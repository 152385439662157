import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from "formik"
import Axios from "axios"
import { SeleksiVendorApi } from "api"

const ModalFilterPR = ({ modalFilter, setModalFilter, data, setData }) => {
  const [dataJabatan, setDataJabatan] = useState([{ label: "Semua", value: undefined }])
  const [dataPembuatPengajuan, setDataPembuatPengajuan] = useState([{ label: "Semua", value: undefined }])
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([{ label: "Semua", value: undefined }])
  const [dataKeperluan, setDataKeperluan] = useState([
    { label: "Semua", value: undefined },
    { label: "Stok", value: "stok" },
    { label: "Produksi", value: "produksi" }
  ])

  const [loading, setLoading] = useState({ all: false, karyawan: true })

    const getDropdown = () => {
    setLoading({ ...loading, all: true })

    Axios.all([
      SeleksiVendorApi.dropdownJabatan(),
      SeleksiVendorApi.dropdownPembuatPengajuan(),
      SeleksiVendorApi.dropdownSeleksiVendor(),
    ])
      .then(Axios.spread(( jab, pengajuan, seleksi, kep ) => {
        const mapJabatan = jab?.data?.data ? jab?.data?.data.map(val => ({ label: val.nama_jabatan, value: val.id_jabatan })) : []
        const mapPengajuan = pengajuan?.data?.data ? pengajuan?.data?.data.map(val => ({ label: val.nama_karyawan, value: val.id_karyawan })) : []
        const mapSeleksiVendor = seleksi?.data?.data ? seleksi?.data?.data.map(val => ({ label: val.nama_item, value: val.id_item_buaso })) : []

        setDataJabatan([ ...dataJabatan, ...mapJabatan ])
        setDataPembuatPengajuan([{label: 'Semua', value: undefined}, ...mapPengajuan ])
        setDataSeleksiVendor([ ...dataSeleksiVendor, ...mapSeleksiVendor ])
      }))
      .finally(() => setLoading({ ...loading, all: false, karyawan: false }))
  }
  const getDropdownPengaju = (value) => {
    SeleksiVendorApi.dropdownPembuatPengajuan({ id_jabatan: value })
      .then(karyawan => {
        const mapDataKaryawan = karyawan?.data?.data ? karyawan.data.data.map(val => ({label: val?.nama_karyawan, value: val?.id_karyawan})) : []
        setDataPembuatPengajuan([{label: 'Semua', value: undefined}, ...mapDataKaryawan])
      })
      .finally(() => setLoading({...loading, karyawan: false }))
  }
  useEffect(() => {
    getDropdown()
  }, [])
  useEffect(() => {
    data.jabatan && getDropdownPengaju(data.jabatan)
  }, [data.jabatan])

  const formInitialValues = {
    tgl_purchase_request_mulai: data?.filter?.tgl_purchase_request_mulai,
    tgl_purchase_request_selesai: data?.filter?.tgl_purchase_request_selesai,
    jabatan: data?.filter?.jabatan,
    pengajuan: data?.filter?.pengajuan,
    id_item: data?.filter?.id_item,
    keperluan: data?.filter?.keperluan,
    tgl_pemakaian_mulai: data?.filter?.tgl_pemakaian_mulai,
    tgl_pemakaian_selesai: data?.filter?.tgl_pemakaian_selesai,
  }
  const formSubmitHandler = (values) => {
      const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

      if (checkActive) {
        setData({ 
            filter: {
                ...values,
                active: true
            },
            pagination: {
                ...data.pagination,
                page: 1
            }
        })
      } else { 
          setData({ 
              ...data,
              filter: {
                  ...values,
                  active: false
              }
          })
      }

      setModalFilter(false)
  }
  const onChangePR = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_purchase_request_mulai: startDate,
        tgl_purchase_request_selesai: endDate,
    })
  }
  const onChangePemakaian = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_pemakaian_mulai: startDate,
        tgl_pemakaian_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
        tgl_purchase_request_mulai: undefined,
        tgl_purchase_request_selesai: undefined,
        jabatan: undefined,
        pengaju: undefined,
        id_item: undefined,
        keperluan: undefined,
        tgl_pemakaian_mulai: undefined,
        tgl_pemakaian_selesai: undefined,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (

      <Modal
        show={modalFilter}
        onHide={() => setModalFilter(false)}>
          
        <Modal.Header closeButton><b>Filter Data</b></Modal.Header>
        <Modal.Body>
          <DatePicker
            selectsRange
            label="Tgl. Purchase Request"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal purchase request"
            startDate={values.tgl_purchase_request_mulai ? new Date(values.tgl_purchase_request_mulai) : ''}
            endDate={values.tgl_purchase_request_selesai ? new Date(values.tgl_purchase_request_selesai) : ''}
            onChange={(dates) => onChangePR(dates, values, setValues)}
            monthsShown={2}
          />
          {/* <SelectSearch
            key={values.jabatan}
            label="Jabatan"
            placeholder="Pilih jabatan"
            defaultValue={dataJabatan?.find(item => item.value === values.jabatan)}
            option={dataJabatan}
            onChange={val => {
              // setFieldValue('jabatan', val.value)
              setValues({ ...values, jabatan: val.value, pengaju: undefined })
              getDropdownPengaju(val.value, values, setValues)
            }}
            loading={loading.all}
          />
          <SelectSearch
            key={values.pengaju}
            label="Nama Pembuat Pengajuan"
            placeholder="Pilih nama pembuat pengajuan"
            defaultValue={dataPembuatPengajuan?.find(item => item.value === values.pengaju)}
            option={dataPembuatPengajuan}
            onChange={val => setFieldValue('pengaju', val.value)}
            isDisabled={Boolean(values.jabatan) ? false : true}
            loading={loading.karyawan}
          /> */}
          <SelectSearch
            key={values.id_item}
            label="Item Purchase Request"
            placeholder="Pilih item purchase request"
            defaultValue={dataSeleksiVendor?.find(item => item.value === values.id_item)}
            option={dataSeleksiVendor}
            onChange={val => setFieldValue('id_item', val.value)}
            loading={loading.all}
          />
          <SelectSearch
            key={values.Keperluan}
            label="keperluan"
            placeholder="Pilih keperluan"
            defaultValue={dataKeperluan?.find(item => item.value === values.keperluan)}
            option={dataKeperluan}
            onChange={val => setFieldValue('keperluan', val.value)}
            loading={loading.all}
          />
          <DatePicker
            selectsRange
            label="Tgl. Pemakaian"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal pemakaian"
            startDate={values.tgl_pemakaian_mulai ? new Date(values.tgl_pemakaian_mulai) : ''}
            endDate={values.tgl_pemakaian_selesai ? new Date(values.tgl_pemakaian_selesai) : ''}
            onChange={(dates) => onChangePemakaian(dates, values, setValues)}
            monthsShown={2}
          />
        </Modal.Body>
        
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => onResetButtonClick(values, setValues)}
          >
            RESET
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            FILTER
          </Button>
        </Modal.Footer>
      </Modal >

      )}
  </Formik>
  )
}

export default ModalFilterPR