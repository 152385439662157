import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Alert,
  BackButton,
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
  ThFixed,
  TdFixed,
  DatePicker,
} from "../../../../components";
import { DateConvert, RupiahConvert } from "../../../../utilities";
import { SeleksiVendorApi } from "../../../../api";

const DetailSeleksiVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  console.log(dataSeleksiVendor)
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    SeleksiVendorApi.getSingle({ id_seleksi_vendor: id })
      .then(({ data }) => {
        setDataSeleksiVendor(data.data);
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle("Detail Seleksi Vendor");
    getInitialData();

    return () => {};
  }, []);

  const PageContent = () => {
    const InfoSection = () => {
      const InfoItem = ({ title, value }) => (
        <tr>
          <td>
            <small>{title}</small>
          </td>
          <td>
            <small className="pl-4 pr-2">:</small>
          </td>
          <td>
            <small>{value}</small>
          </td>
        </tr>
      );

      return (
        <>
          <Row>
            <Col>
              <table>
                <tbody>
                  <InfoItem
                    title="Nama Vendor"
                    value={dataSeleksiVendor.nama_vendor ? dataSeleksiVendor.nama_vendor : "-"}
                  />
                  <InfoItem
                    title="Tgl. Purchase Request"
                    value={
                      dataSeleksiVendor.tgl_purchase_request
                        ? DateConvert(new Date(dataSeleksiVendor.tgl_purchase_request)).detail
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Tgl. Pemakaian"
                    value={
                      dataSeleksiVendor.tgl_pemakaian
                        ? DateConvert(new Date(dataSeleksiVendor.tgl_pemakaian)).detail
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Tgl. Seleksi Vendor"
                    value={
                      dataSeleksiVendor.tgl_seleksi_vendor
                        ? DateConvert(new Date(dataSeleksiVendor.tgl_seleksi_vendor)).detail
                        : "-"
                    }
                  />
                </tbody>
              </table>
            </Col>
            <Col>
              <table>
                <tbody>
                  <InfoItem
                    title="No. Purchase Request"
                    value={
                      dataSeleksiVendor.no_purchase_request
                        ? dataSeleksiVendor.no_purchase_request
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Keperluan"
                    value={dataSeleksiVendor.keperluan ? dataSeleksiVendor.keperluan : "-"}
                  />
                  <InfoItem
                    title="No. Seleksi Vendor"
                    value={
                      dataSeleksiVendor.no_seleksi_vendor
                        ? dataSeleksiVendor.no_seleksi_vendor
                        : "-"
                    }
                  />
                </tbody>
              </table>
            </Col>
          </Row>
          <hr />
        </>
      );
    };

    const ListItemPurchaseRequestSection = () => {
      const TableListItemPurchaseRequest = () => (
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Kode Item</ThFixed>
              <Th>Nama Item</Th>
              <Th>Satuan Beli</Th>
              <Th>Qty PR</Th>
              <Th>Qty Order</Th>
              <Th>Harga Perkiraan</Th>
            </Tr>
          </THead>
          <TBody>
            {dataSeleksiVendor.pr_detail.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_item}</TdFixed>
                <Td>
                  <div style={{ width: "200px" }}>
                    {/* {val.dimensi === "3"
                      ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                      : val.dimensi === "2"
                      ? `${val.nama_item} (${val.pj} X ${val.lb})`
                      : val.dimensi === "1"
                      ? `${val.nama_item} (${val.pj})`
                      : val.dimensi === "0"
                      ? val.nama_item
                      : ""} */}
                    {val.nama_item}
                  </div>
                </Td>
                <Td>{val.satuan_beli}</Td>
                <Td textRight>{val.qty_pr}</Td>
                <Td textRight>{val.qty_ordered}</Td>
                <Td textRight>
                  {RupiahConvert(parseInt(val.harga_perkiraan_sendiri).toString()).detail}
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      );

      return (
        <>
          <div className="py-2 pl-1">
            <b>List Item Purchase Request</b>
          </div>
          {dataSeleksiVendor.pr_detail ? (
            dataSeleksiVendor.pr_detail.length > 0 ? (
              <TableListItemPurchaseRequest />
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </>
      );
    };

    const ListItemSeleksiVendorSection = () => {
      const TableListItemSeleksiVendor = () => {
        let total = 0;

        return (
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Item</ThFixed>
                <Th>Nama Item</Th>
                <Th>Satuan Beli</Th>
                <Th>Qty Order</Th>
                <Th>Harga Kesepakatan</Th>
                <Th>Jumlah</Th>
              </Tr>
            </THead>
            <TBody>
              {dataSeleksiVendor.detail.map((val, index) => {
                total = total + val.harga_kesepakatan * val.qty_order;

                return (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item}</TdFixed>
                    <Td>
                      <div style={{ width: "200px" }}>
                        {/* {val.dimensi === "3"
                          ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                          : val.dimensi === "2"
                          ? `${val.nama_item} (${val.pj} X ${val.lb})`
                          : val.dimensi === "1"
                          ? `${val.nama_item} (${val.pj})`
                          : val.dimensi === "0"
                          ? val.nama_item
                          : ""} */}
                        {val.nama_item}
                      </div>
                    </Td>
                    <Td>{val.satuan_beli}</Td>
                    <Td textRight>{val.qty_order}</Td>
                    <Td textRight>
                      {RupiahConvert(parseInt(val.harga_kesepakatan).toString()).detail}
                    </Td>
                    <Td textRight>
                      {
                        RupiahConvert((val.qty_order * parseInt(val.harga_kesepakatan)).toString())
                          .detail
                      }
                    </Td>
                  </Tr>
                );
              })}
              <Tr>
                <Td colSpan={6} textRight>
                  <b>Total</b>
                </Td>
                <Td textRight>
                  <b>{RupiahConvert(total.toString()).detail}</b>
                </Td>
              </Tr>
            </TBody>
          </Table>
        );
      };

      return (
        <>
          <div className="py-2 pl-1">
            <b>List Item Seleksi Vendor</b>
          </div>
          {dataSeleksiVendor.detail ? (
            dataSeleksiVendor.detail.length > 0 ? (
              <TableListItemSeleksiVendor />
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </>
      );
    };

    return (
      <>
        {/* <InfoSection /> */}
        <ListItemPurchaseRequestSection />
        <ListItemSeleksiVendorSection />
      </>
    );
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>
          <small>{title}</small>
        </td>
        <td>
          <small className="pl-4 pr-2">:</small>
        </td>
        <td>
          <small>{value}</small>
        </td>
      </tr>
    );

    return (
      <>
        <Card className="mb-4">
          <div className="p-2">
            <InfoItem
              title="Tgl. Purchase Request"
              value={dataSeleksiVendor.tgl_purchase_request ? DateConvert(new Date(dataSeleksiVendor.tgl_purchase_request)).detail : "-"}
            />
            <InfoItem
              title="No Purchase Request"
              value={dataSeleksiVendor.no_purchase_request ?? "-"}
            />
            <InfoItem
              title="Tgl. Seleksi Vendor"
              value={dataSeleksiVendor.tgl_pemakaian ? DateConvert(new Date(dataSeleksiVendor.tgl_pemakaian)).detail : "-"}
            />
            <InfoItem
              title="Keperluan"
              value={dataSeleksiVendor.keperluan ?? "-"}
            />
          </div>
        </Card>

        <div className="mt-auto"><b style={{fontSize:"15px"}}>List Data Seleksi Vendor</b></div>
        <Card className="mb-4">
          <div className="p-2">
            <InfoItem
              title="Tgl. Seleksi vendor"
              value={dataSeleksiVendor.tgl_seleksi_vendor ? DateConvert(new Date(dataSeleksiVendor.tgl_seleksi_vendor)).detail : "-"}
            />
            <InfoItem
              title="No Seleksi Vendor"
              value={dataSeleksiVendor.no_seleksi_vendor ?? "-"}
            />
            <InfoItem
              title="Vendor"
              value={dataSeleksiVendor.nama_vendor ?? "-"}
            />
            {/* <InfoItem
              title="Proyek"
              value={dataSeleksiVendor.nama_proyek ?? "-"}
            /> */}
            <InfoItem
              title="Item Seleksi Vendor"
              value={dataSeleksiVendor.nama_item ?? "-"}
            />
            <InfoItem
              title="Satuan Beli"
              value={dataSeleksiVendor.satuan_beli ?? "-"}
            />
            <InfoItem
              title="Qty. Seleksi Vendor"
              value={dataSeleksiVendor.qty_order ?? "-"}
            />
            <InfoItem
              title="Harga Kesepakatan"
              value={dataSeleksiVendor.harga_kesepakatan ? RupiahConvert(dataSeleksiVendor.harga_kesepakatan.toString()).detail : "-"}
            />
            <InfoItem
              title="Satatus Approval"
              value={dataSeleksiVendor.status_approval === "PEN" 
                ? "PENDING" 
                : dataSeleksiVendor.status_approval === "REV"
                ? "REVISION"
                : dataSeleksiVendor.status_approval === "VER"
                ? "VERIFIED"
                : dataSeleksiVendor.status_approval === "APP"
                ? "APPROVED"
                : dataSeleksiVendor.status_approval === "REJ"
                ? "REJECTED"
                : "-"
              }
            />
          </div>
        </Card>
      </>
    );
  };
  const CatatanApprovalSV = () => {
    const InfoItem = ({ labelHeader, karyawanName, labelCatatan, catatanValue }) => (
      <>
          <small>{labelHeader}</small>
          <p><b>{karyawanName}</b></p>
          <small>{labelCatatan}</small>
          <p><b>{catatanValue}</b></p>
      </>
    );
    return (
      <>
        <div className="d-flex">
          {dataSeleksiVendor.stakeholder === undefined
          ? <div className="text-center"><small>Memuat Data...</small></div>
          : dataSeleksiVendor.stakeholder.map((val, index) => (
            <>
              <div className="mr-5" sm key={index}>
                  <InfoItem
                      labelHeader={val.status_approval === "APP" 
                          ? "Pengesah" 
                          : index !== 0 
                          ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}` : ""
                      }
                      karyawanName={index !== 0 ? val.nama_karyawan ?? "-" : ""}
                      labelCatatan={val.status_approval === "APP" 
                          ? "Catatan Pengesah" 
                          : index !== 0 
                          ? `Catatan Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}` : ""
                      }
                      catatanValue={index !== 0 ? val.catatan : ""}
                  />
              </div>
              </>
          ))}
        </div>
      </>
    )
  }

  return (
    <div>
      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      {isPageLoading 
        ? <DataStatus loading={true} text="Memuat data . . ." />
        : isFetchingFailed 
        ? <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
        : <div>
            <div className="d-flex align-items-flex-end">
              <div className="mt-auto"><b style={{fontSize:"15px"}}>Detail Data Purchase Request</b></div>
              <div className="d-flex align-items-center ml-auto mb-4">
                <BackButton onClick={() => history.push("/transaksi/seleksi-vendor", { ...location?.state })} />
              </div>
            </div>

            {/* Info Section */}
            <InfoSection />

            {/* Catatan Approval */}
            <div className="mt-auto"><b style={{fontSize:"15px"}}>Catatan Approval Seleksi Vendor</b></div>
            <Card className="mb-5">
              <Card.Body>
                <CatatanApprovalSV />
              </Card.Body>
            </Card>
          </div>
      }
    </div>
  );
};

export default DetailSeleksiVendor;
