import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from "formik"
import { PurchaseOrderApi } from 'api'
import Axios from "axios"

const ModalFilterPO = ({ modalFilter, setModalFilter, data, setData }) => {
  const [dropdownVendor, setDropdownVendor] = useState([{ label: "Semua", value: undefined }])
  const [dropdownItemPO, setDropdownItemPO] = useState([{ label: "Semua", value: undefined }])
  const [dropdownStatusApproval, setDropdownStatusApproval] = useState([
    {
        label: 'Semua',
        value: undefined
    },
    {
        label: 'APPROVED',
        value: 'APP'
    },
    {
        label: 'VERIFIED',
        value: 'VER'
    },
    {
        label: 'REVISION',
        value: 'REV'
    },
    {
        label: 'REJECTED',
        value: 'REJ'
    },
    {
        label: 'PENDING',
        value: 'PEN'
    }
])
  const [isLoading, setIsLoading] = useState(false)

  const getDropdown = () => {
    setIsLoading(true)

    Axios.all([
      PurchaseOrderApi.dropdownVendor(),
      PurchaseOrderApi.dropdownItemPO()
    ])
      .then(Axios.spread(( vendor, item ) => {
        const mapVendor = vendor?.data?.data ? vendor?.data?.data.map(val => ({ label: val.nama_vendor, value: val.id_vendor })) : []
        const mapItem = item?.data?.data ? item?.data?.data.map(val => ({ label: val.nama_item, value: val.id_item_buaso })) : []

        setDropdownVendor([ ...dropdownVendor, ...mapVendor ])
        setDropdownItemPO([ ...dropdownItemPO, ...mapItem ])
      }))
      .finally(() => setIsLoading(false))
  };

  useEffect(() => {
    getDropdown()
  }, [])
  
  const formInitialValues = {
    tgl_purchase_order_mulai: data?.filter?.tgl_purchase_order_mulai,
    tgl_purchase_order_selesai: data?.filter?.tgl_purchase_order_selesai,
    tgl_sv: data?.filter?.tgl_sv,
    // tgl_seleksi_vendor_selesai: data?.filter?.tgl_seleksi_vendor_selesai,
    id_vendor: data?.filter?.id_vendor,
    id_item_po: data?.filter?.id_item_po,
    status_approval: data?.filter?.status_approval,
  }
  const formSubmitHandler = (values) => {
      const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

      if (checkActive) {
        setData({ 
            filter: {
                ...values,
                active: true
            },
            pagination: {
                ...data.pagination,
                page: 1
            }
        })
      } else { 
          setData({ 
              ...data,
              filter: {
                  ...values,
                  active: false
              }
          })
      }

      setModalFilter(false)
  }
  const onChangePO = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_purchase_order_mulai: startDate,
        tgl_purchase_order_selesai: endDate,
    })
  }
  const onChangeSV = (dates, values, setValues) => {
    const selected = dates ? DateConvert(dates).default : null
    setValues({ ...values, tgl_sv: selected })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
        tgl_purchase_order_mulai: undefined,
        tgl_purchase_order_selesai: undefined,
        tgl_sv: undefined,
        // tgl_seleksi_vendor_selesai: undefined,
        id_vendor: undefined,
        id_item_po: undefined,
        status_approval: undefined,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (

      <Modal
        show={modalFilter}
        onHide={() => setModalFilter(false)}>
          
        <Modal.Header closeButton><b>Filter Data</b></Modal.Header>
        <Modal.Body>
          <DatePicker
            selectsRange
            label="Tgl. Purchase Order"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal purchase order"
            startDate={values.tgl_purchase_order_mulai ? new Date(values.tgl_purchase_order_mulai) : ''}
            endDate={values.tgl_purchase_order_selesai ? new Date(values.tgl_purchase_order_selesai) : ''}
            onChange={(dates) => onChangePO(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker 
            label="Tgl. Seleksi Vendor"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal seleksi vendor"
            selected={values.tgl_sv ? new Date(values.tgl_sv) : ""}
            onChange={(dates) => onChangeSV(dates, values, setValues)}
          />
          <SelectSearch
            key={values.id_vendor}
            label="Vendor"
            placeholder="Pilih vendor"
            defaultValue={dropdownVendor?.find(item => item.value === values.id_vendor)}
            option={dropdownVendor}
            onChange={val => setFieldValue('id_vendor', val.value)}
            loading={isLoading}
          />
          <SelectSearch
            key={values.id_item_po}
            label="Item Purchase Order"
            placeholder="Pilih item purchase order"
            defaultValue={dropdownItemPO?.find(item => item.value === values.id_item_po)}
            option={dropdownItemPO}
            onChange={val => setFieldValue('id_item_po', val.value)}
            loading={isLoading}
          />
          <SelectSearch
            key={values.status_approval}
            label="Status Approval"
            placeholder="Pilih status approval"
            defaultValue={dropdownStatusApproval?.find(item => item.value === values.status_approval)}
            option={dropdownStatusApproval}
            onChange={val => setFieldValue('status_approval', val.value)}
            loading={isLoading}
          />
        </Modal.Body>
        
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => onResetButtonClick(values, setValues)}
          >
            RESET
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            FILTER
          </Button>
        </Modal.Footer>
      </Modal >

      )}
  </Formik>
  )
}

export default ModalFilterPO



{/* <DatePicker
selectsRange
label="Tgl. Seleksi Vendor"
dateFormat="dd/MM/yyyy"
placeholderText="Pilih tanggal seleksi vendor"
startDate={values.tgl_seleksi_vendor_mulai ? new Date(values.tgl_seleksi_vendor_mulai) : ''}
endDate={values.tgl_seleksi_vendor_selesai ? new Date(values.tgl_seleksi_vendor_selesai) : ''}
onChange={(dates) => onChangeSV(dates, values, setValues)}
monthsShown={2}
/> */}