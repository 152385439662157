import React from 'react'
import {
  TableHargaBarang,
  SectionDetail
} from './Comps'

const HargaPerkiraanSendiriPakaiDetail = () => {
  return (
    <div className="p-2">
      {/* Detail */}
      <SectionDetail 
        type="Pakai"
        action="UPDATE"
      />

      {/* Tabel Harga Sebelumnya */}
      <div>
        <h6 className="px-2">
          <b>Harga Barang Sebelumnya</b>
        </h6>
        <TableHargaBarang 
          action='VIEW'
        />
      </div>

      {/* Table Harga Terbaru */}
      <h6 className="px-2">
        <b>Harga Barang Terbaru</b>
      </h6>
      <TableHargaBarang  />
    </div>
  )
}

export default HargaPerkiraanSendiriPakaiDetail