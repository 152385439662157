import {
  useState,
  useEffect
} from 'react'
import {
  useHistory,
  useParams
} from 'react-router-dom'
import {
  Formik
} from 'formik'
import { 
  ActionButton, 
  BackButton, 
  DataStatus
} from 'components'
import {
  PrakualifikasiVendorApi,
} from 'api'
import {
  SectionPrakualifikasiVendor
} from './Comps'
import {
  dataKelengkapanDokumen as dataKelengkapan,
  formInitialValues,
  formValidationSchema
} from './Utils'

const PrakualifikasiVendorUpdate = ({setNavbarTitle}) => {
  const history = useHistory()
  const { id } = useParams()
  const [dataDetail, setDataDetail] = useState({})
  const [dataItemBahan, setDataItemBahan] = useState([])
  const [dataItemSubcon, setDataItemSubcon] = useState([])
  const [dataItemUpah, setDataItemUpah] = useState([])
  const [dataKelengkapanDokumen, setDataKelengkapanDokumen] = useState([])
  const [dataDokumenLainnya, setDataDokumenLainnya] = useState([])
  const [fetching, setFetching] = useState({
    loading: true,
    error: true
  })

  const getInitialData = () => PrakualifikasiVendorApi.single(id)
    .then((res) => {
      const getDataDetail = res?.data?.data ?? {}

      setDataDetail(getDataDetail)
      setDataItemBahan(getDataDetail?.jumlah_item ? getDataDetail.jumlah_item.map(val => ({
        id_item_bahan: val?.id_item_buaso,
        kode_item: val?.kode_item,
        nama_item: val?.nama_item,
        satuan_pakai: val?.nama_satuan,
        jumlah: val?.jumlah_item
      })) : [])
      setDataItemSubcon(getDataDetail?.vendor_item_subcon)
      setDataItemUpah(getDataDetail?.vendor_item_upah)
      setDataKelengkapanDokumen(dataKelengkapan(getDataDetail?.dokumen_utama ?? []))
      setDataDokumenLainnya(getDataDetail?.dokumen_lainnya 
        ? getDataDetail?.dokumen_lainnya?.map(val => ({
            id_vendor_prakualifikasi_dokumen_lainnya: val.id_vendor_prakualifikasi_dokumen_lainnya,
            uraian: val?.nama_dokumen ?? '-',
            dokumen: {
              file: undefined,
              name: val?.file_path ?? undefined,
              link: val?.full_file_path ?? undefined,
              file_path: val?.file_path
            }
          })) 
        : []
      )
      setFetching({
        loading: false,
        error: false
      })
    })
    .catch(() => {
      setFetching({
        loading: false,
        error: true
      })
    })
    
    const updatePrakualifikasiVendor = async(values) => {
      const newDataDokumenUtama = async () => await Promise.all(dataKelengkapanDokumen.map(async val => {
        if (val?.dokumen?.file) {
          const fileData = new FormData()
          fileData.append("file", val.dokumen.file)
  
          return await PrakualifikasiVendorApi.upload(fileData)
            .then(res => ({
              id_vendor_prakualifikasi_dokumen: val?.id,
              ada_atau_tidak: !!(val?.dokumen?.have === 'ada'),
              file_path: res?.data?.data ?? ''
            }))
            .catch(() => ({
              id_vendor_prakualifikasi_dokumen: val?.id,
              ada_atau_tidak: !!(val?.dokumen?.have === 'ada'),
              file_path: ''
            }))
        }
  
        return {
          id_vendor_prakualifikasi_dokumen: val?.id,
          ada_atau_tidak: !!(val?.dokumen?.have === 'ada'),
          file_path: val?.dokumen?.file_path ?? ''
        }
      }))
      
      const newDataDokumenLainnya = async () => await Promise.all(dataDokumenLainnya.map(async val => {
        if (val?.dokumen?.file) {
          const fileData = new FormData()
          fileData.append("file", val.dokumen.file)
  
          return await PrakualifikasiVendorApi.upload(fileData)
            .then(res => ({
              id_vendor_prakualifikasi_dokumen_lainnya: val.id_vendor_prakualifikasi_dokumen_lainnya,
              nama_dokumen: val?.uraian,
              file_path: res?.data?.data ?? ''
            }))
            .catch(() => ({
              id_vendor_prakualifikasi_dokumen_lainnya: val.id_vendor_prakualifikasi_dokumen_lainnya,
              nama_dokumen: val?.uraian,
              file_path: ''
            }))
        }
  
        return {
          id_vendor_prakualifikasi_dokumen_lainnya: val.id_vendor_prakualifikasi_dokumen_lainnya,
          nama_dokumen: val?.uraian,
          file_path: val?.dokumen?.file_path ?? ''
        }
      })) 
      
      const arrDokumenLainnya = await newDataDokumenLainnya()

      const finalValues = {
        ...values,
        jumlah_item: dataItemBahan.map(val => ({
          id_vendor_prakualifikasi_jumlah_item: val.id_item_bahan,
          jumlah_item: val.jumlah
        })),
        dokumen_utama: await newDataDokumenUtama(),
        dokumen_lainnya_lama: arrDokumenLainnya.filter(val => val.id_vendor_prakualifikasi_dokumen_lainnya).map(val => val.id_vendor_prakualifikasi_dokumen_lainnya),
        dokumen_lainnya_baru: arrDokumenLainnya.filter(val => !val.id_vendor_prakualifikasi_dokumen_lainnya),
      }

      return PrakualifikasiVendorApi.update(finalValues)
        .then(() => history.push('/master/prakualifikasi-vendor', {alert: {show: true, variant: 'primary', text: 'Ubah data berhasil!'}}))
        .catch(() => history.push('/master/prakualifikasi-vendor', {alert: {show: true, variant: 'danger', text: 'Ubah data gagal!'}}))
    }

  useEffect(() => {
    setNavbarTitle('Ubah Prakualifikasi Vendor')
    getInitialData()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between align-items-end mb-2">
        <b className="px-2" style={{fontSize: 17}}>Data Prakualifikasi Vendor</b>
        <BackButton size="sm" onClick={history.goBack} />
      </div>
      <div className="bg-white border rounded p-3">
        {fetching.loading || fetching.error
          ? <DataStatus 
              loading={fetching.loading}
              text={fetching.loading ? 'Memuat data . . .' : 'Data gagal dimuat'}
            />
          : <Formik
              enableReinitialize
              initialValues={formInitialValues(dataDetail)}
              validationSchema={formValidationSchema}
              onSubmit={updatePrakualifikasiVendor}
            >
              {({isSubmitting, handleSubmit}) => (
                <>
                  <SectionPrakualifikasiVendor 
                    action="UPDATE"
                    jenisVendor={dataDetail?.jenis_vendor ?? []}
                    dataDetail={dataDetail}
                    dataItemBahan={dataItemBahan}
                    dataItemUpah={dataItemUpah}
                    dataItemSubcon={dataItemSubcon}
                    dataDokumen={dataKelengkapanDokumen}
                    dataDokumenLainnya={dataDokumenLainnya}
                    setDataItemBahan={setDataItemBahan}
                    setDataDokumen={setDataKelengkapanDokumen}
                    setDataDokumenLainnya={setDataDokumenLainnya}
                  />
                    <div className="text-right">
                      <ActionButton 
                        text="Ubah"
                        variant="success"
                        className="px-4 my-3"
                        loading={isSubmitting}
                        onClick={handleSubmit}
                      />
                    </div>
                </>
              )}
            </Formik>
        }
      </div>
    </>
  )
}

export default PrakualifikasiVendorUpdate