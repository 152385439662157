import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  FilterButton,
  TextArea
} from "../../../../components";
import { TableNumber, DateConvert, RupiahConvert } from "../../../../utilities";
import { SeleksiVendorApi } from "../../../../api";
import { IoAddOutline, IoEyeOutline } from "react-icons/io5";
import { ModalFilterPR } from "../Section"

const ListPurchaseRequestSeleksiVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPurchaseRequest, setDataPurchaseRequest] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [modalFilter, setModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.pr?.filter?.active,
      tgl_purchase_request_mulai: location?.state?.pr?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai: location?.state?.pr?.filter?.tgl_purchase_request_selesai,
      jabatan: location?.state?.pr?.filter?.jabatan,
      pengaju: location?.state?.pr?.filter?.pengaju,
      id_item: location?.state?.pr?.filter?.id_item,
      keperluan: location?.state?.pr?.filter?.keperluan,
      tgl_pemakaian_mulai: location?.state?.pr?.filter?.tgl_pemakaian_mulai,
      tgl_pemakaian_selesai: location?.state?.pr?.filter?.tgl_pemakaian_selesai,
    },
    pagination: {
      page: location?.state?.pr?.filter?.page ?? "1",
      dataLength: location?.state?.pr?.filter?.dataLength ?? "10",
      totalPage: location?.state?.pr?.filter?.totalPage ?? "1",
      dataCount: location?.state?.pr?.filter?.dataCount ?? "0",
    }
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    setAlertConfig({
      ...alertConfig,
      show: false,
    });

    SeleksiVendorApi.getPurchaseRequest({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_purchase_request_mulai: dataFilter?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai: dataFilter?.filter?.tgl_purchase_request_selesai,
      jabatan: dataFilter?.filter?.jabatan,
      pengaju: dataFilter?.filter?.pengaju,
      id_item: dataFilter?.filter?.id_item,
      keperluan: dataFilter?.filter?.keperluan,
      tgl_pemakaian_mulai: dataFilter?.filter?.tgl_pemakaian_mulai,
      tgl_pemakaian_selesai: dataFilter?.filter?.tgl_pemakaian_selesai,
    })
      .then(data => {
        setDataPurchaseRequest(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter?.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => {
        setIsPageLoading(false);
        if (searchConfig.key != "") {
          setAlertConfig({
            show: true,
            variant: "primary",
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
        }
      });
  };

  const onSubmitSearchHandler = (e) => {
    e.preventDefault();
    setSearchConfig({
      ...searchConfig,
      status: true,
    });
    getInitialData();
  };

  const onResetHandler = () => {
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setSearchConfig({
      show: false,
      key: "",
    });
    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  useEffect(() => {
    setNavbarTitle("Seleksi Vendor");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.status,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_purchase_request_mulai,
    dataFilter?.filter?.tgl_purchase_request_selesai,
    dataFilter?.filter?.jabatan,
    dataFilter?.filter?.pengaju,
    dataFilter?.filter?.id_item,
    dataFilter?.filter?.keperluan,
    dataFilter?.filter?.tgl_pemakaian_mulai,
    dataFilter?.filter?.tgl_pemakaian_selesai,
  ]);

  const PageContent = () => {
    const BarangJadiCollapse = ({ data, type }) => { //parseFloat(brg.qty_order).toPrecision()
      const [isReadMoreClick, setisReadMoreClick] = useState(false);
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return <li index={index}>{`${brg.nama_item} ${parseFloat(brg.qty_pr).toPrecision()} ${brg.satuan_beli}`}</li>
              } else {
                return index <= 1 && <li index={index}>{`${brg.nama_item} ${parseFloat(brg.qty_pr).toPrecision()} ${brg.satuan_beli}`}</li>
              }
            })}
          </ul>
          {data.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };

    const DataTable = () => (
      <>
        <div className="mt-3"><span><b style={{fontSize:"14px"}}>List Purchase Request Yang Siap Dibuatkan Seleksi Vendor</b></span></div>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Purchase Request</ThFixed>
              <Th>Item Purchase Request</Th>
              <Th>Keperluan</Th>
              <ThFixed>Tgl. Pemakaian</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataPurchaseRequest.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_purchase_request ? DateConvert(new Date(val.tgl_purchase_request)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_purchase_request ?? "-"}</div>
                </TdFixed>
                <Td>{val.item_purchase_request ? <BarangJadiCollapse data={val.item_purchase_request} /> : "-"}</Td>
                <Td>{val.keperluan}</Td>
                <TdFixed>{val.tgl_pemakaian ? DateConvert(new Date(val.tgl_pemakaian)).defaultDMY : "-"}</TdFixed>
                <TdFixed className="d-flex justify-content-center">
                  <ActionButton
                    size="sm"
                    className="m-1 text-nowrap"
                    text={<IoEyeOutline className="text-dark" />}
                    onClick={() => {
                      setProcessedData(val);
                      setModalConfig({
                        show: true,
                        type: "detail",
                      });
                    }}
                  />
                  <ActionButton
                    size="sm"
                    className="m-1 text-nowrap"
                    text={<IoAddOutline className="text-dark" />}
                    onClick={() =>
                      history.push("/transaksi/seleksi-vendor/tambah/" + val.id_purchase_request, { ...location?.state, pr: dataFilter })
                    }
                  />
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
          }
          dataPage={
            dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              }
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              }
            })
          }
        />
      </>
    );
  };

  const PageModal = () => {
    const [modalData, setModalData] = useState({});
    console.log("pek", modalData)
    const [isModalLoading, setIsModalLoading] = useState(true);
    const [isModalFetchingFailed, setIsModalFetchingFailed] = useState(false);

    const getInitialModalData = () => {
      SeleksiVendorApi.getSingle({ id_purchase_request: processedData.id_purchase_request })
        .then(({ data }) => {
          setModalData(data.data);
          setIsModalFetchingFailed(false);
        })
        .catch(() => {
          setIsModalFetchingFailed(true);
        })
        .finally(() => setIsModalLoading(false));
    };

    useEffect(() => {
      getInitialModalData();

      return () => {
        setIsModalLoading(true);
        setIsModalFetchingFailed(false);
      };
    }, []);

    const ModalTitle = () =>
      modalConfig.type.toLowerCase() === "detail" ? (
        <span className="text-primary">Detail Purchase Request</span>
      ) : modalConfig.type.toLowerCase() === "update" ? (
        <span className="text-success">Ubah Seleksi Vendor</span>
      ) : modalConfig.type.toLowerCase() === "delete" ? (
        <span className="text-danger">Hapus Seleksi Vendor</span>
      ) : (
        <span className="text-primary">Tambah Seleksi Vendor</span>
      );

    const ModalDetail = () => {
      const ModalDetailInfo = () => {
        const ModalInfoItem = ({ title, value }) => (
          <tr>
            <td>
              <small>{title}</small>
            </td>
            <td className="pl-3 pr-1">
              <small>:</small>
            </td>
            <td>
              <small>{value}</small>
            </td>
          </tr>
        );

        return (
          <>
            <table>
              <tbody>
                <ModalInfoItem
                  title="Tgl. Purchase Request"
                  value={modalData.tgl_purchase_request ? DateConvert(new Date(modalData.tgl_purchase_request)).detail : "-"}
                />
                <ModalInfoItem
                  title="No. Purchase Request"
                  value={modalData.no_purchase_request ? modalData.no_purchase_request : "-"}
                />
                <ModalInfoItem
                  title="Tgl. Pemakaian"
                  value={modalData.tgl_pemakaian ? DateConvert(new Date(modalData.tgl_pemakaian)).detail : "-"}
                />
                <ModalInfoItem
                  title="Keperluan"
                  value={modalData.keperluan ? modalData.keperluan : "-"}
                />
              </tbody>
            </table>
            <hr />
          </>
        );
      };
      const ModalDetailTable = () => {
        return (
          <>
            <div className="mt-auto"><b style={{fontSize:"15px"}}>List Item Barang Purchase Request</b></div>
            <Table>
              <THead>
                <Tr>
                  <ThFixed>No.</ThFixed>
                  <ThFixed>Kode Barang</ThFixed>
                  <Th>Item Purchase Request</Th>
                  <Th>Satuan Beli</Th>
                  <Th>Qty. Purchase Request</Th>
                  <Th>Qty. Telah Diseleksi</Th>
                  <Th>Qty. Sisa</Th>
                  <Th>Harga Perkiraan Sendiri</Th>
                </Tr>
              </THead>
              <TBody>
                {modalData.pr_detail.map((val, index) => (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item ?? "-"}</TdFixed>
                    <Td>
                      {/* {val.dimensi === "3"
                        ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                        : val.dimensi === "2"
                        ? `${val.nama_item} (${val.pj} X ${val.lb})`
                        : val.dimensi === "1"
                        ? `${val.nama_item} (${val.pj})`
                        : val.dimensi === "0"
                        ? val.nama_item
                        : ""} */}
                      {val.nama_item ?? "-"}
                    </Td>
                    <Td>{val.satuan_beli ?? "-"}</Td>
                    <Td>{val.qty_pr}</Td>
                    <Td>{val.qty_ordered ?? "-"}</Td>
                    <Td>qty sisa</Td>
                    <Td>{val.harga_perkiraan_sendiri ? RupiahConvert(val.harga_perkiraan_sendiri).detail : "-"}</Td>
                  </Tr>
                ))}
              </TBody>
            </Table>
          </>
        );
      };
      const CatatanPR = () => {
        return (
          <>
            <div className="mt-4"><b style={{fontSize:"15px"}}>Catatan Purchase Request</b></div>
            <TextArea
                // type="text"
                // name="media_sosial"
                // placeholder="Masukkan Media Sosial"
                value={modalData?.catatan_purchase_request ?? "-"}
                // onChange={handleChange}
                rows={4}
              />
          </>
        )
      }

      return (
        <>
          <Modal.Body>
            {isModalLoading 
              ? <DataStatus loading={true} text="Memuat data . . ." />
              : isModalFetchingFailed 
                ? <DataStatus text="Data gagal dimuat" />
                : <>
                    <ModalDetailInfo />
                    {modalData.pr_detail 
                      ? modalData.pr_detail.length > 0 
                        ? <ModalDetailTable />
                        : <DataStatus text="Tidak ada item" />
                      : <DataStatus text="Tidak ada item" />
                    }
                    <CatatanPR />
                  </>
            }
          </Modal.Body>
          <Modal.Footer>
            <ActionButton 
              variant="secondary"
              text="Tutup"
              onClick={() => setModalConfig({ ...modalConfig, show: false })}
            />
          </Modal.Footer>
        </>
      );
    };

    return (
      <Modal
        size="xl"
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <ModalTitle />
        </Modal.Header>
        {modalConfig.type.toLowerCase() === "detail" && <ModalDetail />}
      </Modal>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchConfig({
                      ...searchConfig,
                      key: e.target.value,
                    });
                    setDataFilter({
                      ...dataFilter,
                      pagination: {
                        ...dataFilter.pagination,
                        page: "1",
                        dataLength: "10",
                        totalPage: "1",
                        dataCount: "0",
                      }
                    });
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/transaksi/seleksi-vendor", { ...location?.state, pr: dataFilter })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPurchaseRequest ? (
        dataPurchaseRequest.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
      <PageModal />

      {/* Modal Filter  */}
      {modalFilter && (
        <ModalFilterPR
          modalFilter={modalFilter}
          setModalFilter={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default ListPurchaseRequestSeleksiVendor;
