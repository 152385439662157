import Axios from "axios"

const { REACT_APP_ROOT_API_BASE_URL } = process.env

const RootServices = Axios.create({
  baseURL: REACT_APP_ROOT_API_BASE_URL,
})

// RootServices.defaults.timeout = 30000

RootServices.interceptors.request.use((config) => {
  config.headers = {
   "Api-Key": "TUdQQmFuZ3NhdFBhbnRla0FzdQ==",
    "Content-Type": "application/json",
  }

  return config
})

export default RootServices
