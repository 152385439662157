import React from 'react'
import { 
  IoCaretDownOutline, 
  IoCaretUpOutline 
} from 'react-icons/io5'
import {
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ReadButton,
  UpdateButton,
  DataStatus,
  Pagination
} from 'components'
import {
  DateConvert,
  RupiahConvert,
  TableNumber
} from 'utilities'

export const HPSList = ({type = 'beli', data = [], onReadButtonClick, onUpdateButtonClick, fetchingStatus, paginationConfig, setPaginationConfig, withoutCaret}) => {

  if (fetchingStatus.loading || fetchingStatus.error) {
    return (
      <DataStatus 
        loading={fetchingStatus.loading}
        text={fetchingStatus.loading ? 'Memuat . . .' : 'Data gagal dimuat'}
      />
    )  
  }

  if (type === 'beli') {
    return (
      <>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <Th style={{width: 100}}>Kode Item</Th>
              <Th style={{minWidth: 300,}} className="p-0">Nama Item</Th>
              <Th style={{minWidth: 100,}} className="p-0">Grup BUASO</Th>
              <Th style={{minWidth: 100,}} className="p-0">Satuan Beli</Th>
              <Th style={{minWidth: 100,}} className="p-0">Tgl. HPS Sebelumnya</Th>
              <Th style={{minWidth: 150,}} className="p-0">Rata-Rata Harga Sebelumnya</Th>
              <Th style={{minWidth: 100,}} className="p-0">Tgl. HPS Terbaru</Th>
              <Th style={{minWidth: 150,}} className="p-0">Rata-Rata Harga Terbaru</Th>
              <Th style={{minWidth: 150,}} className="p-0">Selisih Harga</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data && data?.length > 1
              ? data.map((e, i) => (
                  <Tr key={i}>
                    <TdFixed>{TableNumber(paginationConfig.page, paginationConfig.dataLength, i)}</TdFixed>
                    <Td>{e?.kode_item ?? '-'}</Td>
                    <Td>{e?.nama_item ?? '-'}</Td>
                    <Td>{e?.nama_buaso ?? '-'}</Td>
                    <Td>{e?.satuan_beli ?? '-'}</Td>
                    <Td>{e?.tgl_hps_sebelumnya ? DateConvert(new Date(e.tgl_hps_sebelumnya)).defaultDMY : '-'}</Td>
                    <Td textRight>{e?.avg_hps_beli_sebelumnya ? RupiahConvert(String(parseInt(e.avg_hps_beli_sebelumnya))).detail : '-'}</Td>
                    <Td>{e?.tgl_hps_terakhir ? DateConvert(new Date(e.tgl_hps_terakhir)).defaultDMY : '-'}</Td>
                    <Td textRight>{e?.avg_hps_beli_terakhir ? RupiahConvert(String(parseInt(e.avg_hps_beli_terakhir))).detail : '-'}</Td>
                    <Td textRight>
                      <div className="d-flex justify-content-between">
                        {!withoutCaret && <span>{e?.selisih && e?.selisih != 0  ? e.selisih >= 0 ? <IoCaretDownOutline size={18} className="text-success" /> : <IoCaretUpOutline size={18} className="text-danger" />  : ''}</span>}
                        <span>{e?.selisih ? `${RupiahConvert(String(parseInt(e.selisih))).detail} ${e?.persen ? `(${parseFloat(e.persen)}%)` : ''}` : '-'}</span>
                      </div>
                    </Td>
                    <Td>
                      <div className="d-flex">
                        <ReadButton onClick={() => onReadButtonClick(e)} />
                        <UpdateButton onClick={() => onUpdateButtonClick(e)} />
                      </div>
                    </Td>
                  </Tr>
                ))
              : <Tr>
                  <Td colSpan={11}>
                    <DataStatus 
                      text="Tidak ada data"
                    />
                  </Td>
                </Tr>
            }
          </TBody>
        </Table>
        <Pagination
          dataLength={paginationConfig?.dataLength}
          dataNumber={paginationConfig?.page * paginationConfig?.dataLength - paginationConfig?.dataLength + 1}
          dataPage={
            paginationConfig?.dataCount < paginationConfig?.dataLength
              ? paginationConfig?.dataCount
              : paginationConfig?.page * paginationConfig?.dataLength
          }
          dataCount={paginationConfig?.dataCount}
          currentPage={paginationConfig?.page}
          totalPage={paginationConfig?.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    )
  }

  return (
    <>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th style={{width: 100}}>Kode Item</Th>
            <Th style={{minWidth: 300,}} className="p-0">Nama Item</Th>
            <Th style={{minWidth: 100,}} className="p-0">Grup BUASO</Th>
            <Th style={{minWidth: 100,}} className="p-0">Satuan Beli</Th>
            <Th style={{minWidth: 100,}} className="p-0">Tgl. HPS Terbaru</Th>
            <Th style={{minWidth: 150,}} className="p-0">Rata-Rata Harga Terbaru</Th>
            <Th style={{minWidth: 150,}} className="p-0">Satuan Pakai</Th>
            <Th style={{minWidth: 150,}} className="p-0">Tgl. HPS Pakai</Th>
            <Th style={{minWidth: 150,}} className="p-0">HPS Pakai</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data && data?.length > 1
            ? data.map((e, i) => (
                <Tr key={i}>
                  <TdFixed>{TableNumber(paginationConfig.page, paginationConfig.dataLength, i)}</TdFixed>
                  <Td>{e?.kode_item ?? '-'}</Td>
                  <Td>{e?.nama_item ?? '-'}</Td>
                  <Td>{e?.nama_buaso ?? '-'}</Td>
                  <Td>{e?.satuan_beli ?? '-'}</Td>
                  <Td>{e?.tgl_hps_terakhir ? DateConvert(new Date(e.tgl_hps_terakhir)).defaultDMY : '-'}</Td>
                  <Td textRight>{e?.avg_hps_beli_terakhir ? RupiahConvert(String(parseInt(e.avg_hps_beli_terakhir))).detail : '-'}</Td>
                  <Td textRight>{e?.satuan_pakai ?? '-'}</Td>
                  <Td>{e?.tgl_hps_pakai ? DateConvert(new Date(e.tgl_hps_pakai)).defaultDMY : '-'}</Td>
                  <Td textRight>{e?.hps_pakai ? RupiahConvert(String(parseInt(e.hps_pakai))).detail : '-'}</Td>
                  <Td>
                    <div className="d-flex">
                      <UpdateButton onClick={() => onUpdateButtonClick(e)} />
                    </div>
                  </Td>
                </Tr>
              ))
            : <Tr>
                <Td colSpan={11}>
                  <DataStatus 
                    text="Tidak ada data"
                  />
                </Td>
              </Tr>
          }
        </TBody>
      </Table>
      <Pagination
          dataLength={paginationConfig?.dataLength}
          dataNumber={paginationConfig?.page * paginationConfig?.dataLength - paginationConfig?.dataLength + 1}
          dataPage={
            paginationConfig?.dataCount < paginationConfig?.dataLength
              ? paginationConfig?.dataCount
              : paginationConfig?.page * paginationConfig?.dataLength
          }
          dataCount={paginationConfig?.dataCount}
          currentPage={paginationConfig?.page}
          totalPage={paginationConfig?.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
    </>
  )
}
