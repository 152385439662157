// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Component
import {
  CRUDLayout,
  InputSearch,
  DataStatus,
  Pagination,
  THead,
  TBody,
  ThFixed,
  Alert,
  TdFixed,
  Tr,
  Th,
  Td,
  ActionButton,
  SelectSearch,
} from "../../../components";
import { Button, Modal, Row, Col, Card } from "react-bootstrap";
import { TableNumber, DateConvert } from "../../../utilities";
import { saveAs } from 'file-saver'
import { AiOutlineEye, AiOutlinePlus } from "react-icons/ai"

// API
import { KualifikasiVendorApi } from "../../../api";

const KualifikasiVendor = ({ setNavbarTitle }) => {
  let history = useHistory();
  const location = useLocation();

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(false);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("");

  // Data View
  const [data, setData] = useState([
    {
      jenis: "Supplier, Mandor",
    },
  ]);
  const dropdownStatusKualifikasi = [
    {
      value: 1,
      label: "Lolos"
    },
    {
      value: 0,
      label: "Tidak Lolos"
    },
    {
      value: null,
      label: "Semua"
    },
  ]
  const [statusKualifikasi, setStatusKualifikasi] = useState(undefined)
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);

  // alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  let createAlert = location?.state?.variant;

  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setShowAlert(false);

    KualifikasiVendorApi.getPage({
      page: page,
      per_page: dataLength,
      status_kualifikasi: statusKualifikasi,
      q: searchKey
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setTotalPage(res?.data?.total_page ?? []);
        setDataCount(res?.data?.data_count ?? []);
      })
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  const getExportData = (file) => {
    KualifikasiVendorApi.getExport({
        tipe: file,
        status_kualifikasi: statusKualifikasi
    })
    .then((res) => {
        const link = res?.data?.data
        const parse = 'https://' + link.split('/').filter((_val, idx) => idx !== 0).filter((_val, idx) => idx !== 0).join('/')
        saveAs(parse, "Kualifikasi Vendor")
    })
    .catch(() => {
        window.alert('Export data gagal')
    })
  }

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle("Kualifikasi Vendor");

    if (createAlert !== undefined) {
      setAlertConfig({ variant: location?.state?.variant, text: location?.state?.text });
      setShowAlert(true);
    } else {
      return getData();
    }

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      history.replace((createAlert = undefined));
      setIsLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey, statusKualifikasi]);

  // Tabel
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Prakualifikasi</ThFixed>
              <ThFixed>Kode Vendor</ThFixed>
              <Th>Nama Vendor</Th>
              <Th width={200}>Jenis Vendor</Th>
              <ThFixed>Status Kualifikasi</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={index}>
                  <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="text-left">{val.tgl_vendor_prakualifikasi ? DateConvert(new Date(val.tgl_vendor_prakualifikasi)).defaultDMY : '-'}</div>
                    <div className="text-left">{val.no_vendor_prakualifikasi ?? "-"}</div>
                  </TdFixed>
                  <TdFixed>{val.kode_vendor ?? "-"}</TdFixed>
                  <Td>{val.nama_vendor ?? "-"}</Td>
                  <Td>{val.jenis_vendor ?? "-"}</Td>
                  <TdFixed>{val.status_kualifikasi == "0" 
                    ? <div>PENDING</div> 
                    : val.status_kualifikasi == "1" 
                    ? <>
                        <div className="text-success">LOLOS</div>
                        <div>{val.tgl_kualifikasi ? DateConvert(new Date(val.tgl_kualifikasi)).defaultDMY : '-'}</div>
                      </>
                    : <>
                        <div className="text-danger">TIDAK LOLOS</div>
                        <div>{val.tgl_kualifikasi ? DateConvert(new Date(val.tgl_kualifikasi)).defaultDMY : '-'}</div>
                      </> //<div>{val.tgl_kualifikasi != null && val.tgl_kualifikasi.split(" ")[0]}</div>
                    }
                  </TdFixed>
                  <TdFixed>
                    <div className="text-center">
                      {val.status_kualifikasi == "0" ? (
                        <ActionButton
                          variant="success"
                          text={<AiOutlinePlus />}
                          onClick={() => history.push("/master/kualifikasi-vendor/kualifikasi/" + val.id_vendor_prakualifikasi )}
                        />
                      ) : (
                        <ActionButton
                          variant="primary"
                          text={<AiOutlineEye style={{fontSize:"15px"}} className="text-dark" />}
                          onClick={() => history.push("/master/kualifikasi-vendor/detail/" + val.id_vendor_prakualifikasi )}
                        />
                      )}
                    </div>
                  </TdFixed>
                </Tr>
            )})}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataPage={page * dataLength}
            dataCount={dataCount}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <Card className="p-3 mb-4">
        {/* <CRUDLayout>
          <CRUDLayout.Head>
            <CRUDLayout.HeadSearchSection> */}
                <Row>
                  <Col md="4" className="pt-4">
                    <InputSearch
                      onChange={(e) => {
                        setTimeout(() => {
                          setSearchKey(e.target.value);
                          setPage(1);
                        }, 1000);
                      }}
                      onSubmit={(e) => e.preventDefault()}
                    />
                  </Col>
                  <div className="ml-3" style={{width:'160px'}}>
                    <SelectSearch 
                      label="Status Kualifikasi"
                      placeholder="Pilih salah satu..."
                      name='status_kualifikasi'
                      defaulValue={dropdownStatusKualifikasi.value
                          ? dropdownStatusKualifikasi.find(val => dropdownStatusKualifikasi.value === val.value)
                          : ''
                      }
                      option={dropdownStatusKualifikasi}
                      onChange={e => {
                          setStatusKualifikasi(e.value)
                      }}
                    />
                  </div>
                  <div className="ml-4 pt-4">
                    <ActionButton
                      variant="outline-danger"
                      text="Export PDF"
                      onClick={() => getExportData('pdf')}
                    />
                  </div>
                  <div className="ml-2 pt-4">
                    <ActionButton
                      variant="outline-success"
                      text="Export Excel"
                      onClick={() => getExportData('excel')}
                    />
                  </div>
                </Row>
            {/* </CRUDLayout.HeadSearchSection>
          </CRUDLayout.Head>
        </CRUDLayout> */}
      </Card>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* Table */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      }
      </div>
  );
};

export default KualifikasiVendor;
