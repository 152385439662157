import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";

import {
  Alert,
  BackButton,
  ActionButton,
  UpdateButton,
  DeleteButton,
  DataStatus,
  Input,
  SelectSearch,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DatePicker,
  TextArea
} from "../../../../components";
import { DateConvert, RupiahConvert } from "../../../../utilities";
import { PurchaseOrderApi, SeleksiVendorApi, VendorApi } from "../../../../api";
import { IoAddOutline } from "react-icons/io5";
import { AiFillStar } from "react-icons/ai"
import { GoPrimitiveDot } from "react-icons/go"

const TambahSeleksiVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  console.log(dataSeleksiVendor);
  const [dataVendor, setDataVendor] = useState([]);
  const [dropdownVendor, setDropdownVendor] = useState([])
  const [dataListItemSeleksiVendor, setDataListItemSeleksiVendor] = useState([]);
  const [processedData, setProcessedData] = useState({});

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "create",
  });

  const getInitialData = () => {
    Axios.all([
      SeleksiVendorApi.getSingle({ id_purchase_request: id }), 
      VendorApi.get(),
      SeleksiVendorApi.getDropdown()
    ])
      .then(
        Axios.spread((seleksi, vendor, dropdown) => {
          const mapVendor = dropdown?.data?.data ? dropdown.data.data.map(val => ({ label: val.nama_vendor, value: val.id_vendor })) : []

          setDataSeleksiVendor(seleksi.data.data);
          setDataVendor(vendor.data.data);
          setDropdownVendor(dropdown?.data?.data ?? [])
        })
      )
      .catch((err) => {
        console.log(err);
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle("Tambah Seleksi Vendor");
    getInitialData();
    // getNoSeleksiVendor(today);

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const PageContent = () => {
    const InfoSection = () => {
      const InfoItem = ({ title, value }) => (
        <tr>
          <td>
            <small>{title}</small>
          </td>
          <td className="pl-3 pr-1">
            <small>:</small>
          </td>
          <td>
            <small>{value}</small>
          </td>
        </tr>
      );

      return (
        <>
          <div><b style={{fontSize:"15px"}}>Detail Data Purchase Request</b></div>
          <Card className="mb-4">
            <div className="p-2">
              <table>
                  <tbody>
                    <InfoItem
                      title="Tgl. Purchase Request"
                      value={dataSeleksiVendor.tgl_purchase_request ? DateConvert(new Date(dataSeleksiVendor.tgl_purchase_request)).detail : "-"}
                    />
                    <InfoItem
                      title="No. Purchase Request"
                      value={dataSeleksiVendor.no_purchase_request ? dataSeleksiVendor.no_purchase_request : "-"}
                    />
                    <InfoItem
                      title="Tgl. Pemakaian"
                      value={dataSeleksiVendor.tgl_pemakaian ? DateConvert(new Date(dataSeleksiVendor.tgl_pemakaian)).detail : "-"}
                    />
                    <InfoItem
                      title="Keperluan"
                      value={dataSeleksiVendor.keperluan ? dataSeleksiVendor.keperluan : "-"}
                    />
                  </tbody>
              </table>
            </div>
          </Card>
        </>
      )
    }
    const ListItemPurchaseRequestSection = () => {
      const dataListPR = dataSeleksiVendor.pr_detail ? dataSeleksiVendor.pr_detail : [];
      const cantProcess = dataListItemSeleksiVendor.length > 0 ? true : false;

      const TableListItemPurchaseRequest = () => (
        <>
          <div className="mt-auto"><b style={{fontSize:"15px"}}>List Item Barang Purchase Request</b></div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Item</ThFixed>
                <Th>Item Purchase request</Th>
                <Th>Satuan Beli</Th>
                <Th>Qty. Purchase Request</Th>
                <Th>Total Qty. Telah Diseleksi</Th>
                <Th>Qty Sisa</Th>
                <Th>Harga Perkiraan</Th>
                <ThFixed>Aksi</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {dataListPR.map((val, index) => (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kode_item}</TdFixed>
                  <Td>
                    <div style={{ width: "200px" }}>
                      {/* {val.dimensi === "3"
                        ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                        : val.dimensi === "2"
                        ? `${val.nama_item} (${val.pj} X ${val.lb})`
                        : val.dimensi === "1"
                        ? `${val.nama_item} (${val.pj})`
                        : val.dimensi === "0"
                        ? val.nama_item
                        : val.nama_item} */}
                      {val.nama_item}
                    </div>
                  </Td>
                  <Td>{val.satuan_beli}</Td>
                  <Td textRight>{val.qty_pr ? parseFloat(val.qty_pr).toPrecision() : "0"}</Td>
                  <Td textRight>{val.qty_ordered ? parseFloat(val.qty_ordered).toPrecision() : "0"}</Td>
                  <Td textRight>{val.qty_sisa ? parseFloat(val.qty_sisa).toPrecision() : "0"}</Td>
                  <Td textRight>
                    {RupiahConvert(parseInt(val.harga_perkiraan_sendiri).toString()).detail}
                  </Td>
                  <Td>
                    <ActionButton
                      size="sm"
                      className="text-nowrap"
                      text={<IoAddOutline />}
                      disable={cantProcess}
                      onClick={() => {
                        setProcessedData(val);
                        setModalConfig({
                          show: true,
                          type: "create",
                        });
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </>
      );

      return (
        <>
          {dataSeleksiVendor.pr_detail 
            ? dataSeleksiVendor.pr_detail.length > 0 
              ? <TableListItemPurchaseRequest />
              : <DataStatus text="Tidak ada data" />
            : <DataStatus text="Tidak ada data" />
          }
        </>
      );
    }
    const CatatanPR = () => {
      return (
        <>
          <div className="mt-4"><b style={{fontSize:"15px"}}>Catatan Purchase Request</b></div>
          <TextArea
              type="text"
              name="catatan"
              // placeholder="Masukkan catatan"
              value={dataSeleksiVendor.catatan_purchase_request}
              // onChange={handleChange}
              rows={4}
            />
        </>
      )
    }
    const ListHistorySV = () => {
      const dataListPR = dataSeleksiVendor.detail ? dataSeleksiVendor.detail : [];
      // const cantProcess = dataListItemSeleksiVendor.length > 0 ? true : false;

      const TableSV = () => (
        <div className="mt-4">
          <div className="mt-auto"><b style={{fontSize:"15px"}}>History Seleksi Vendor</b></div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Informasi Seleksi Vendor</ThFixed>
                <Th>Pembuat Seleksi Vendor</Th>
                <Th>Vendor</Th>
                <Th>Item Seleksi Vendor</Th>
                <Th>Satuan Beli</Th>
                <Th>Qty. Seleksi Vendor</Th>
                <Th>Harga Kesepakatan</Th>
              </Tr>
            </THead>
            <TBody>
              {dataListPR.length > 1 ? dataListPR.map((val, index) => (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>
                    <div className="text-left">{val.tgl_seleksi_vendor ? DateConvert(new Date(val.tgl_seleksi_vendor)).defaultDMY : "-"}</div>
                    <div className="text-left">{val.no_seleksi_vendor ?? "-"}</div>
                  </TdFixed>
                  <Td>
                    <div className="text-left">{val.nama_karyawan_pengaju ?? "-"}</div>
                    <div className="text-left">{val.nama_jabatan_karyawan_pengaju ?? "-"}</div>
                  </Td>
                  <Td>{val.nama_vendor ?? "-"}</Td>
                  <Td>{val.nama_item ?? "-"}</Td>
                  <Td>{val.satuan_beli ?? "-"}</Td>
                  <Td textRight>{val.qty_order ? parseFloat(val.qty_order).toPrecision() : "0"}</Td>
                  <Td textRight>{val.harga_kesepakatan ? RupiahConvert(parseInt(val.harga_kesepakatan).toString()).detail : "Rp.0"}</Td>
                </Tr>
              )) : <Tr><Td><DataStatus text="Tidak ada data" /></Td></Tr>}
            </TBody>
          </Table>
        </div>
      );

      return (
        <>
          {/* {dataSeleksiVendor.pr_detail 
            ? dataSeleksiVendor.pr_detail.length > 0 
              ? <TableListItemPurchaseRequest />
              : <DataStatus text="Tidak ada data" />
            : <DataStatus text="Tidak ada data" />
          } */}
          <TableSV />
        </>
      );
    }

    return (
      <>
        <InfoSection />
        <ListItemPurchaseRequestSection />
        <CatatanPR />
        <ListHistorySV />
      </>
    )
  };

  const PageModal = () => {
    const [modalAlertConfig, setModalAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });

    const InfoSection = () => {
      const InfoItem = ({ title, value }) => (
        <tr>
          <td>
            <small>{title}</small>
          </td>
          <td>
            <small className="pl-4 pr-2">:</small>
          </td>
          <td>
            <small>{value}</small>
          </td>
        </tr>
      );

      return (
        <>
          <table>
            <tbody>
              <InfoItem
                title="Tgl. Purchase Request"
                value={dataSeleksiVendor.tgl_purchase_request ? DateConvert(new Date(dataSeleksiVendor.tgl_purchase_request)).detail : "-"}
              />
              <InfoItem
                title="No. Purchase Request"
                value={dataSeleksiVendor.no_purchase_request ? dataSeleksiVendor.no_purchase_request : "-"}
              />
              <InfoItem
                title="Tgl. Pemakaian"
                value={dataSeleksiVendor.tgl_pemakaian ? DateConvert(new Date(dataSeleksiVendor.tgl_pemakaian)).detail : "-"}
              />
              <InfoItem
                title="Keperluan"
                value={dataSeleksiVendor.keperluan ? dataSeleksiVendor.keperluan : "-"}
              />
              <InfoItem
                title="Pengaju"
                value={dataSeleksiVendor.pengaju ? dataSeleksiVendor.nama_karyawan_pengaju : "-"}
              />
            </tbody>
          </table>
          <hr />
        </>
      );
    };

    const ModalTitle = () =>
      modalConfig.type.toLowerCase() === "update" ? (
        <span className="text-success">Ubah Item Seleksi Vendor</span>
      ) : modalConfig.type.toLowerCase() === "delete" ? (
        <span className="text-danger">Hapus Item Seleksi Vendor</span>
      ) : (
        <span className="text-primary">Tambah Item Seleksi Vendor</span>
      );

    const ModalForm = () => {
      const [noBaru, setNoBaru] = useState("");
      const getNoSeleksiVendor = (date) => {
        SeleksiVendorApi.getNomor({ tanggal: date })
          .then(({ data }) => {
            setNoBaru(data.data);
          })
          .catch(() => {
            setModalAlertConfig({
              show: true,
              variant: "danger",
              text: "Nomor vendor gagal diperoleh!",
            });
          });
      };

      useEffect(() => {
        getNoSeleksiVendor(new Date().toISOString().slice(0, 10));
        return () => {};
      }, []);

      const formInitialValues = {
        id_purchase_request: id,
        tgl_seleksi_vendor: new Date(),
        no_seleksi_vendor: noBaru,
        id_vendor: "",

        kode_item: processedData.kode_item,
        id_item_buaso: processedData.id_item_buaso,
        nama_item: processedData.nama_item,
        satuan_beli: processedData.satuan_beli,
        qty_pr: processedData.qty_pr,
        // qty_order: modalConfig.type.toLowerCase() === "update" ? processedData.qty_order : processedData.qty_ordered,
        qty_order: "0",
        harga_perkiraan_sendiri: processedData.harga_perkiraan_sendiri,
        harga_kesepakatan: processedData.harga_kesepakatan
          ? processedData.harga_kesepakatan
          : processedData.harga_perkiraan_sendiri,
        dimensi: processedData.dimensi ? processedData.dimensi : "0",
        pj: processedData.pj ? processedData.pj : "",
        lb: processedData.lb ? processedData.lb : "",
        tb: processedData.tb ? processedData.tb : "",
        keterangan: processedData.keterangan ? processedData.keterangan : "",
        qty_sisa: processedData.qty_sisa ? processedData.qty_sisa : "0"
      };

      const formValidationSchema = Yup.object().shape({
        tgl_seleksi_vendor: Yup.string().required("Pilih tanggal seleksi vendor"),
        no_seleksi_vendor: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
        id_vendor: Yup.string().required("Pilih vendor"),
        qty_order: Yup.string().required("Masukan qty"),
        harga_kesepakatan: Yup.string().required("Masukan harga kesepakatan"),
      });

      const formSubmitHandler = (values) => {
        const finalValues = {
          id_purchase_request: values.id_purchase_request,
          id_vendor: values.id_vendor,
          tgl_seleksi_vendor: values.tgl_seleksi_vendor,
          no_seleksi_vendor: values.no_seleksi_vendor,
          detail: [
            {
              id_item_buaso: values.id_item_buaso,
              qty_order: values.qty_order,
              harga_kesepakatan: parseInt(values.harga_kesepakatan),
            },
          ],
          keterangan: values.keterangan
        };
        SeleksiVendorApi.createOrUpdate(finalValues)
          .then((res) => {
            console.log("res", res);
            setAlertConfig({
              show: true,
              variant: "primary",
              text: `Berhasil tambah data`,
            });
          })
          .catch((err) =>
            setAlertConfig({
              show: true,
              variant: "danger",
              text: `Gagal tambah data ${err?.response?.data?.message ?? ""}`,
            })
          )
          .finally(() => setModalConfig({ ...modalConfig, show: false }));
      };

      return (
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Alert
                  showCloseButton
                  show={modalAlertConfig.show}
                  variant={modalAlertConfig.variant}
                  text={modalAlertConfig.text}
                  onClose={() =>
                    setModalAlertConfig({
                      ...modalAlertConfig,
                      show: false,
                    })
                  }
                />
                {noBaru ? (
                  <>
                    <InfoSection />
                    <Row>
                      <Col>
                        <DatePicker
                          label="Tgl. Seleksi Vendor"
                          dateFormat="dd/MM/yyyy"
                          selected={values.tgl_seleksi_vendor ? new Date(values.tgl_seleksi_vendor) : ""}
                          onChange={(e) => {
                            const value = e.toISOString().slice(0, 10);
                            setFieldValue("tgl_seleksi_vendor", value);
                            getNoSeleksiVendor(value);
                          }}
                          error={errors.tgl_seleksi_vendor && touched.tgl_seleksi_vendor && true}
                          errorText={errors.tgl_seleksi_vendor}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="text"
                          label="No. Seleksi Vendor"
                          placeholder="Pilih tanggal untuk menentukan nomor"
                          value={values.no_seleksi_vendor}
                          error={errors.no_seleksi_vendor && touched.no_seleksi_vendor && true}
                          errorText={errors.no_seleksi_vendor}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <SelectSearch
                      label="Vendor"
                      placeholder="Pilih vendor"
                      defaultValue={values.id_vendor ? { value: values.id_vendor, label: values.nama_vendor } : "" }
                      option={dropdownVendor.map((val) => {
                        return {
                          value: val.id_vendor,
                          status_rekomendasi : val.status_rekomendasi,
                          label : val.nama_vendor
                        };
                      })}
                      getOptionLabel={(option)=><span>
                        {option.label}  
                        {option.status_rekomendasi === "RECOMMEND" 
                        ? <AiFillStar className="text-success ml-2" style={{fontSize:"15px", marginTop:"-2px"}} /> 
                        : (option.status_rekomendasi === "NOT RECOMMEND") 
                        ? <GoPrimitiveDot className="text-warning ml-2" style={{fontSize:"22px"}} />
                        : "" 
                      } </span>}
                      onChange={(val) => { setFieldValue("id_vendor", val.value) }}
                      error={errors.id_vendor && touched.id_vendor && true}
                      errorText={errors.id_vendor && touched.id_vendor && errors.id_vendor}
                    />
                    <Input
                      label="Item Purchase Request"
                      type="text"
                      value={
                        values.dimensi === "3"
                          ? `${values.nama_item} (${values.pj} X ${values.lb} X ${values.tb})`
                          : values.dimensi === "2"
                          ? `${values.nama_item} (${values.pj} X ${values.lb})`
                          : values.dimensi === "1"
                          ? `${values.nama_item} (${values.pj})`
                          : values.dimensi === "0"
                          ? values.nama_item
                          : values.nama_item
                      }
                      readOnly
                    />
                    <Row>
                      <Col>
                        <Input
                          label="Qty. Purchase Request"
                          type="text"
                          value={values.qty_pr}
                          readOnly
                        />
                      </Col>
                      <Col>
                        <Input
                          label="Satuan Beli"
                          type="text"
                          value={values.satuan_beli}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input
                          label="Qty. Sisa"
                          type="text"
                          value={values.qty_sisa ? parseFloat(values.qty_sisa).toPrecision() : "0"}
                          readOnly
                        />
                      </Col>
                      <Col>
                        <Input
                          label="Harga Perkiraan Sendiri (HPS)"
                          type="text"
                          value={
                            RupiahConvert(parseInt(values.harga_perkiraan_sendiri).toString()).detail
                          }
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input
                          label="Qty. Order"
                          type="number"
                          name="qty_order"
                          placeholder="Masukan qty. order"
                          value={values.qty_order}
                          onChange={handleChange}
                          error={errors.qty_order && touched.qty_order && true}
                          errorText={errors.qty_order}
                        />
                      </Col>
                      <Col>
                        <Input
                          label="Harga Kesepakatan"
                          type="text"
                          placeholder="Masukan harga kesepakatan"
                          value={
                            RupiahConvert(parseInt(values.harga_kesepakatan).toString()).detail
                          }
                          onChange={(e) => {
                            const val = e.target.value;
                            const convert = RupiahConvert(val.toString()).default;
                            setFieldValue(
                              "harga_kesepakatan",
                              Number.isInteger(convert) ? convert : 0
                            );
                          }}
                          error={errors.harga_kesepakatan && touched.harga_kesepakatan && true}
                          errorText={errors.harga_kesepakatan}
                        />
                      </Col>
                    </Row>
                    <TextArea
                      label="Keterangan"
                      type="text"
                      name="keterangan"
                      placeholder="Masukkan keterangan"
                      value={values.keterangan}
                      onChange={handleChange}
                      rows={4}
                    />
                  </>
                ) : (
                  <DataStatus text="Memuat Data" loading={true} />
                )}
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  onClick={() => setModalConfig({ ...modalConfig, show: false })}
                  variant="secondary"
                  text="Batal"
                />
                {modalConfig.type.toLowerCase() === "update" ? (
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah Data"
                    loading={isSubmitting}
                  />
                ) : (
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Tambah"
                    loading={isSubmitting}
                  />
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      );
    };

    // const ModalDelete = () => {
    //   const [isDeleting, setIsDeleting] = useState(false);

    //   const deleteDataHandler = () => {
    //     setIsDeleting(true);

    //     const filteredData = dataListItemSeleksiVendor.filter(
    //       (value, index) => processedData.index !== index
    //     );

    //     setTimeout(() => {
    //       setDataListItemSeleksiVendor(filteredData);
    //       setIsDeleting(false);
    //       setModalConfig({
    //         ...modalConfig,
    //         show: false,
    //       });
    //       setAlertConfig({
    //         show: true,
    //         variant: "primary",
    //         text: "Data berhasil dihapus!",
    //       });
    //     }, 100);
    //   };

    //   useEffect(() => {
    //     return () => {
    //       setIsDeleting(false);
    //     };
    //   }, []);

    //   const ModalDeleteText = () => (
    //     <>
    //       <h5>
    //         <span>Hapus data dengan nama item: </span>
    //         <br />
    //         <b>{processedData.nama_item}</b>
    //       </h5>
    //       <small className="text-danger">Data yang dihapus tidak dapat dikembalikan!</small>
    //     </>
    //   );

    //   const ModalDeleteButton = () => (
    //     <div className="d-flex justify-content-center mt-3">
    //       <ActionButton
    //         variant="outline-secondary"
    //         className="m-1"
    //         text="Batal"
    //         onClick={() =>
    //           setModalConfig({
    //             ...modalConfig,
    //             show: false,
    //           })
    //         }
    //       />
    //       <ActionButton
    //         variant="danger"
    //         className="m-1"
    //         text="Hapus Data"
    //         loading={isDeleting}
    //         onClick={deleteDataHandler}
    //       />
    //     </div>
    //   );

    //   return (
    //     <>
    //       <Modal.Body className="text-center">
    //         <ModalDeleteText />
    //         <ModalDeleteButton />
    //       </Modal.Body>
    //     </>
    //   );
    // };

    return (
      <Modal
        size="lg"
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <ModalTitle />
        </Modal.Header>
        <ModalForm />
        {/* {modalConfig.type.toLowerCase() === "delete" ? <ModalDelete /> : <ModalForm />} */}
      </Modal>
    );
  };

  return (
    <div>
      <div className="d-flex align-items-flex-end">
        <div className="d-flex align-items-center ml-auto mb-4">
          <BackButton onClick={() => history.push("/transaksi/seleksi-vendor/purchase-request", { ...location?.state })} />
        </div>
      </div>
      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      {isPageLoading 
        ? <DataStatus loading={true} text="Memuat data . . ." />
        : isFetchingFailed 
          ? <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          : <PageContent />
      }
      {modalConfig.show && <PageModal />}
    </div>
  );
};

export default TambahSeleksiVendor;
