import {
  useState
} from 'react'
import {
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  TdFixed,
  ThFixed,
  Td,
  Th,
  ActionButton,
} from 'components'
import { 
  ModalDokumen 
} from '../Comps'
import { IoCheckmarkOutline } from 'react-icons/io5'

const Radio = ({...props}) => (
    <input 
      {...props}
      type="radio"
      style={{
        height: 18,
        width: 18
      }}
    />
)

export const TableKelengkapanDokumen = ({data = [], setData, action}) => {
  const [modal, setModal] = useState({
    show: false,
    index: null,
    data: {}
  })

  const onRadioChange = e => {
    const index = e.target.name
    const value = e.target.value
    const newData = [...data]

    newData.splice(index, 1, {
      ...newData[index],  
      dokumen: {
        ...newData[index]?.dokumen,
        have: value,
      }
    })

    setData(newData)
  }

  return (
    <>
      <div className="p-2">
        <b>Kelengkapan Dokumen</b>
      </div>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th style={{minWidth: 300}} className="p-2">Uraian</Th>
            <Th style={{width: 100}} className="p-2">Ada</Th>
            <Th style={{width: 100}} className="p-2">Tidak Ada</Th>
            <Th style={{width: 150}} className="p-2">{action === 'DETAIL' ? 'Dokumen' : 'Upload Dokumen'}</Th>
          </Tr>
        </THead>
        <TBody>
          {data && data?.length > 1
            ? data.map((e, i) => (
                <Tr key={i}>
                  <TdFixed>{i + 1}</TdFixed>
                  <Td>{e?.label ?? '-'}</Td>
                  <Td className="text-center">
                    {action === 'DETAIL'
                      ? e?.dokumen?.have === 'ada' ? <IoCheckmarkOutline size={20} /> : ''
                      : <Radio 
                          name={i}
                          value="ada"
                          checked={e?.dokumen?.have === "ada"}
                          // onChange={action === 'DETAIL' ? '' : onRadioChange}
                        />
                    }
                  </Td>
                  <Td className="text-center">
                    {action === 'DETAIL'
                      ? e?.dokumen?.have === 'tidak' ? <IoCheckmarkOutline size={20} /> : ''
                      : <Radio 
                          name={i}
                          value="tidak"
                          checked={e?.dokumen?.have === "tidak"}
                          // onChange={action === 'DETAIL' ? '' : onRadioChange}
                        />
                    }
                  </Td>
                  <Td>
                    <ActionButton
                      size="sm"
                      text="Cek Dokumen"
                      className="col"
                      onClick={() => setModal({show: true, index: i, data: e})}
                    />
                  </Td>
                </Tr>
              ))
            : <Tr>
                <Td colSpan={11}>
                  <DataStatus 
                    text="Tidak ada data"
                  />
                </Td>
              </Tr>
          }
        </TBody>
      </Table>
      <ModalDokumen 
        show={modal.show}
        onHide={() => setModal({show: false,index: null, data: {}})}
        value={modal.data}
        index={modal.index}
        data={data}
        setData={setData}
        action={action}
      />
    </>
  )
}