import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  ActionButton,
  UpdateButton,
  DeleteButton,
  DataStatus,
  Input,
  SelectSearch,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DatePicker,
} from "../../../../components";
import { DateConvert, RupiahConvert } from "../../../../utilities";
import { SeleksiVendorApi, VendorApi } from "../../../../api";
import { IoAddOutline } from "react-icons/io5";
import DetailSeleksiVendor from "../DetailSeleksiVendor";

const UbahSeleksiVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  console.log("id", id)
  const location = useLocation(); 
  console.log("use", location)
  const [idSeleksi, setIdSeleksi] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  const [dataVendor, setDataVendor] = useState([]);
  const [dataListItemSeleksiVendor, setDataListItemSeleksiVendor] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [dataForm, setDataForm] = useState({
    tgl_seleksi_vendor: "",
    no_seleksi_vendor: "",
    id_vendor: "",
    nama_vendor: "",
    qty_order: "",
    harga_kesepakatan: "",

  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "create",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([SeleksiVendorApi.getSingle({ id_seleksi_vendor: id }), VendorApi.get()])
      .then(
        Axios.spread((seleksi, vendor) => {
          setDataSeleksiVendor(seleksi.data.data);
          setDataVendor(vendor.data.data);
          setDataListItemSeleksiVendor(seleksi.data.data.detail);
          setDataForm({
            tgl_seleksi_vendor: seleksi.data.data.tgl_seleksi_vendor,
            no_seleksi_vendor: seleksi.data.data.no_seleksi_vendor,
            id_vendor: seleksi.data.data.id_vendor,
            nama_vendor: seleksi.data.data.nama_vendor,
            qty_order: seleksi.data.data.qty_order,
            harga_kesepakatan: seleksi.data.data.harga_kesepakatan,
          });
        })
      )
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  // const getNoSeleksiVendor = date => {
  //   SeleksiVendorApi.getNomor({tanggal: date})
  //     .then(({data}) => setDataForm({
  //       ...dataForm,
  //       tgl_seleksi_vendor: date,
  //       no_seleksi_vendor: data.data
  //     }))
  //     .catch(() => {
  //       setDataForm({
  //         ...dataForm,
  //         tgl_seleksi_vendor: '',
  //         no_seleksi_vendor: ''
  //       })
  //       setAlertConfig({
  //         show: true,
  //         variant: 'danger',
  //         text: 'Nomor gagal diperoleh!'
  //       })
  //     })
  // }

  useEffect(() => {
    setNavbarTitle("Ubah Seleksi Vendor");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const PageContent = () => {
    const formInitialValues = {
      id_purchase_request: dataSeleksiVendor.id_purchase_request,
      id_seleksi_vendor: dataSeleksiVendor.id_seleksi_vendor,
      tgl_seleksi_vendor: dataForm.tgl_seleksi_vendor,
      no_seleksi_vendor: dataForm.no_seleksi_vendor,
      id_vendor: dataForm.id_vendor,
      nama_vendor: dataForm.nama_vendor,
      nama_item: dataSeleksiVendor.nama_item,
      qty_pr: dataSeleksiVendor.qty_pr,
      satuan_beli: dataSeleksiVendor.satuan_beli,
      harga_perkiraan_sendiri: dataSeleksiVendor.harga_perkiraan_sendiri,
      qty_order: dataForm.qty_order,
      harga_kesepakatan: dataForm.harga_kesepakatan,
      qty_sisa: dataSeleksiVendor.qty_sisa
    };
    const formValidationSchema = Yup.object().shape({
      tgl_seleksi_vendor: Yup.string().required("Pilih tanggal seleksi vendor"),
      no_seleksi_vendor: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
      id_vendor: Yup.string().required("Pilih vendor"),
      qty_order: Yup.string().required("Masukan qty"),
      harga_kesepakatan: Yup.string().required("Masukan harga kesepakatan"),
    });
    const formSubmitHandler = (values) => {
      const detailData = dataListItemSeleksiVendor.map((val) => {
        return {
          id_item_buaso: val.id_item_buaso,
          qty_order: val.qty_order,
          harga_kesepakatan: parseInt(val.harga_kesepakatan),
        };
      });

      const finalValues = {
        id_purchase_request: values.id_purchase_request,
        id_seleksi_vendor: values.id_seleksi_vendor,
        tgl_seleksi_vendor: values.tgl_seleksi_vendor,
        no_seleksi_vendor: values.no_seleksi_vendor,
        id_vendor: values.id_vendor,
        detail: detailData,
      };

      SeleksiVendorApi.createOrUpdate(finalValues)
        .then((res) => {
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil diubah!",
          });
          getInitialData();
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal diubah!",
          });
        });
    };

    const InfoSection = () => {
      const InfoItem = ({ title, value }) => (
        <tr>
          <td>
            <small>{title}</small>
          </td>
          <td>
            <small className="pl-4 pr-2">:</small>
          </td>
          <td>
            <small>{value}</small>
          </td>
        </tr>
      );

      return (
        <Card className="mb-4">
          <div className="p-2">
            <Row>
              <Col>
                <table>
                  <tbody>
                    <InfoItem
                      title="Tgl. Purchase Request"
                      value={dataSeleksiVendor.tgl_purchase_request ? DateConvert(new Date(dataSeleksiVendor.tgl_purchase_request)).detail : "-"}
                    />
                    <InfoItem
                      title="No Purchase Request"
                      value={dataSeleksiVendor.no_purchase_request ?? "-"}
                    />
                    <InfoItem
                      title="Tgl. Pemakaian"
                      value={dataSeleksiVendor.tgl_pemakaian ? DateConvert(new Date(dataSeleksiVendor.tgl_pemakaian)).detail : "-" }
                    />
                    <InfoItem
                      title="Keperluan"
                      value={dataSeleksiVendor.keperluan ?? "-"}
                    />
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Card>
      );
    };
    const CatatanApprovalSV = () => {
      const InfoItem = ({ labelHeader, karyawanName, labelCatatan, catatanValue }) => (
        <>
            <small>{labelHeader}</small>
            <p><b>{karyawanName}</b></p>
            <small>{labelCatatan}</small>
            <p><b>{catatanValue}</b></p>
        </>
      );
      return (
        <>
          <div className="mt-auto"><b style={{fontSize:"15px"}}>Catatan Approval Seleksi Vendor</b></div>
          <Card className="mb-2">
            <Card.Body>
              <div className="d-flex">
                {dataSeleksiVendor.stakeholder === undefined
                ? <Col className="text-center"><small>Memuat Data...</small></Col>
                : dataSeleksiVendor.stakeholder.map((val, index) => (
                  <>
                    <div className="mr-5" sm key={index}>
                        <InfoItem
                            labelHeader={val.status_approval === "APP" 
                                ? "Pengesah" 
                                : index !== 0 
                                ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}` : ""
                            }
                            karyawanName={index !== 0 ? val.nama_karyawan ?? "-" : ""}
                            labelCatatan={val.status_approval === "APP" 
                                ? "Catatan Pengesah" 
                                : index !== 0 
                                ? `Catatan Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}` : ""
                            }
                            catatanValue={index !== 0 ? val.catatan : ""}
                        />
                    </div>
                  </>
                ))}
              </div>
            </Card.Body>
          </Card>
        </>
      )
    }

    const FormSection = ({ values, errors, touched, setFieldValue, handleChange, handleSubmit }) => {
      return (
        <>
          <div className="mt-auto"><b style={{fontSize:"15px"}}>List Data Seleksi Vendor</b></div>
          <Card className="mb-4">
            <div className="p-2">
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <DatePicker
                        label="Tgl. Seleksi Vendor"
                        dateFormat="dd/MM/yyyy"
                        className="mt-1"
                        selected={values.tgl_seleksi_vendor ? new Date(values.tgl_seleksi_vendor) : ""}
                        onChange={(e) => {
                          const value = e.toISOString().slice(0, 10);
                          setDataForm({ ...dataForm, tgl_seleksi_vendor: value });
                        }}
                        error={errors.tgl_seleksi_vendor && touched.tgl_seleksi_vendor && true}
                        errorText={errors.tgl_seleksi_vendor}
                      />
                    </Col>
                    <Col>
                      <Input
                        type="text"
                        label="No. Seleksi Vendor"
                        placeholder="Pilih tanggal untuk menentukan nomor"
                        value={values.no_seleksi_vendor}
                        error={errors.no_seleksi_vendor && touched.no_seleksi_vendor && true}
                        errorText={errors.no_seleksi_vendor}
                        readOnly
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <SelectSearch
                        label="Vendor"
                        placeholder="Pilih vendor"
                        defaultValue={values.id_vendor ? {  value: values.id_vendor, label: values.nama_vendor } : ""}
                        option={dataVendor.map((val) => { return { value: val.id_vendor, label: val.nama_vendor }})}
                        onChange={(val) => {
                          setDataForm({
                            ...dataForm,
                            id_vendor: val.value,
                            nama_vendor: val.label,
                          });
                        }}
                        error={errors.id_vendor && touched.id_vendor && true}
                        errorText={errors.id_vendor && touched.id_vendor && errors.id_vendor}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Input
                        label="Item Seleksi vendor"
                        type="text"
                        value={values.nama_item}
                        readOnly
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Input
                        label="Qty. Purchase Request"
                        className="text-right"
                        type="text"
                        value={values.qty_pr ? parseFloat(values.qty_pr).toPrecision() : "0"}
                        readOnly
                      />
                    </Col>
                    <Col>
                      <Input 
                        label="Satuan Beli" 
                        type="text" 
                        value={values.satuan_beli} 
                        readOnly 
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Input
                        label="Qty. Sisa"
                        type="text"
                        name="qty_sisa"
                        placeholder="Masukan qty. sisa"
                        value={values.qty_sisa ? parseFloat(values.qty_sisa).toPrecision() : "0"}
                        // onChange={handleChange}
                        // error={errors.qty_order && touched.qty_order && true}
                        // errorText={errors.qty_order}
                        readOnly={true}
                      />
                    </Col>
                    <Col>
                      <Input
                        label="Harga Perkiraan Sendiri (HPS)"
                        type="text"
                        value={values.harga_perkiraan_sendiri ? RupiahConvert(parseInt(values.harga_perkiraan_sendiri).toString()).detail : "-"}
                        readOnly
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Input
                        label="Qty. Seleksi Vendor"
                        type="number"
                        name="qty_order"
                        placeholder="Masukan qty. seleksi_vendor"
                        value={values.qty_order}
                        // onChange={handleChange}
                        onChange={(e) => {
                          const val = e.target.value;
                          const convert = parseFloat(val).toPrecision()
                          // setFieldValue("harga_kesepakatan", Number.isInteger(convert) ? convert : 0);
                          setDataForm({ ...dataForm, qty_order: convert });
                        }}
                        error={errors.qty_order && touched.qty_order && true}
                        errorText={errors.qty_order}
                      />
                    </Col>
                    <Col>
                      <Input
                        label="Harga Kesepakatan"
                        type="text"
                        placeholder="Masukan harga kesepakatan"
                        value={RupiahConvert(parseInt(values.harga_kesepakatan).toString()).detail}
                        // onChange={(val) => setDataForm({ ...dataForm, harga_kesepakatan: val })}
                        onChange={(e) => {
                          const val = e.target.value;
                          const convert = RupiahConvert(val.toString()).default;
                          // setFieldValue("harga_kesepakatan", Number.isInteger(convert) ? convert : 0);
                          setDataForm({ ...dataForm, harga_kesepakatan: Number.isInteger(convert) ? convert : 0});
                        }}
                        error={errors.harga_kesepakatan && touched.harga_kesepakatan && true}
                        errorText={errors.harga_kesepakatan}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </>
      );
    };
    const ListItemPurchaseRequestSection = () => {
      const cantProcess = dataListItemSeleksiVendor.length > 0 ? true : false;
      const TableListItemPurchaseRequest = () => (
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Kode Item</ThFixed>
              <Th>Nama Item</Th>
              <Th>Satuan Beli</Th>
              <Th>Qty PR</Th>
              <Th>Qty Order</Th>
              <Th>Harga Perkiraan</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataSeleksiVendor.pr_detail.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_item}</TdFixed>
                <Td>
                  <div style={{ width: "200px" }}>{val.nama_item}</div>
                </Td>
                <Td>{val.satuan_beli}</Td>
                <Td textRight>{val.qty_pr}</Td>
                <Td textRight>{val.qty_ordered}</Td>
                <Td textRight>
                  {RupiahConvert(parseInt(val.harga_perkiraan_sendiri).toString()).detail}
                </Td>
                <Td>
                  <ActionButton
                    size="sm"
                    className="text-nowrap"
                    text={<IoAddOutline />}
                    disable={cantProcess}
                    onClick={() => {
                      setProcessedData(val);
                      setModalConfig({
                        show: true,
                        type: "create",
                      });
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      );

      return (
        <>
          <div className="py-2 pl-1">
            <b>List Item Purchase Request</b>
          </div>
          {dataSeleksiVendor.pr_detail ? (
            dataSeleksiVendor.pr_detail.length > 0 ? (
              <TableListItemPurchaseRequest />
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </>
      );
    };
    const ListItemSeleksiVendorSection = () => {
      const TableListItemSeleksiVendor = () => {
        let total = 0;

        return (
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Item</ThFixed>
                <Th>Nama Item</Th>
                <Th>Satuan Beli</Th>
                <Th>Qty Order</Th>
                <Th>Harga Kesepakatan</Th>
                <Th>Jumlah</Th>
                <ThFixed>Aksi</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {dataListItemSeleksiVendor.map((val, index) => {
                total = total + val.harga_kesepakatan * val.qty_order;

                return (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item}</TdFixed>
                    <Td>
                      <div style={{ width: "200px" }}>{val.nama_item}</div>
                    </Td>
                    <Td>{val.satuan_beli}</Td>
                    <Td textRight>{val.qty_order}</Td>
                    <Td textRight>
                      {RupiahConvert(parseInt(val.harga_kesepakatan).toString()).detail}
                    </Td>
                    <Td textRight>
                      {
                        RupiahConvert((val.qty_order * parseInt(val.harga_kesepakatan)).toString())
                          .detail
                      }
                    </Td>
                    <Td className="d-flex">
                      {val.editable && (
                        <UpdateButton
                          onClick={() => {
                            setProcessedData({
                              index,
                              ...val,
                            });
                            setModalConfig({
                              show: true,
                              type: "update",
                            });
                          }}
                        />
                      )}
                      {val.deletable && (
                        <DeleteButton
                          onClick={() => {
                            setProcessedData({
                              index,
                              ...val,
                            });
                            setModalConfig({
                              show: true,
                              type: "delete",
                            });
                          }}
                        />
                      )}
                    </Td>
                  </Tr>
                );
              })}
              <Tr>
                <Td colSpan={7} textRight>
                  <b>Total</b>
                </Td>
                <Td textRight>
                  <b>{RupiahConvert(total.toString()).detail}</b>
                </Td>
              </Tr>
            </TBody>
          </Table>
        );
      };

      return (
        <>
          <div className="py-2 pl-1">
            <b>List Item Seleksi Vendor</b>
          </div>
          {dataListItemSeleksiVendor ? (
            dataListItemSeleksiVendor.length > 0 ? (
              <TableListItemSeleksiVendor />
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
          <hr />
        </>
      );
    };
    const ButtonSection = ({ isSubmitting }) => (
      <div className="d-flex justify-content-end my-3">
        <div className="mr-3">
          <ActionButton
            className="pl-4 pr-4"
            onClick={() => history.push("/transaksi/seleksi-vendor")}
            variant="secondary"
            text="Batal"
          />
        </div>
        <div>
          <ActionButton
            className="pl-4 pr-4"
            type="submit"
            variant="success"
            text="Ubah"
            loading={isSubmitting}
          />
        </div>
      </div>
    );

    return (
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <InfoSection />
            <FormSection 
              values={values} 
              errors={errors} 
              touched={touched} 
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
            <CatatanApprovalSV />

            {/* <ListItemPurchaseRequestSection /> */}
            {/* <ListItemSeleksiVendorSection /> */}
            <ButtonSection isSubmitting={isSubmitting} />
          </form>
        )}
      </Formik>
    );
  };

  const PageContentNew = () => {
    const formInitialValues = {
      id_purchase_request: dataSeleksiVendor.id_purchase_request,
      id_vendor: dataSeleksiVendor.id_vendor,
      nama_vendor: dataSeleksiVendor.nama_vendor,
      tgl_seleksi_vendor: dataSeleksiVendor.tgl_seleksi_vendor,
      no_seleksi_vendor: dataSeleksiVendor.no_seleksi_vendor,

      kode_item: dataSeleksiVendor.kode_item,
      id_item_buaso: dataSeleksiVendor.id_item_buaso,
      nama_item: dataSeleksiVendor.nama_item,

      satuan_beli: dataSeleksiVendor.satuan_beli,
      qty_pr: dataSeleksiVendor.qty_pr,
      harga_perkiraan_sendiri: dataSeleksiVendor.harga_perkiraan_sendiri,
      qty_order: dataSeleksiVendor.qty_order,
      harga_kesepakatan: dataSeleksiVendor.harga_kesepakatan,

      dimensi: dataSeleksiVendor.dimensi ? dataSeleksiVendor.dimensi : "0",
      pj: dataSeleksiVendor.pj ? dataSeleksiVendor.pj : "",
      lb: dataSeleksiVendor.lb ? dataSeleksiVendor.lb : "",
      tb: dataSeleksiVendor.tb ? dataSeleksiVendor.tb : "",
    };
    const formValidationSchema = Yup.object().shape({
      id_vendor: Yup.string().required("Pilih vendor"),
      tgl_seleksi_vendor: Yup.string().required("Pilih tanggal seleksi vendor"),
      qty_order: Yup.string().required("Masukan qty"),
      harga_kesepakatan: Yup.string().required("Masukan harga kesepakatan"),
    });
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_purchase_request: values.id_purchase_request,
        id_vendor: values.id_vendor,
        tgl_seleksi_vendor: values.tgl_seleksi_vendor,
        no_seleksi_vendor: values.no_seleksi_vendor,
        id_seleksi_vendor: id,
        detail: [
          {
            id_item_buaso: values.id_item_buaso,
            qty_order: values.qty_order,
            harga_kesepakatan: values.harga_kesepakatan,
          },
        ],
      };

      SeleksiVendorApi.createOrUpdate(finalValues)
        .then((res) => {
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil diubah!",
          });
          getInitialData();
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal diubah!",
          });
        });
    };

    const InfoSection = () => {
      const InfoItem = ({ title, value }) => (
        <tr>
          <td>
            <small>{title}</small>
          </td>
          <td>
            <small className="pl-4 pr-2">:</small>
          </td>
          <td>
            <small>{value}</small>
          </td>
        </tr>
      );

      return (
        <Card className="mb-4">
          <div className="p-2">
            <Row>
              <Col>
                <table>
                  <tbody>
                    <InfoItem
                      title="Tgl. Purchase Request"
                      value={dataSeleksiVendor.tgl_purchase_request ? DateConvert(new Date(dataSeleksiVendor.tgl_purchase_request)).detail : "-"}
                    />
                    <InfoItem
                      title="No Purchase Request"
                      value={dataSeleksiVendor.no_purchase_request ?? "-"}
                    />
                    <InfoItem
                      title="Tgl. Pemakaian"
                      value={dataSeleksiVendor.tgl_pemakaian ? DateConvert(new Date(dataSeleksiVendor.tgl_pemakaian)).detail : "-" }
                    />
                    <InfoItem
                      title="Keperluan"
                      value={dataSeleksiVendor.keperluan ?? "-"}
                    />
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Card>
      );
    };
    const CatatanApprovalSV = () => {
      const InfoItem = ({ labelHeader, karyawanName, labelCatatan, catatanValue }) => (
        <>
            <small>{labelHeader}</small>
            <p><b>{karyawanName}</b></p>
            <small>{labelCatatan}</small>
            <p><b>{catatanValue}</b></p>
        </>
      );
      return (
        <>
          <div className="mt-auto"><b style={{fontSize:"15px"}}>Catatan Approval Seleksi Vendor</b></div>
          <Card className="mb-2">
            <Card.Body>
              <div className="d-flex">
                {dataSeleksiVendor.stakeholder === undefined
                ? <Col className="text-center"><small>Memuat Data...</small></Col>
                : dataSeleksiVendor.stakeholder.map((val, index) => (
                  <>
                    <div className="mr-5" sm key={index}>
                        <InfoItem
                            labelHeader={val.status_approval === "APP" 
                                ? "Pengesah" 
                                : index !== 0 
                                ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}` : ""
                            }
                            karyawanName={index !== 0 ? val.nama_karyawan ?? "-" : ""}
                            labelCatatan={val.status_approval === "APP" 
                                ? "Catatan Pengesah" 
                                : index !== 0 
                                ? `Catatan Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}` : ""
                            }
                            catatanValue={index !== 0 ? val.catatan : ""}
                        />
                    </div>
                  </>
                ))}
              </div>
            </Card.Body>
          </Card>
        </>
      )
    }
    const onChangeSV = (dates, values, setValues) => {
      const selected = dates ? DateConvert(dates).default : null
      setValues({ ...values, tgl_seleksi_vendor: selected })
    }

    const FormSection = ({ values, errors, touched, setFieldValue, handleChange, setValues, handleSubmit }) => {
      return (
        <>
          <div className="mt-auto"><b style={{fontSize:"15px"}}>List Data Seleksi Vendor</b></div>
          <Card className="mb-4">
            <div className="p-2">
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Input
                        label="Tgl. Seleksi Vendor"
                        type="date"
                        name="tgl_seleksi_vendor"
                        value={values.tgl_seleksi_vendor}
                        error={errors.tgl_seleksi_vendor && touched.tgl_seleksi_vendor && true}
                        errorText={errors.tgl_seleksi_vendor}
                        onChange={(e) => {
                          setFieldValue("tgl_seleksi_vendor", e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Input
                        type="text"
                        label="No. Seleksi Vendor"
                        placeholder="Pilih tanggal untuk menentukan nomor"
                        value={values.no_seleksi_vendor}
                        error={errors.no_seleksi_vendor && touched.no_seleksi_vendor && true}
                        errorText={errors.no_seleksi_vendor}
                        readOnly
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <SelectSearch
                        label="Vendor"
                        placeholder="Pilih vendor"
                        defaultValue={ values.id_vendor ? { value: values.id_vendor, label: values.nama_vendor } : ""}
                        option={dataVendor.map((val) => { return { value: val.id_vendor, label: val.nama_vendor }})}
                        onChange={(val) => { setFieldValue("id_vendor", val.value)}}
                        error={errors.id_vendor && touched.id_vendor && true}
                        errorText={errors.id_vendor && touched.id_vendor && errors.id_vendor}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Input
                        label="Item Seleksi vendor"
                        type="text"
                        value={values.nama_item}
                        readOnly
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Input
                        label="Qty. Purchase Request"
                        className="text-right"
                        type="text"
                        value={values.qty_pr ? parseFloat(values.qty_pr).toPrecision() : "0"}
                        readOnly
                      />
                    </Col>
                    <Col>
                      <Input 
                        label="Satuan Beli" 
                        type="text" 
                        value={values.satuan_beli} 
                        readOnly 
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Input
                        label="Qty. Sisa"
                        type="text"
                        name="qty_sisa"
                        placeholder="Masukan qty. sisa"
                        value={values.qty_sisa ? parseFloat(values.qty_sisa).toPrecision() : "0"}
                        // onChange={handleChange}
                        // error={errors.qty_order && touched.qty_order && true}
                        // errorText={errors.qty_order}
                        readOnly={true}
                      />
                    </Col>
                    <Col>
                      <Input
                        label="Harga Perkiraan Sendiri (HPS)"
                        type="text"
                        value={values.harga_perkiraan_sendiri ? RupiahConvert(parseInt(values.harga_perkiraan_sendiri).toString()).detail : "-"}
                        readOnly
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Input
                        label="Qty. Seleksi Vendor"
                        type="number"
                        name="qty_order"
                        placeholder="Masukan qty. seleksi_vendor"
                        value={values.qty_order}
                        onChange={handleChange}
                        error={errors.qty_order && touched.qty_order && true}
                        errorText={errors.qty_order}
                      />
                    </Col>
                    <Col>
                      <Input
                        label="Harga Kesepakatan"
                        type="text"
                        placeholder="Masukan harga kesepakatan"
                        value={RupiahConvert(parseInt(values.harga_kesepakatan).toString()).detail}
                        onChange={(e) => {
                          const val = e.target.value;
                          const convert = RupiahConvert(val.toString()).default;
                          setFieldValue("harga_kesepakatan", Number.isInteger(convert) ? convert : 0 )}
                        }
                        error={errors.harga_kesepakatan && touched.harga_kesepakatan && true}
                        errorText={errors.harga_kesepakatan}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </>
      );
    };
    const ButtonSection = ({ isSubmitting }) => (
      <div className="d-flex justify-content-end my-3">
        <div className="mr-3">
          <ActionButton
            className="pl-4 pr-4"
            onClick={() => history.push("/transaksi/seleksi-vendor")}
            variant="secondary"
            text="Batal"
          />
        </div>
        <div>
          <ActionButton
            className="pl-4 pr-4"
            type="submit"
            variant="success"
            text="Ubah"
            loading={isSubmitting}
          />
        </div>
      </div>
    );

    return (
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <InfoSection />
            <FormSection 
              values={values} 
              errors={errors} 
              touched={touched} 
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
            <CatatanApprovalSV />
            <ButtonSection isSubmitting={isSubmitting} />
          </form>
        )}
      </Formik>
    );
  };

  const PageModal = () => {
    const ModalTitle = () =>
      modalConfig.type.toLowerCase() === "update" ? (
        <span className="text-success">Ubah Item Seleksi Vendor</span>
      ) : modalConfig.type.toLowerCase() === "delete" ? (
        <span className="text-danger">Hapus Item Seleksi Vendor</span>
      ) : (
        <span className="text-primary">Tambah Item Seleksi Vendor</span>
      );

    const ModalForm = () => {
      const formInitialValues = {
        kode_item: processedData.kode_item,
        id_item_buaso: processedData.id_item_buaso,
        nama_item: processedData.nama_item,
        satuan_beli: processedData.satuan_beli,
        qty_pr: processedData.qty_pr,
        qty_order:
          modalConfig.type.toLowerCase() === "update"
            ? processedData.qty_order
            : processedData.qty_ordered,
        harga_perkiraan_sendiri: processedData.harga_perkiraan_sendiri,
        harga_kesepakatan: processedData.harga_kesepakatan
          ? processedData.harga_kesepakatan
          : processedData.harga_perkiraan_sendiri,
        editable: true,
        deletable: true,
      };

      const formValidationSchema = Yup.object().shape({
        qty_order: Yup.string().required("Masukan qty"),
        harga_kesepakatan: Yup.string().required("Masukan harga kesepakatan"),
      });

      const formSubmitHandler = (value) => {
        const createData = () => {
          setDataListItemSeleksiVendor([...dataListItemSeleksiVendor, value]);
          setModalConfig({
            ...modalConfig,
            show: false,
          });
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil ditambah!",
          });
        };

        const updateData = () => {
          const filteredData = [];

          dataListItemSeleksiVendor.map((val, index) =>
            index === processedData.index ? filteredData.push(value) : filteredData.push(val)
          );

          setDataListItemSeleksiVendor(filteredData);
          setModalConfig({
            ...modalConfig,
            show: false,
          });
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil diubah!",
          });
        };

        setTimeout(() => {
          modalConfig.type === "update" ? updateData() : createData();
        }, 100);
      };

      return (
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue, handleChange, handleSubmit, }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Item Purchase Request"
                  type="text"
                  value={values.nama_item}
                  readOnly
                />
                <Row>
                  <Col>
                    <Input
                      label="Qty. Purchase Request"
                      type="text"
                      value={values.qty_pr ? values.qty_pr : "-"}
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Input label="Satuan Beli" type="text" value={values.satuan_beli} readOnly />
                  </Col>
                </Row>
                <Input
                  label="Harga Perkiraan Sendiri (HPS)"
                  type="text"
                  value={
                    values.harga_perkiraan_sendiri
                      ? RupiahConvert(parseInt(values.harga_perkiraan_sendiri).toString()).detail
                      : "-"
                  }
                  readOnly
                />
                <Row>
                  <Col>
                    <Input
                      label="Qty. Order"
                      type="text"
                      name="qty_order"
                      placeholder="Masukan qty. order"
                      value={values.qty_order}
                      onChange={handleChange}
                      error={errors.qty_order && touched.qty_order && true}
                      errorText={errors.qty_order}
                    />
                  </Col>
                  <Col>
                    <Input
                      label="Harga Kesepakatan"
                      type="text"
                      placeholder="Masukan harga kesepakatan"
                      value={RupiahConvert(parseInt(values.harga_kesepakatan).toString()).detail}
                      onChange={(e) => {
                        const val = e.target.value;
                        const convert = RupiahConvert(val.toString()).default;
                        setFieldValue("harga_kesepakatan", Number.isInteger(convert) ? convert : 0);
                      }}
                      error={errors.harga_kesepakatan && touched.harga_kesepakatan && true}
                      errorText={errors.harga_kesepakatan}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                {modalConfig.type.toLowerCase() === "update" ? (
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah Data"
                    loading={isSubmitting}
                  />
                ) : (
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Tambah Data"
                    loading={isSubmitting}
                  />
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      );
    };

    const ModalDelete = () => {
      const [isDeleting, setIsDeleting] = useState(false);

      const deleteDataHandler = () => {
        setIsDeleting(true);

        const filteredData = dataListItemSeleksiVendor.filter(
          (value, index) => processedData.index !== index
        );

        setTimeout(() => {
          setDataListItemSeleksiVendor(filteredData);
          setIsDeleting(false);
          setModalConfig({
            ...modalConfig,
            show: false,
          });
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil dihapus!",
          });
        }, 100);
      };

      useEffect(() => {
        return () => {
          setIsDeleting(false);
        };
      }, []);

      const ModalDeleteText = () => (
        <>
          <h5>
            <span>Hapus data dengan nama item: </span>
            <br />
            <b>{processedData.nama_item}</b>
          </h5>
          <small className="text-danger">Data yang dihapus tidak dapat dikembalikan!</small>
        </>
      );

      const ModalDeleteButton = () => (
        <div className="d-flex justify-content-center mt-3">
          <ActionButton
            variant="outline-secondary"
            className="m-1"
            text="Batal"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            variant="danger"
            className="m-1"
            text="Hapus Data"
            loading={isDeleting}
            onClick={deleteDataHandler}
          />
        </div>
      );

      return (
        <>
          <Modal.Body className="text-center">
            <ModalDeleteText />
            <ModalDeleteButton />
          </Modal.Body>
        </>
      );
    };

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <ModalTitle />
        </Modal.Header>
        {modalConfig.type.toLowerCase() === "delete" ? <ModalDelete /> : <ModalForm />}
      </Modal>
    );
  };

  return (
    <div>
      <div className="d-flex align-items-flex-end">
        <div className="mt-auto"><b style={{fontSize:"15px"}}>Detail Data Purchase Request</b></div>
        <div className="d-flex align-items-center ml-auto mb-4">
          <BackButton onClick={() => history.push("/transaksi/seleksi-vendor", { ...location?.state })} />
        </div>
      </div>
      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      {isPageLoading 
        ? <DataStatus loading={true} text="Memuat data . . ." />
        : isFetchingFailed 
        ? <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
        : <PageContentNew />
      }
      <PageModal />
    </div>
  );
};

export default UbahSeleksiVendor;
