import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Row, Col, ButtonGroup } from "react-bootstrap"
import axios from "axios"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
  FilterButton
} from "components"
import { TableNumber, DateConvert } from "utilities"
import { IoAddOutline } from "react-icons/io5"
import { TransferUpahProduksiApi } from "api"
import { ModalFilterPermintaanProduksi } from "./Section"

const ItemCollapse = ({ data }) => {
  const [isReadMoreClick, setisReadMoreClick] = useState(false)
  const Item = ({ val, index }) => (<li key={index}>{val.nama_item} {val.qty_permintaan_produksi ? `${parseFloat(val?.qty_permintaan_produksi)} ${val?.nama_satuan}` : ''}</li>)
  return (
    <>
      <ul className="pl-3 mb-1" style={{ fontSize: "13px" }}>
        {data.map((val, index) => {
          if (isReadMoreClick) {
            return <Item val={val} key={index} />
          } else {
            return index < 2 && <Item val={val} key={index} />
          }
        })}
      </ul>

      {data.length > 2 &&
        <div
          className="text-primary"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => setisReadMoreClick((prev) => !prev)}
        >
          {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
        </div>}
    </>
  )
}

const ListPermintaanUpahProduksi = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataJobOrder, setDataJobOrder] = useState([])
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({ show: false, type: "filter", data: {} })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.permintaan?.filter?.active,
      tgl_permintaan_produksi_mulai: location?.state?.permintaan?.filter?.tgl_permintaan_produksi_mulai,
      tgl_permintaan_produksi_selesai: location?.state?.permintaan?.filter?.tgl_permintaan_produksi_selesai,
      tgl_job_order_mulai: location?.state?.permintaan?.filter?.tgl_job_order_mulai,
      tgl_job_order_selesai: location?.state?.permintaan?.filter?.tgl_job_order_selesai,
      item_produksi: location?.state?.permintaan?.filter?.item_produksi,
      petugas_produksi: location?.state?.permintaan?.filter?.petugas_produksi,
      // depo_produksi: location?.state?.permintaan?.filter?.depo_produksi,
      keperluan_produksi: location?.state?.permintaan?.filter?.keperluan_produksi
    },
    pagination: {
      page: location?.state?.permintaan?.filter?.page ?? "1",
      dataLength: location?.state?.permintaan?.filter?.dataLength ?? "10",
      totalPage: location?.state?.permintaan?.filter?.totalPage ?? "1",
      dataCount: location?.state?.permintaan?.filter?.dataCount ?? "0",
    }
  })
  const [dropdown, setDropdown] = useState({
    item_produksi: [{ value: undefined, label: 'Semua' },],
    petugas: [{ value: undefined, label: 'Semua' },],
    // depo: [{ value: undefined, label: 'Semua' },],
    keperluan: [
      { value: undefined, label: 'Semua' },
      { value: "penjualan", label: 'Penjualan' },
      { value: "stok", label: 'Stok' },
    ],
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    TransferUpahProduksiApi.getPreform({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_permintaan_produksi_mulai: dataFilter?.filter?.tgl_permintaan_produksi_mulai,
      tgl_permintaan_produksi_selesai: dataFilter?.filter?.tgl_permintaan_produksi_selesai,
      tgl_job_order_mulai: dataFilter?.filter?.tgl_job_order_mulai,
      tgl_job_order_selesai: dataFilter?.filter?.tgl_job_order_selesai,
      item_produksi: dataFilter?.filter?.item_produksi,
      petugas_produksi: dataFilter?.filter?.petugas_produksi,
      // depo_produksi: dataFilter?.filter?.depo_produksi,
      keperluan_produksi: dataFilter?.filter?.keperluan_produksi
    })
      .then(data => {
        setDataJobOrder(data?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      })
      .catch((err) => {
        setDataJobOrder([])
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }
  const fetchDropdown = () => {
    axios.all([
      TransferUpahProduksiApi.getDropdown({ tipe: 'upah' }),
      TransferUpahProduksiApi.getDropdown({ tipe: 'karyawan' }),
      // TransferUpahProduksiApi.getDropdown({ tipe: 'depo' })
    ])
      .then(axios.spread((resUpah, resKaryawan, resDepo) => {
        const mapItem = resUpah.data.data.map((item) => ({ value: item.id_item_buaso, label: item.nama_item }))
        const mapPetugas = resKaryawan.data.data.map(item => ({ value: item.id_karyawan, label: item.nama_karyawan }))
        // const mapDepo = resDepo.data.data.map(item => ({ value: item.id_depo, label: item.nama_depo }))

        setDropdown((prev) => ({
          ...prev,
          item_produksi: prev.item_produksi.concat(mapItem),
          petugas: prev.petugas.concat(mapPetugas),
          // depo: prev.depo.concat(mapDepo)
        }))
      }))
  }
  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }
  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  useEffect(() => {
    fetchDropdown()

    return () => {
      setDropdown({ item_produksi: [], petugas: [], keperluan: [] }) //depo: []
    }
  }, [])

  useEffect(() => {
    setNavbarTitle("Transfer Upah Produksi")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    setNavbarTitle, 
    searchConfig.key, 
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.tgl_permintaan_produksi_mulai,
    dataFilter?.filter?.tgl_permintaan_produksi_selesai,
    dataFilter?.filter?.tgl_job_order_mulai,
    dataFilter?.filter?.tgl_job_order_selesai,
    dataFilter?.filter?.item_produksi,
    dataFilter?.filter?.petugas_produksi,
    // dataFilter?.filter?.depo_produksi,
    dataFilter?.filter?.keperluan_produksi
  ])

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Informasi Permintaan Produksi</ThFixed>
            <ThFixed>Informasi Job Order</ThFixed>
            <Th>Item Produksi</Th>
            <Th>Item Upah Yang Diminta</Th>
            <Th width={100}>Qty. Produksi</Th>
            <ThFixed>Keperluan Produksi</ThFixed>
            <Th>Petugas Produksi</Th>
            {/* <Th>Depo Produksi</Th> */}
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataJobOrder?.map((val, index) => {
            return (
              <Tr key={index}>
                <Td className="text-center">{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</Td>
                <Td>
                  <div> {val.tgl_permintaan_produksi ? DateConvert(new Date(val.tgl_permintaan_produksi)).defaultDMY : "-"} </div>
                  <div> {val.no_permintaan_produksi ?? "-"} </div>
                </Td>
                <Td>
                  <div> {val.tgl_job_order ? DateConvert(new Date(val.tgl_job_order)).defaultDMY : "-"} </div>
                  <div> {val.no_job_order ?? "-"} </div>
                </Td>
                <Td>{val.nama_item ?? "-"}</Td>
                <Td> <ItemCollapse data={val?.detail ?? []} /> </Td>
                <Td>
                  {parseFloat(val.qty_produksi ?? 0).toPrecision()}{" "}
                  {val.kode_satuan}
                </Td>
                <Td className="text-capitalize">{val.keperluan_produksi ?? "-"}</Td>
                <Td> {val.nama_karyawan} </Td>
                {/* <Td>{val.nama_depo ?? "-"}</Td> */}
                <TdFixed>
                  <ButtonGroup>
                    <ActionButton
                      size="sm"
                      onClick={() => history.push(`/transaksi/transfer-upah-produksi/tambah/${val.id_permintaan_produksi}`, {...location?.state, permintaan: dataFilter})}>
                      <IoAddOutline />
                    </ActionButton>
                  </ButtonGroup>
                </TdFixed>
              </Tr>
            )
          })}
        </TBody>
      </Table >
    )

    if (!dataJobOrder || dataJobOrder?.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <small className="font-weight-bold">List Permintaan Produksi Siap Dibuatkan Transfer Upah Produksi</small>
        <DataTable />

        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
            <FilterButton 
                active={dataFilter?.filter?.active}
                onClick={() => setModalConfig({ show: true, type: 'filter' })}
              />
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton className="mr-2"
            onClick={() => history.push("/transaksi/transfer-upah-produksi", {...location?.state, permintaan: dataFilter})}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })
        }
      />

      {/* content */}
      {isPageLoading
        ? (<DataStatus loading={true} text="Memuat data . . ." />)
        : (<PageContent />)
      }
      {/* ModalFilter */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterPermintaanProduksi
          dropdown={dropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

    </CRUDLayout>
  )
}

export default ListPermintaanUpahProduksi