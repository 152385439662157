import {
  useState,
  useEffect
} from 'react'
import {
  useHistory,
  useParams
} from 'react-router-dom'
import {
  Formik
} from 'formik'
import { 
  ActionButton, 
  BackButton, 
  DataStatus
} from 'components'
import {
  PrakualifikasiVendorApi,
} from 'api'
import {
  SectionPrakualifikasiVendor
} from './Comps'
import {
  dataKelengkapanDokumen as dataKelengkapan,
  formInitialValues,
  formValidationSchema
} from './Utils'

const PrakualifikasiVendorDetail = ({setNavbarTitle}) => {
  const history = useHistory()
  const { id } = useParams()
  const [dataDetail, setDataDetail] = useState({})
  const [dataItemBahan, setDataItemBahan] = useState([])
  const [dataItemSubcon, setDataItemSubcon] = useState([])
  const [dataItemUpah, setDataItemUpah] = useState([])
  const [dataKelengkapanDokumen, setDataKelengkapanDokumen] = useState([])
  const [dataDokumenLainnya, setDataDokumenLainnya] = useState([])
  const [fetching, setFetching] = useState({
    loading: true,
    error: true
  })

  const getInitialData = () => PrakualifikasiVendorApi.single(id)
    .then((res) => {
      const getDataDetail = res?.data?.data ?? {}

      setDataDetail(getDataDetail)
      setDataItemBahan(getDataDetail?.jumlah_item ? getDataDetail.jumlah_item.map(val => ({
        id_item_bahan: val?.id_item_bahan,
        kode_item: val?.kode_item,
        nama_item: val?.nama_item,
        satuan_pakai: val?.nama_satuan,
        jumlah: val?.jumlah_item
      })) : [])
      setDataItemSubcon(getDataDetail?.vendor_item_subcon)
      setDataItemUpah(getDataDetail?.vendor_item_upah)
      setDataKelengkapanDokumen(dataKelengkapan(getDataDetail?.dokumen_utama ?? []))
      setDataDokumenLainnya(getDataDetail?.dokumen_lainnya 
        ? getDataDetail?.dokumen_lainnya?.map(val => ({
            uraian: val?.nama_dokumen ?? '-',
            dokumen: {
              file: undefined,
              name: val?.file_path ?? undefined,
              link: val?.file_path ?? undefined
            }
          })) 
        : []
      )
      setFetching({
        loading: false,
        error: false
      })
    })
    .catch(() => {
      setFetching({
        loading: false,
        error: true
      })
    })

  useEffect(() => {
    setNavbarTitle('Detail Prakualifikasi Vendor')
    getInitialData()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between align-items-end mb-2">
        <b className="px-2" style={{fontSize: 17}}>Data Prakualifikasi Vendor</b>
        <BackButton size="sm" onClick={history.goBack} />
      </div>
      <div className="bg-white border rounded p-3">
        {fetching.loading || fetching.error
          ? <DataStatus 
              loading={fetching.loading}
              text={fetching.loading ? 'Memuat data . . .' : 'Data gagal dimuat'}
            />
          : <Formik
              initialValues={{}}
            >
              {() => (
                <>
                  <SectionPrakualifikasiVendor 
                    action="DETAIL"
                    jenisVendor={dataDetail?.jenis_vendor ?? []}
                    dataDetail={dataDetail}
                    dataItemBahan={dataItemBahan}
                    dataItemUpah={dataItemUpah}
                    dataItemSubcon={dataItemSubcon}
                    dataDokumen={dataKelengkapanDokumen}
                    dataDokumenLainnya={dataDokumenLainnya}
                    setDataItemBahan={setDataItemBahan}
                    setDataDokumen={setDataKelengkapanDokumen}
                    setDataDokumenLainnya={setDataDokumenLainnya}
                  />
                </>
              )}
            </Formik>
        }
      </div>
    </>
  )
}

export default PrakualifikasiVendorDetail