const RadioButtonWrapper = ({label, errorText, children, orderRow}) => (
  <div className="mb-2 d-flex flex-column">
    <label>
      <small>
        {label}
      </small>
    </label>
    <div className={orderRow ? 'd-flex flex-column' : ''}>
      {children}
    </div>
    <small className="mt-1 text-danger">
      {errorText}
    </small>
  </div>
)

export default RadioButtonWrapper