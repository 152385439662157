import React, { useState, useEffect, useContext, createContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Layout, PageLoader } from "components";
import { AuthContext, NotifContext } from "utilities";
import { AuthApi } from "api";
import Dashboard from "pages/Dashboard";
import NotFound from "pages/404";
import Login from "pages/Login";
import Config from "config";

const MyRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataNotif, setDataNotif] = useState({});
  const { state } = useContext(AuthContext);
  const { isAuthenticated, role } = state;
  const { ROUTES } = Config;

  // Fetch notifikasi
  const checkNotifHandler = () => {
    // setIsLoading(true);

    AuthApi.notif()
      .then((res) => {
        const notif = res.data.data;
        setDataNotif(notif);
      })
      .catch(() => {
        window.alert("Notifikasi gagal diperoleh!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      isAuthenticated && checkNotifHandler();
      !isAuthenticated && setIsLoading(false);
    }, 1000);

    return () => {
      clearTimeout();
    };
  }, [isAuthenticated, role]);

  // Routes pada saat terotentikasi
  const AuthRoutes = ({ setNavbarTitle }) => {
    return (
      <Switch>
        {/* DASHBOARD */}
        <Route exact path="/">
          <Dashboard setNavbarTitle={setNavbarTitle} />
        </Route>

        {/* ROUTES */}
        {ROUTES.map(
          (val, index) =>
            val.hak &&
            val.hak.some((hak) => role.includes(hak)) && (
              <Route key={index} exact={val.exact} path={val.path}>
                <val.page setNavbarTitle={setNavbarTitle} />
              </Route>
            )
        )}

        {/* LOGIN FORM */}
        <Route path="/login">
          <Redirect to="/" />
        </Route>

        {/* 404-NOT FOUND */}
        <Route path="*">
          <NotFound setNavbarTitle={setNavbarTitle} />
        </Route>
      </Switch>
    );
  };

  // Routes pada saat belum terotentikasi
  const UnAuthRoutes = () => (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );

  // Routing keseluruhan
  const Routes = () => {
    if (!isAuthenticated) {
      return <UnAuthRoutes />;
    }

    return (
      <Layout>
        <AuthRoutes />
      </Layout>
    );
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <NotifContext.Provider value={dataNotif}>
      <BrowserRouter basename="/">
        <Routes />
      </BrowserRouter>
    </NotifContext.Provider>
  );
};

export default MyRoutes;
