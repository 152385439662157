import Services from "../../../services";

class SeleksiVendorApi {
  get(params) {
    return Services.get("/seleksi_vendor/page", { params });
  }

  getVendor() {
    return Services.get("/vendor_master");
  }

  getSingle(params) {
    return Services.get("/seleksi_vendor/single", { params });
  }

  getPurchaseRequest(params) {
    return Services.get("/seleksi_vendor/purchase_request", { params });
  }

  getNomor(params) {
    return Services.get("/seleksi_vendor/no_baru", { params });
  }

  createOrUpdate(data) {
    return Services.post("/seleksi_vendor", data);
  }

  delete(data) {
    return Services.post("/seleksi_vendor/delete", data);
  }

  getDropdown() {
    return Services.get("/vendor_master/dropdown")
  }

  dropdownJabatan() {
    return Services.get("/dropdown/jabatan")
  }

  dropdownPembuatPengajuan(params) {
    return Services.get("/dropdown/karyawan", { params })
  }

  dropdownVendor() {
    return Services.get("/dropdown/vendor")
  }

  dropdownSeleksiVendor() {
    return Services.get("/dropdown/item_barang")
  }

  dropdownSatuan() {
    return Services.get("/dropdown/satuan")
  }

  exportSV(params) {
    return Services.get("/seleksi_vendor/export", { params })
  }
}

export default new SeleksiVendorApi();
