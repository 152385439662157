import {
  useState,
  useEffect
} from 'react'
import {
  useParams
} from 'react-router-dom'
import {
  DataStatus
} from 'components'
import {
  HargaPerkiraanSendiriApi
} from 'api'
import {
  TableHargaBarang,
  SectionDetail
} from './Comps'

const HargaPerkiraanSendiriBeliDetail = () => {
  const { id } = useParams()
  const [dataDetail, setDataDetail] = useState({})
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true
  })

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      error: true
    })

    HargaPerkiraanSendiriApi.getDetailBeli({id_item_buaso: id})
      .then((res) => {
        setDataDetail(res?.data?.data)
        setFetchingStatus({
          loading: false,
          error: false
        })
      })
      .catch(() => {
        setFetchingStatus({
          loading: false,
          error: true
        })
      })
  }

  useEffect(() => {
    getInitialData()
  }, [])
  
  if (fetchingStatus.loading || fetchingStatus.error) {
    return (
      <div className="py-2">
        <DataStatus 
          loading={fetchingStatus.loading}
          text={fetchingStatus.loading ? 'Memuat . . .' : 'Data gagal dimuat'}
        />
      </div>
    )
  }

  return (
    <div className="p-2">
      {/* Detail */}
      <SectionDetail 
        type="Beli"
        action="VIEW"
        data={dataDetail}
      />

      {/* Tabel Harga Sebelumnya */}
      <div>
        <h6 className="px-2">
          <b>Harga Barang Sebelumnya</b>
        </h6>
        <TableHargaBarang 
          action='VIEW'
          data={dataDetail?.detail_hps_sebelumnya ?? []}
        />
      </div>

      {/* Table Harga Terbaru */}
      <h6 className="px-2">
        <b>Harga Barang Terbaru</b>
      </h6>
      <TableHargaBarang 
        action="VIEW"
        data={dataDetail?.detail_hps_terakhir ?? []}
      />
    </div>
  )
}

export default HargaPerkiraanSendiriBeliDetail