import React from 'react'
import {
  Row,
  Col,
} from 'react-bootstrap'
import {
  IoCaretUpOutline,
  IoCaretDownOutline
} from 'react-icons/io5'
import {
  useHistory
} from 'react-router-dom'
import {
  BackButton,
  InfoItemVertical,
} from 'components'
import { 
  DateConvert ,
  RupiahConvert
} from 'utilities'

export const SectionDetail = ({type = 'beli', action = 'VIEW',  data}) => {
  const history = useHistory()

  return (
    <>
      <div className="d-flex justify-content-between align-item-end">
        <b className="p-2">Update HPS {type === 'beli' ? 'Beli' : 'Pakai'}</b>
        <div>
          <BackButton 
            size="sm"
            onClick={() => history.push('/master/harga-perkiraan-sendiri')}
          />
        </div>
      </div>
      <div className="bg-white border rounded p-3 pt-3 mb-3">
        <Row>
          <Col>
            <InfoItemVertical 
              label="Kode Item"
              text={data?.kode_item ?? '-'}
            />
            <InfoItemVertical 
              label="Satuan Beli"
              text={data?.satuan_beli ?? '-'}
            />
          </Col>
          <Col>
            <InfoItemVertical 
              label="Nama Item"
              text={data?.nama_item ?? '-'}
            />
            <InfoItemVertical 
              label="Grup BUASO"
              text={data?.nama_buaso ?? '-'}
            />
          </Col>
          <Col>
            {action === 'VIEW'
              ? <InfoItemVertical 
                  label="Selisih Harga"
                  text={
                    <div>
                      <span>{data?.selisih && data?.selisih !== 0 ? data?.selisih >= 0 ? <IoCaretDownOutline size={18} className="text-success" /> : <IoCaretUpOutline size={18} className="text-danger" /> : ''}</span>
                      <span>{data?.selisih ? `${RupiahConvert(String(parseInt(data.selisih))).detail} ${data?.persen ? `(${parseFloat(data?.persen)}%)` : ''}` : '-'}</span>
                    </div>
                  }
                />
              : <InfoItemVertical 
                  label="Tgl. HPS Terakhir"
                  text={data?.tgl_hps_terakhir ? DateConvert(new Date(data?.tgl_hps_terakhir)).detail : '-'}
                />
            }
          </Col>
        </Row>
      </div>
    </>
  )
}
