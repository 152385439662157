import Services from "../../../services";

class PurchaseOrderApi {
  get(params) {
    return Services.get("/purchase_order/page", { params });
  }

  getSingle(params) {
    return Services.get("/purchase_order/single", { params });
  }

  getVendor(params) {
    return Services.get("/purchase_order/list_vendor", { params });
  }

  getSingleVendor(params) {
    return Services.get("/purchase_order/list_request_vendor", { params });
  }

  getNomor(params) {
    return Services.get("/purchase_order/no_baru", { params });
  }

  createOrUpdate(data) {
    return Services.post("/purchase_order", data);
  }

  delete(data) {
    return Services.post("/purchase_order/delete", data);
  }

  dropdownVendor() {
    return Services.get("/dropdown/vendor")
  }
  
  dropdownItemPO() {
    return Services.get("/dropdown/item_barang")
  }

  dropdownSeleksiVendor() {
    return Services.get("/dropdown/seleksi_vendor")
  }

  export(params) {
    return Services.get("/purchase_order/export", { params })
  }
}

export default new PurchaseOrderApi();
