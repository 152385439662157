import {
  useState
} from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  useFormikContext
} from 'formik'
import {
  DatePicker,
  Input,
  RadioButtonWrapper,
  RadioButton,
  InfoItemHorizontal
} from 'components'
import {
  PrakualifikasiVendorApi
} from 'api'
import {
  TableItem,
  TableKelengkapanDokumen,
  TableDokumenLainnya
} from '../Comps'
import { DateConvert } from 'utilities'


export const SectionPrakualifikasiVendor = ({
  action,
  jenisVendor = [],
  dataDetail = {},
  dataItemBahan = [],
  dataItemUpah = [],
  dataItemSubcon = [],
  dataDokumen = [],
  dataDokumenLainnya = [],
  setDataDokumen,
  setDataItemBahan,
  setDataDokumenLainnya,
}) => {
  const { values, errors, touched, setFieldValue, handleChange } = useFormikContext()
  const [loading, setLoading] = useState({
    nomor: false
  })

  const getNomor = (date) => {
    setLoading({
      ...loading,
      nomor: true
    })

    PrakualifikasiVendorApi.getNoBaru(date)
      .then((res) => setFieldValue('no_vendor_prakualifikasi', res?.data?.data))
      .catch(() => setFieldValue('no_vendor_prakualifikasi', ''))
      .finally(() => setLoading({...loading, nomor: false}))
  }

  return (
    <>
      {/* Tgl & No Prakualifikasi */}
      {action !== 'DETAIL' &&
        <Row>
          <Col md>
            <DatePicker 
              label="Tgl. Prakualifikasi"
              placeholderText="Pilih tgl. prakualifikasi"
              selected={values.tgl_vendor_prakualifikasi ? new Date(values.tgl_vendor_prakualifikasi) : ''}
              onChange={date => {
                const newDate = DateConvert(date).default
                setFieldValue('tgl_vendor_prakualifikasi', newDate)
                action === 'CREATE' && getNomor(newDate)
              }}
              error={errors.tgl_vendor_prakualifikasi && touched.tgl_vendor_prakualifikasi}
              errorText={errors.tgl_vendor_prakualifikasi && touched.tgl_vendor_prakualifikasi && errors.tgl_vendor_prakualifikasi}
            />
          </Col>
          <Col md>
            <Input 
              readOnly
              label="No. Prakualifikasi"
              placeholder="Pilih tgl. prakualifikasi untuk menentukan nomor"
              value={loading.nomor ? 'Memuat data . . .' : values.no_vendor_prakualifikasi}
              error={errors.no_vendor_prakualifikasi && touched.no_vendor_prakualifikasi}
              errorText={errors.no_vendor_prakualifikasi && touched.no_vendor_prakualifikasi && errors.no_vendor_prakualifikasi}
            />
          </Col>
        </Row>
      }
      
      {/* Radio Button Status Hukum */}
      {action !== 'DETAIL' &&
        <>
          <RadioButtonWrapper
            label="Status Hukum Vendor"
            error={errors.status_hukum && touched.status_hukum}
            errorText={errors.status_hukum && touched.status_hukum && errors.status_hukum}
          >
            <RadioButton 
              id="status_hukum"
              name="status_hukum"
              label="Berbadan Hukum"
              value={true}
              checked={values.status_hukum === true}
              onChange={() => setFieldValue('status_hukum', true)}
              error={errors.status_hukum && touched.status_hukum}
            />
            <RadioButton 
              id="tidak_status_hukum"
              name="status_hukum"
              label="Tidak Berbadan Hukum"
              value={false}
              checked={values.status_hukum === false}
              onChange={() => setFieldValue('status_hukum', false)}
              error={errors.status_hukum && touched.status_hukum}
            />
          </RadioButtonWrapper>
        </>
      }
      
      {/* Info Section */}
      <Row className="my-3">
        <Col md>
          {action === 'DETAIL' &&
             <InfoItemHorizontal 
              label="Tgl. Prakualifikasi"
              text={dataDetail?.tgl_vendor_prakualifikasi ? DateConvert(new Date(dataDetail.tgl_vendor_prakualifikasi)).detail : '-'}
              width={150}
              fontSize={15}
            />
          }
          <InfoItemHorizontal 
            label="Kode Vendor"
            text={dataDetail?.kode_vendor ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Nama Vendor"
            text={dataDetail?.nama_vendor ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Jenis Vendor"
            text={jenisVendor.map(val => val.jenis_vendor).join(', ')}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Pimpinan Perusahaan"
            text={dataDetail?.nama_pimpinan_perusahaan ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Contact Person"
            text={dataDetail?.contact_person ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Telepon"
            text={dataDetail?.telepon ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Email"
            text={dataDetail?.email ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Website"
            text={dataDetail?.website ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Media Sosial"
            text={dataDetail?.media_sosial ?? '-'}
            width={150}
            fontSize={15}
          />
        </Col>
        <Col md>
          {action === 'DETAIL' &&
             <InfoItemHorizontal 
              label="No. Prakualifikasi"
              text={dataDetail?.no_vendor_prakualifikasi ?? '-'}
              width={150}
              fontSize={15}
            />
          }
          <InfoItemHorizontal 
            label="Alamat Vendor"
            text={dataDetail?.alamat_vendor ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Desa"
            text={dataDetail?.nama_desa ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Kecamatan"
            text={dataDetail?.nama_kecamatan ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Kabupaten"
            text={dataDetail?.nama_kabupaten ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Provinsi"
            text={dataDetail?.nama_provinsi ?? '-'}
            width={150}
            fontSize={15}
          />
          <InfoItemHorizontal 
            label="Kode Pos"
            text={dataDetail?.kode_pos ?? '-'}
            width={150}
            fontSize={15}
          />
          {action === 'DETAIL' && 
            <>
              <InfoItemHorizontal 
                label="Status hukum"
                text={dataDetail?.status_hukum ? 'Berbadan Hukum' : 'Tidak Berbadan Hukum'}
                width={150}
                fontSize={15}
              />
              <InfoItemHorizontal 
                label="Jumlah Karyawan"
                text={dataDetail?.jumlah_karyawan ?? '-'}
                width={150}
                fontSize={15}
              />
            </>
          }
        </Col>
      </Row>

      {jenisVendor.map((val, index) => {
        if (val?.jenis_vendor?.toUpperCase() === 'MANDOR') return <TableItem key={index} action="VIEW" type="MANDOR" data={dataItemUpah} />
        if (val?.jenis_vendor?.toUpperCase() === 'SUBCON') return <TableItem key={index} action="VIEW" type="SUBCON" data={dataItemSubcon} />
      })}

      {/* Jumblah Karyawan */}
      <Row className="mt-2">
        <Col md={6}>
          {action !== 'DETAIL' &&
            <Input 
              type="number"
              label="Jumlah Karyawan"
              name="jumlah_karyawan"
              value={values.jumlah_karyawan}
              onChange={handleChange}
              error={errors.jumlah_karyawan && touched.jumlah_karyawan}
              errorText={errors.jumlah_karyawan && touched.jumlah_karyawan && errors.jumlah_karyawan}
            />
          }
        </Col>
      </Row>

      {/* Table List Bahan */}
      {jenisVendor.find(val => val?.jenis_vendor?.toUpperCase() === 'SUPPLIER') && <TableItem 
        action={action} 
        type="SUPPLIER" 
        data={dataItemBahan} 
        setData={setDataItemBahan}
      />}
      
      {/* Radio Button Status Hukum */}
      {action !== 'DETAIL' &&
        <>
          <RadioButtonWrapper
            orderRow
            label="Lama Vendor Menyediakan Item"
            error={errors.lama_menyediakan_item && touched.lama_menyediakan_item}
            errorText={errors.lama_menyediakan_item && touched.lama_menyediakan_item && errors.lama_menyediakan_item}
          >
            <RadioButton 
              id="lama_menyediakan_item_1"
              name="lama_menyediakan_item"
              value="1"
              label="Kurang Dari 1 (Satu) Tahun"
              checked={values?.lama_menyediakan_item === "1"}
              onChange={() => setFieldValue('lama_menyediakan_item', "1")}
              error={errors.lama_menyediakan_item && touched.lama_menyediakan_item}
            />
            <RadioButton 
              id="lama_menyediakan_item_2"
              name="lama_menyediakan_item"
              value="2"
              label="Antara 1 (Satu) Sampai (3) Tahun"
              checked={values?.lama_menyediakan_item === "2"}
              onChange={() => setFieldValue('lama_menyediakan_item', "2")}
              error={errors.lama_menyediakan_item && touched.lama_menyediakan_item}
            />
            <RadioButton 
              id="lama_menyediakan_item_3"
              name="lama_menyediakan_item"
              value="3"
              label="Diatas 3 (Tiga) Tahun"
              checked={values?.lama_menyediakan_item === "3"}
              onChange={() => setFieldValue('lama_menyediakan_item', "3")}
              error={errors.lama_menyediakan_item && touched.lama_menyediakan_item}
            />
          </RadioButtonWrapper>
        </>
      }

      {/* Table Kelengkapan Dokumen */}
      <TableKelengkapanDokumen 
        action={action}
        data={dataDokumen}
        setData={setDataDokumen}
      />

      <div className="mt-3" />

      {/* Table Dokumen Lainnya */}
      <TableDokumenLainnya 
        action={action}
        data={dataDokumenLainnya}
        setData={setDataDokumenLainnya}
      />
    </>
  )
}
