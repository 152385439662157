import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {
  Alert,
  ActionButton,
  BackButton,
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  Approval,
} from "../../../../components";
import { RupiahConvert, DateConvert } from "../../../../utilities";
import { PurchaseOrderApi } from "../../../../api";
import PrintPurchaseOrder from "./PrintPurchaseOrder";

const DetailPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation()
  const { id } = useParams();
  const componentRef = useRef();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState({});
  const [readData, setReadData] = useState([])
  const [dataPODetail, setDataPODetail] = useState([]);
  console.log("data po", dataPurchaseOrder)
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.getSingle({ id_purchase_order: id })
      .then(({ data }) => {
        const dataPO = data.data;
        const dataDetail = [];

        setDataPurchaseOrder(dataPO);

        dataPO.po_detail &&
          dataPO.po_detail.map((val) => {
            const processed = {
              id_item_buaso: val.id_item_buaso,
              qty_order: val.qty_order,
              harga_kesepakatan: val.harga_kesepakatan,
              kode_item: val.kode_item,
              nama_item: val.nama_item,
              id_seleksi_vendor_detail: val.id_seleksi_vendor_detail[0],
              dimensi: val.dimensi,
              pj: val.pj,
              tb: val.tb,
              lb: val.lb,
              nama_satuan: val.nama_satuan,
            };
            dataDetail.push(processed);
          });

        setDataPODetail(dataDetail);
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const PageContent = () => {
    const dataPengaju = dataPurchaseOrder?.stakeholder ? dataPurchaseOrder.stakeholder : [];

    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td>
          <div className="pl-4 pr-2">: </div>
        </td>
        <td>{value}</td>
      </tr>
    );

    const InfoItems = ({ title, value }) => (
      <tr>
        <td width={125}>{title}</td>
        <td className="pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    let total = 0;
    let nilaiDiskon = 0;
    let setelahDiskon = 0;
    let nilaiPPN = 0;
    let setelahPPN = 0;

    dataPODetail.map((val, index) => {
      total = total + val.harga_kesepakatan * val.qty_order;
      nilaiDiskon = dataPurchaseOrder.diskon
        ? parseInt(dataPurchaseOrder.diskon) === 0
          ? 0
          : parseInt((dataPurchaseOrder.diskon / 100) * total)
        : 0;
      setelahDiskon = parseInt(total - nilaiDiskon);
      nilaiPPN = dataPurchaseOrder.ppn
        ? parseInt(dataPurchaseOrder.ppn) === 0
          ? 0
          : parseInt((dataPurchaseOrder.ppn / 100) * setelahDiskon)
        : 0;
      setelahPPN = parseInt(setelahDiskon + nilaiPPN);
    });

    const CatatanSection = () => (
      <div>
        <div className="mt-3 mb-3">Pesanan ini selambat-lambatnya telah sampai pada :</div>
        <Row>
          <Col>
            <table>
              <tbody>
                <InfoItems
                  title="Tanggal"
                  value={
                    dataPurchaseOrder.tgl_pengiriman
                      ? DateConvert(new Date(dataPurchaseOrder.tgl_pengiriman)).detail
                      : "-"
                  }
                />
                <InfoItems title="Pembayaran" value={dataPurchaseOrder.pembayaran} />
                <InfoItems title="Dikirim ke" value={dataPurchaseOrder.alamat_tujuan_pengiriman} />
                <InfoItems title="Keterangan" value={dataPurchaseOrder.keterangan ?? "-"} />
              </tbody>
            </table>
          </Col>
          <Col>
            <div className="d-flex justify-content-end">
              <table>
                <tbody>
                  <InfoItems
                    title="Sub Total"
                    value={RupiahConvert(parseInt(total).toString()).detail}
                  />
                  <InfoItems
                    title={`Diskon ${dataPurchaseOrder.diskon} %`}
                    value={RupiahConvert(nilaiDiskon.toString()).detail}
                  />
                  <InfoItems
                    title="Pengenaan Pajak"
                    value={RupiahConvert(setelahDiskon.toString()).detail}
                  />
                  <InfoItems
                    title={`PPN ${dataPurchaseOrder.ppn} %`}
                    value={RupiahConvert(nilaiPPN.toString()).detail}
                  />
                  <InfoItems
                    title={<b>Total</b>}
                    value={RupiahConvert(setelahPPN.toString()).detail}
                  />
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    );

    // console.log(dataPurchaseOrder);

    const ListItemPurchaseOrderSection = () => {
      let total = 0;
      let nilaiDiskon = 0;
      let setelahDiskon = 0;
      let nilaiPPN = 0;
      let setelahPPN = 0;

      const TableFooterItems = ({ text, input, value }) => (
        <Tr>
          <Td colSpan={6} textRight>
            <b>{text}</b>
          </Td>
          <Td textRight colSpan={2}>
            <div className="d-flex align-items-center justify-content-between text-nowrap">
              {input ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div style={{ width: "70px" }}>{input}</div>
                  <div className="pb-1 ml-2">
                    <b>%</b>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="text-right">
                <b>{value}</b>
              </div>
            </div>
          </Td>
        </Tr>
      );

      return (
        <>
          <div className="py-2 pl-1">
            <span>Kami tempatkan order pembelian dengan sbb :</span>
          </div>
          {dataPODetail ? (
            dataPODetail.length > 0 ? (
              <>
                <Table>
                  <THead>
                    <Tr>
                      <ThFixed>No.</ThFixed>
                      <ThFixed>Kode</ThFixed>
                      <Th>Nama Barang/Jasa</Th>
                      <Th>Qty</Th>
                      <Th>Satuan</Th>
                      <Th>Harga Satuan</Th>
                      <Th>Jumlah Harga</Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {dataPODetail.map((val, index) => {
                      total = total + val.harga_kesepakatan * val.qty_order;
                      nilaiDiskon = dataPurchaseOrder.diskon
                        ? parseInt(dataPurchaseOrder.diskon) === 0
                          ? 0
                          : parseInt((dataPurchaseOrder.diskon / 100) * total)
                        : 0;
                      setelahDiskon = parseInt(total - nilaiDiskon);
                      nilaiPPN = dataPurchaseOrder.ppn
                        ? parseInt(dataPurchaseOrder.ppn) === 0
                          ? 0
                          : parseInt((dataPurchaseOrder.ppn / 100) * setelahDiskon)
                        : 0;
                      setelahPPN = parseInt(setelahDiskon + nilaiPPN);

                      return (
                        <Tr key={index}>
                          <TdFixed>{index + 1}</TdFixed>
                          <TdFixed>{val.kode_item}</TdFixed>
                          <Td>
                            <div style={{ width: "200px" }}>
                              {/* {val.dimensi === "3"
                                ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                                : val.dimensi === "2"
                                ? `${val.nama_item} (${val.pj} X ${val.lb})`
                                : val.dimensi === "1"
                                ? `${val.nama_item} (${val.pj})`
                                : val.dimensi === "0"
                                ? val.nama_item
                                : ""} */}
                              {val.nama_item}
                            </div>
                          </Td>
                          <Td textRight>
                            {val.qty_order ? parseFloat(val.qty_order).toPrecision() : "-"}
                          </Td>
                          <Td>{val.nama_satuan ? val.nama_satuan : "-"}</Td>
                          <Td textRight>
                            {RupiahConvert(val.harga_kesepakatan.toString()).detail}
                          </Td>
                          <Td textRight>
                            {
                              RupiahConvert(
                                parseInt(val.qty_order * val.harga_kesepakatan).toString()
                              ).detail
                            }
                          </Td>
                        </Tr>
                      );
                    })}
                  </TBody>
                </Table>
                <CatatanSection />
              </>
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </>
      );
    };

    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Purchase Order</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {dataPengaju.map(
                (val, index) =>
                  index !== 0 &&
                  val.status_approval !== "PEN" && (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === "VER" || val.status_approval === "REV"
                            ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                            : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                        }
                        value1={val.nama_karyawan ?? "-"}
                        title2="Catatan"
                        value2={val.catatan ?? "-"}
                      />
                    </Col>
                  )
              )}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    return (
      <div className="p-4">
        {/* <InfoSection /> */}
        <ListItemPurchaseOrderSection />
        <hr />
        {dataPengaju.length > 1 && <Approval data={dataPurchaseOrder} />}
      </div>
    );
  };

  const DetailDataPO = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td>
          <div className="pl-4 pr-2">: </div>
        </td>
        <td>{value}</td>
      </tr>
    )

    const jangkaWaktu = (data) => {
        const dateOne = new Date(data.tgl_jatuh_tempo)
        const dateTwo = new Date(data.tgl_purchase_order)

        const difference = dateOne.getTime() - dateTwo.getTime()
        const TotalDays = Math.ceil(difference / (1000 * 3600 * 24))

        return `${TotalDays} Hari`
    }

    return (
      <>
        <Row>
          <Col>
            <table>
              <tbody>
                <InfoItem
                  title="Tgl. Purchase Order"
                  value={dataPurchaseOrder.tgl_purchase_order ? DateConvert(new Date(dataPurchaseOrder.tgl_purchase_order)).detail : "-"}
                />
                <InfoItem
                  title="No. Purchase Order"
                  value={dataPurchaseOrder.no_purchase_order ? dataPurchaseOrder.no_purchase_order : "-"}
                />
                <InfoItem
                  title="Kode Vendor"
                  value={dataPurchaseOrder.kode_vendor ? dataPurchaseOrder.kode_vendor : "-"}
                />
                <InfoItem
                  title="Contact Person"
                  value={dataPurchaseOrder.contact_person ? dataPurchaseOrder.contact_person : "-"}
                />
                <InfoItem
                  title="Alamat Vendor"
                  value={dataPurchaseOrder.alamat_vendor ? dataPurchaseOrder.alamat_vendor : "-"}
                />
                <InfoItem
                  title="Tgl. Pengiriman"
                  value={dataPurchaseOrder.tgl_pengiriman ? DateConvert(new Date(dataPurchaseOrder.tgl_pengiriman)).detail : "-"}
                />
                {/* <InfoItem
                  title="Jangka Waktu Pembayaran"
                  value={dataPurchaseOrder.tgl_jatuh_tempo ? jangkaWaktu(dataPurchaseOrder) : "-"}
                /> */}
                <InfoItem
                  title="Jangka Waktu Pembayaran"
                  value={dataPurchaseOrder.pembayaran ? dataPurchaseOrder.pembayaran : "-"}
                />
                <InfoItem
                  title="Alamat Tujuan Pengiriman"
                  value={dataPurchaseOrder.alamat_tujuan_pengiriman ? dataPurchaseOrder.alamat_tujuan_pengiriman : "-"}
                />
                <InfoItem
                  title="Keterangan"
                  value={dataPurchaseOrder.keterangan ? dataPurchaseOrder.keterangan : "-"}
                />
                <InfoItem
                  title="Status Approval"
                  value={dataPurchaseOrder.status_approval === "APP" 
                    ? "APPROVE"
                    : dataPurchaseOrder.status_approval === "REV"
                    ? "REVISION"
                    : dataPurchaseOrder.status_approval === "VER"
                    ? "VERIFIED"
                    : dataPurchaseOrder.status_approval === "REJ"
                    ? "REJECTED"
                    : dataPurchaseOrder.status_approval === "PEN"
                    ? "PENDING"
                    : "-"}
                />
              </tbody>
            </table>
          </Col>
          {/* <Col>
            <table>
              <tbody>
                <InfoItem title="Kepada" />
                <span>{dataPurchaseOrder.nama_vendor ? dataPurchaseOrder.nama_vendor : "-"}</span>
                <br />
                <span>
                  {dataPurchaseOrder.alamat_vendor ? dataPurchaseOrder.alamat_vendor : "-"}
                </span>
              </tbody>
            </table>
          </Col> */}
        </Row>
      </>
    );
  };
  const TabelItemListSeleksiVendor = () => {
    return (
      <div className="mb-3">
        {dataPODetail ? dataPODetail.length > 0 ? 
          <>
            <Table>
              <THead>
                <Tr>
                  <ThFixed>No.</ThFixed>
                  <ThFixed>Kode Barang</ThFixed>
                  <Th>Item Seleksi Vendor</Th>
                  <Th width={150}>Qty. Seleksi Vendor</Th>
                  <Th width={150}>Satuan Beli</Th>
                  <ThFixed>Informasi Purchase Request</ThFixed>
                  <ThFixed>Informasi Seleksi Vendor</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {dataPurchaseOrder.sv_detail.map((val, index) => {
                  return (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.kode_item ?? "-"}</TdFixed>
                      <Td>{val.nama_item ?? "-"}</Td>
                      <Td textRight>{val.qty_order ? parseFloat(val.qty_order).toPrecision() : "-"}</Td>
                      <Td>{val.satuan_beli ?? "-"}</Td>
                      <TdFixed>
                        <div className="text-left">{val.tgl_purchase_request ? DateConvert(new Date(val.tgl_purchase_request)).defaultDMY : "-"}</div>
                        <div className="text-left">{val.no_purchase_request ?? "-"}</div>
                      </TdFixed>
                      <TdFixed>
                        <div className="text-left">{val.tgl_seleksi_vendor ? DateConvert(new Date(val.tgl_seleksi_vendor)).defaultDMY : "-"}</div>
                        <div className="text-left">{val.no_seleksi_vendor ?? "-"}</div>
                      </TdFixed>
                    </Tr>
                  );
                })}
              </TBody>
            </Table>
          </>
        : <DataStatus text="Tidak ada data" />
        : <DataStatus text="Tidak ada data" />
      }
      </div>
    )
  };
  const TabelItemListPurchaseOrder = () => {
    let total = 0;
    let nilaiDiskon = 0;
    let setelahDiskon = 0;
    let nilaiPPN = 0;
    let setelahPPN = 0;
    
    return (
      <div className="mb-4">
        {dataPODetail 
          ? dataPODetail.length > 0 ? 
            <>
              <Table>
                <THead>
                  <Tr>
                    <ThFixed>No.</ThFixed>
                    <ThFixed>Kode Barang</ThFixed>
                    <Th>Item Purchase Order</Th>
                    <Th width={100}>Qty. Seleksi Vendor</Th>
                    <Th width={140}>Satuan Beli</Th>
                    <Th width={150}>Harga Kesepakatan</Th>
                    <Th width={150}>Sub Total</Th>  
                  </Tr>
                </THead>
                <TBody>
                  {dataPurchaseOrder.po_detail.map((val, index) => {
                    total = total + val.harga_kesepakatan * val.qty_order;
                    nilaiDiskon = dataPurchaseOrder?.diskon
                      ? parseInt(dataPurchaseOrder?.diskon) === 0
                        ? 0
                        : parseInt((dataPurchaseOrder?.diskon / 100) * total)
                      : 0;
                    setelahDiskon = parseInt(total - nilaiDiskon);
                    nilaiPPN = dataPurchaseOrder?.ppn
                      ? parseInt(dataPurchaseOrder?.ppn) === 0
                        ? 0
                        : parseInt((dataPurchaseOrder?.ppn / 100) * setelahDiskon)
                      : 0;
                    setelahPPN = parseInt(setelahDiskon + nilaiPPN);

                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.kode_item ?? "-"}</TdFixed>
                        <Td>{val.nama_item ?? "-"}</Td>
                        <Td textRight>{val.qty_order ? parseFloat(val.qty_order).toPrecision() : "0"}</Td>
                        <Td>{val.nama_satuan ?? "0"}</Td>
                        <Td textRight>{val.harga_kesepakatan ? RupiahConvert(val.harga_kesepakatan.toString()).detail : "Rp.0"}</Td>
                        <Td textRight>{RupiahConvert(parseInt(val.qty_order * val.harga_kesepakatan).toString()).detail}</Td>
                      </Tr>
                    )
                  })}
                  <Tr>
                    <Td colSpan={6} className="text-right font-weight-bold">Total</Td>
                    <Td className="text-right font-weight-bold">{RupiahConvert(parseInt(total).toString()).detail}</Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={6} className="text-right  font-weight-bold">
                      <div className="d-flex justify-content-end align-items-baseline">
                        <div className="mr-3">Diskon (%)</div>
                        <div style={{ width: "50px" }}>
                          <input
                            type="number"
                            style={{paddingRight:"0px"}}
                            name="diskon"
                            className={`form-control form-control-sm text-right`} //${errors.diskon && touched.diskon && "border-danger"}
                            value={dataPurchaseOrder?.diskon}
                            // onChange={handleChange}
                            min={0}
                            max={100}
                            required
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </Td>
                    <Td className="text-right font-weight-bold">{RupiahConvert(nilaiDiskon.toString()).detail}</Td>
                  </Tr>
                  <Tr className="bg-light">
                    <Td colSpan={6} className="text-right font-weight-bold">Total Seteleah Dikurangi Diskon</Td>
                    <Td className="text-right font-weight-bold">{RupiahConvert(setelahDiskon.toString()).detail}</Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={6} className="text-right  font-weight-bold">
                      <div className="d-flex justify-content-end align-items-baseline">
                        <div className="mr-3">PPN (%)</div>
                        <div style={{ width: "50px" }}>
                          <input
                            type="number"
                            style={{paddingRight:"0px"}}
                            name="diskon"
                            className={`form-control form-control-sm text-right`} //${errors.diskon && touched.diskon && "border-danger"}
                            value={dataPurchaseOrder?.ppn}
                            // onChange={handleChange}
                            min={0}
                            max={100}
                            required
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </Td>
                    <Td className="text-right font-weight-bold">{RupiahConvert(nilaiPPN.toString()).detail}</Td>
                  </Tr>
                  <Tr className="bg-light">
                    <Td colSpan={6} className="text-right font-weight-bold">Total Setelah Ditambah PPN</Td>
                    <Td className="text-right font-weight-bold">{RupiahConvert(setelahPPN.toString()).detail}</Td>
                  </Tr>
                </TBody>
              </Table>
            </>
          : <DataStatus text="Tidak ada data" />
          : <DataStatus text="Tidak ada data" />
        }
      </div>
    )
  };
  const CatatanApprovalPurchaseOrder = () => {
    const InfoItem = ({ labelHeader, karyawanName, labelCatatan, catatanValue }) => (
      <>
          <small>{labelHeader}</small>
          <p><b>{karyawanName}</b></p>
          <small>{labelCatatan}</small>
          <p><b>{catatanValue}</b></p>
      </>
    );
    return (
      <>
        <div>
          {dataPurchaseOrder.stakeholder === undefined
          ? <div className="text-center"><small>Memuat Data...</small></div>
          : dataPurchaseOrder.stakeholder.map((val, index) => (
              <>
                  <div className="mr-5" key={index}>
                      <InfoItem
                          labelHeader={val.status_approval === "APP" 
                              ? "Pengesah" 
                              : index !== 0 
                              ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}` : ""
                          }
                          karyawanName={index !== 0 ? val.nama_karyawan ?? "-" : ""}
                          labelCatatan={val.status_approval === "APP" 
                              ? "Catatan Pengesah" 
                              : index !== 0 
                              ? `Catatan Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}` : ""
                          }
                          catatanValue={index !== 0 ? val.catatan : ""}
                      />
                  </div>
              </>
          ))}
        </div>
      </>
    )
  }

  useEffect(() => {
    setNavbarTitle("Detail Purchase Order");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <div>
      {isPageLoading 
        ? <DataStatus loading={true} text="Memuat data . . ." />
        : isFetchingFailed 
        ? <DataStatus text="Data gagal dimuat, tidak dapat mengubah data!" />
        : <div>
            <div className="d-flex align-items-flex-end">
              <div className="mt-auto"><b style={{fontSize:"15px"}}>Detail Data Purchase Order</b></div>
              <div className="d-flex align-items-center ml-auto mb-4">
                {!isPageLoading && (
                  <ReactToPrint
                    trigger={() => <ActionButton className="mb-1 mx-2" text="Cetak PO" />}
                    content={() => componentRef.current}
                  />
                )}
                <BackButton onClick={() => history.push("/transaksi/purchase-order", { ...location?.state })} />
              </div>
            </div>
            {/* Info Section */}
            <Card className="mb-5">
              <div className="p-2">
                <DetailDataPO />
              </div>
            </Card>

            {/* Table Seleksi Vendor */}
            <div className="mt-auto"><b style={{fontSize:"15px"}}>List Item Seleksi Vendor</b></div>
            <TabelItemListSeleksiVendor />

            {/* Table Purchase Order */}
            <div className="mt-auto"><b style={{fontSize:"15px"}}>List Item Puchase Order</b></div>
            <TabelItemListPurchaseOrder />

            {/* Catatan Approval */}
            <div className="mt-auto"><b style={{fontSize:"15px"}}>Catatan Approval Purchase Order</b></div>
            <Card className="mb-5">
              <Card.Body>
                <CatatanApprovalPurchaseOrder />
              </Card.Body>
            </Card>

            <div style={{ display: "none" }}>
              <PrintPurchaseOrder
                ref={componentRef}
                dataPurchaseOrder={dataPurchaseOrder}
                dataPODetail={dataPODetail}
              />
            </div>
          </div>
      }
    </div>
  );
};

export default DetailPurchaseOrder;

      {/* {/* <Card>
        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : isFetchingFailed ? (
          <DataStatus text="Data gagal dimuat, tidak dapat mengubah data!" />
        ) : ( */}

        {/* //   <> */}
        {/* <PageContent /> */}
        {/* //     <div style={{ display: "none" }}>
        //       <PrintPurchaseOrder */}

        {/* //         ref={componentRef}
        //         dataPurchaseOrder={dataPurchaseOrder}
        //         dataPODetail={dataPODetail}
        //       />
        //     </div> */}
        {/* //   </>
        // )} */}
      {/* // </Card> */}
