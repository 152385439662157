import React from 'react'
import {
  Modal
} from 'react-bootstrap'
import {
  useFormik
} from 'formik'
import * as Yup from 'yup'
import {
  Input,
  InfoItemHorizontal,
  ActionButton
} from 'components'
import { 
  RupiahConvert 
} from 'utilities'
import {
  HargaPerkiraanSendiriApi
} from 'api'

export const ModalHPSPakai = ({show, onHide, data, onSubmit}) => {
  const { values, errors, touched, isSubmitting, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      hps_pakai: '',
      buaso: 'B',
      id_item_hps: data?.id_item_buaso
    },
    validationSchema: Yup.object().shape({
      hps_pakai: Yup.string() 
        .required('Masukan HPS pakai')
        .test({
          name: 'check-hps-pakai',
          message: 'HPS pakai tidak boleh kosong',
          test: val => val > 0
        }), 
    }),
    onSubmit: onSubmit
  })

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <b>Update HPS Pakai</b>
      </Modal.Header>
      <Modal.Body>
        <InfoItemHorizontal 
          label="Kode Item"
          text={data?.kode_item ?? '-'}
        />
        <InfoItemHorizontal 
          label="Nama Item"
          text={data?.nama_item ?? '-'}
        />
        <InfoItemHorizontal 
          label="Nama BUASO"
          text={data?.nama_buaso ?? '-'}
        />
        <InfoItemHorizontal 
          label="Satuan Pakai"
          text={data?.satuan_pakai ?? '-'}
        />
        <InfoItemHorizontal 
          label="Rata-rata Harga Terbaru"
          text={data?.avg_hps_beli_terakhir ? RupiahConvert(String(parseInt(data.avg_hps_beli_terakhir))).detail : '-'}
        />
        <hr />
        <Input
          label="HPS Pakai" 
          value={RupiahConvert(String(parseInt(values.hps_pakai || 0))).detail}
          onChange={e => {
            const value = RupiahConvert(String(e?.target?.value)).default || 0
            setFieldValue('hps_pakai', parseInt(value))
          }}
          error={errors.hps_pakai && touched.hps_pakai}
          errorText={errors.hps_pakai && touched.hps_pakai && errors.hps_pakai}
        />
      </Modal.Body>
      <Modal.Footer>
        <ActionButton 
          variant="warning"
          className="text-white"
          text="Update"
          onClick={handleSubmit}
          loading={isSubmitting}
        />
      </Modal.Footer>
    </Modal>
  )
}