import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, ButtonGroup, Table as BSTable } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  FilterButton,
} from "../../../../components";
import { TableNumber, DateConvert, RupiahConvert } from "../../../../utilities";
import { PurchaseOrderApi } from "../../../../api";
import { IoAddOutline } from "react-icons/io5";
import { ModalFilterSV, seleksiVendor } from "../Section"

const VendorTerseleksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataVendorTerseleksi, setDataVendorTerseleksi] = useState([]);
  console.log("test", dataVendorTerseleksi)
  const [modalFilter, setModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.sv?.filter?.active,
      tgl_sv: location?.state?.sv?.filter?.tgl_sv,
      // tgl_seleksi_vendor_selesai: location?.state?.sv?.filter?.tgl_seleksi_vendor_selesai,
      id_vendor: location?.state?.sv?.filter?.id_vendor,
      id_item_sv: location?.state?.sv?.filter?.id_item_sv,
    },
    pagination: {
      page: location?.state?.sv?.filter?.page ?? "1",
      dataLength: location?.state?.sv?.filter?.dataLength ?? "10",
      totalPage: location?.state?.sv?.filter?.totalPage ?? "1",
      dataCount: location?.state?.sv?.filter?.dataCount ?? "0",
    }
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    setAlertConfig({
      ...alertConfig,
      show: false,
    });

    PurchaseOrderApi.getVendor({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_sv: dataFilter?.filter?.tgl_sv,
      // tgl_seleksi_vendor_selesai: dataFilter?.filter?.tgl_seleksi_vendor_selesai,
      id_vendor: dataFilter?.filter?.id_vendor,
      id_item_sv: dataFilter?.filter?.id_item_sv,
    })
      .then(data => {
        setDataVendorTerseleksi(data.data.data);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle("Purchase Order");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle, 
    searchConfig.key, 
    dataFilter?.pagination?.page, 
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_sv,
    // dataFilter?.filter?.tgl_seleksi_vendor_selesai,
    dataFilter?.filter?.id_vendor,
    dataFilter?.filter?.id_item_sv,
  ]);

  // EXPORT DATA
  // const getExportData = (tipe) => {
  //   setLoadingExport(true)

  //   KartuStockMaterialApi.getExport({
  //     q: searchConfig.key,
  //     page: dataFilter?.pagination?.page,
  //     per_page: dataFilter?.pagination?.dataLength,
  //     tgl_sv: dataFilter?.filter?.tgl_sv,
  //     id_vendor: dataFilter?.filter?.id_vendor,
  //     id_item_sv: dataFilter?.filter?.id_item_sv,
  //     tipe
  //   })
  //   .then(val => {
  //       // const urlFile = process.env.REACT_APP_FILE_BASE_URL + val.data.file;
  //       // FileSaver.saveAs(urlFile, val.data.file);
  //       const link = val?.data?.data
  //       const parse = 'https://' + link.split('/').filter((_val, idx) => idx !== 0).filter((_val, idx) => idx !== 0).join('/')
  //       saveAs(parse, "PurchaseRequest")
  //   }).catch(() => {
  //       setAlertConfig({
  //         show: true,
  //         variant: "danger",
  //         text: "Export Data gagal",
  //       })
  //   }).finally(() => setLoadingExport(false))
  // }

  const ItemCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);

    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: "13px" }}>
          {data.map((brg, index) => {
            if (isReadMoreClick) {
              return <li index={index}>{brg.nama_item}</li>;
            } else {
              return index <= 1 && <li index={index}>{brg.nama_item}</li>;
            }
          })}
        </ul>
        {data.length < 3 ? (
          ""
        ) : (
          <div
            className="text-primary"
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              // fontWeight: "bold",
            }}
            onClick={() => {
              setisReadMoreClick((prev) => !prev);
            }}
          >
            {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
          </div>
        )}
      </>
    );
  };

  const PageContent = () => {
    const TableSV = ({ children }) => {
      return (
        <BSTable bordered responsive className="bg-white overflow-hidden border-1 shadow-sm">
          {children}
        </BSTable>
      );
    };
    const TabelTd = ({children, colSpan, rowSpan, textRight, className, ...props}) => {
      return (
        <td 
          {...props}
          colSpan={colSpan}
          rowSpan={rowSpan}
          className={`p-1 px-2 ${textRight && 'text-right'} ${className}`}
          style={{
            ...props.style,
            fontSize: '14px'
          }}
        >
          {children}
        </td>
      )
    }
    const TabelTdFixed = ({children, colSpan, rowSpan, className, textRight, ...props}) => {
      return (
        <td 
          {...props}
          colSpan={colSpan}
          rowSpan={rowSpan}
          className={`p-1 px-2 text-center ${textRight && 'text-right'} ${className}`}
          style={{
            ...props.style,
            fontSize: '14px'
          }}
        >
          {children}
        </td>
      )
    }
    const DataTable = () => (
      <>
        <div className="mt-3"><span><b style={{fontSize:"14px"}}>List Seleksi Vendor Yang Siap Dibuatkan Purchase Order</b></span></div>
        <TableSV>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <Th>Vendor</Th>
              <ThFixed>Informasi Seleksi Vendor</ThFixed>
              <Th>Item Seleksi Vendor</Th>
              <Th>Qty. Seleksi vendor</Th>
              <ThFixed>Harga Kesepakatan</ThFixed>
              <Th>Total Harga</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataVendorTerseleksi.map((val, index) => {
              const rowSpan = val?.seleksi_vendor?.length ?? 1

              return val?.seleksi_vendor?.map((data, index_data) => {
                const total = parseInt(data.harga_kesepakatan) * parseInt(data.qty_order)

                return (
                  <Tr key={index}>
                    {index_data === 0 &&
                      <>
                        <TabelTdFixed rowSpan={rowSpan}>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TabelTdFixed>
                        <TabelTd rowSpan={rowSpan}>{data.nama_vendor ?? "-"}</TabelTd>
                      </>
                    }
                    <TdFixed>
                      <div className="text-left">{data.tgl_seleksi_vendor ? DateConvert(new Date(data.tgl_seleksi_vendor)).defaultDMY : "-"}</div>
                      <div className="text-left">{data.no_seleksi_vendor ?? "-"}</div>
                    </TdFixed>
                    <Td>{data.nama_item ?? "-"}</Td>
                    <Td textRight>{data.qty_order ? parseFloat(data.qty_order).toPrecision() : "-"}</Td>
                    <Td className="text-right">{data.harga_kesepakatan ? RupiahConvert(data.harga_kesepakatan).detail : "-"}</Td>
                    <Td className="text-right">{total ? RupiahConvert(total.toString()).detail : "Rp.0"}</Td>
                    {index_data === 0 &&
                      <TabelTd rowSpan={rowSpan}>
                        <ButtonGroup size="sm">
                          <ActionButton
                            size="sm"
                            className="mx-1 text-nowrap"
                            text={<IoAddOutline />}
                            onClick={() =>
                              history.push("/transaksi/purchase-order/tambah/" + val.id_vendor, {...location?.state, sv: dataFilter})
                            }
                          />
                        </ButtonGroup>
                      </TabelTd>
                    }
                  </Tr>
                )
              })
            })}
          </TBody>
        </TableSV>
      </>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
          }
          dataPage={
            dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              }
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              }
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  const key = e.target.value;
                  setSearchConfig({
                    ...searchConfig,
                    key: e.target.value,
                  });
                  setAlertConfig({
                    show: key ? true : false,
                    variant: "primary",
                    text: "Hasil dari pencarian: " + key,
                  });
                }}
              />
            </Col>
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/transaksi/purchase-order", {...location?.state, sv: dataFilter})} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataVendorTerseleksi ? (
        dataVendorTerseleksi.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}

      {/* Modal Filter  */}
      {modalFilter && (
        <ModalFilterSV
          modalFilter={modalFilter}
          setModalFilter={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default VendorTerseleksi;
