export const dataKelengkapanDokumen =  (data = []) => [
  {
    id: 1,
    label: 'Copy NPWP',
    dokumen: {
      have: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '1')?.ada_atau_tidak ? 'ada' : 'tidak',
      name: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '1')?.file_path ?? undefined,
      link: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '1')?.full_file_path ?? undefined,
      file_path: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '1')?.file_path ?? undefined,
      file: undefined
    }
  },
  {
    id: 2,
    label: 'Copy KTP',
    dokumen: {
      have: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '2')?.ada_atau_tidak ? 'ada' : 'tidak',
      name: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '2')?.file_path ?? undefined,
      link: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '2')?.full_file_path ?? undefined,
      file_path: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '2')?.file_path ?? undefined,
      file: undefined
    }
  },
  {
    id: 3,
    label: 'Copy SIUP',
    dokumen: {
      have: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '3')?.ada_atau_tidak ? 'ada' : 'tidak',
      name: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '3')?.file_path ?? undefined,
      link: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '3')?.full_file_path ?? undefined,
      file_path: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '3')?.file_path ?? undefined,
      file: undefined
    }
  },
  {
    id: 4,
    label: 'Copy Tanda Daftar Perusahaan',
    dokumen: {
      have: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '4')?.ada_atau_tidak ? 'ada' : 'tidak',
      name: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '4')?.file_path ?? undefined,
      link: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '4')?.full_file_path ?? undefined,
      file_path: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '4')?.file_path ?? undefined,
      file: undefined
    }
  },
  {
    id: 5,
    label: 'Kemampuan Modal Usaha',
    dokumen: {
      have: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '5')?.ada_atau_tidak ? 'ada' : 'tidak',
      name: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '5')?.file_path ?? undefined,
      link: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '5')?.full_file_path ?? undefined,
      file_path: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '5')?.file_path ?? undefined,
      file: undefined
    }
  },
  {
    id: 6,
    label: 'Referensi Bank',
    dokumen: {
      have: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '6')?.ada_atau_tidak ? 'ada' : 'tidak',
      name: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '6')?.file_path ?? undefined,
      link: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '6')?.full_file_path ?? undefined,
      file_path: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '6')?.file_path ?? undefined,
      file: undefined
    }
  },
  {
    id: 7,
    label: 'Sertifikat Produk/Sertifikat Sistem Mutu',
    dokumen: {
      have: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '7')?.ada_atau_tidak ? 'ada' : 'tidak',
      name: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '7')?.file_path ?? undefined,
      link: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '7')?.full_file_path ?? undefined,
      file_path: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '7')?.file_path ?? undefined,
      file: undefined
    }
  },
  {
    id: 8,
    label: 'Surat Penunjukan Keagenan',
    dokumen: {
      have: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '8')?.ada_atau_tidak ? 'ada' : 'tidak',
      name: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '8')?.file_path ?? undefined,
      link: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '8')?.full_file_path ?? undefined,
      file_path: data?.find(val => val.id_vendor_prakualifikasi_dokumen === '8')?.file_path ?? undefined,
      file: undefined
    }
  }
]