import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  CreateButton,
  ReadButton,
  UpdateButton,
  ActionButton,
  FilterButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ApprovalStatusButton
} from "../../../../components";
import { TableNumber, DateConvert, RupiahConvert } from "../../../../utilities";
import { PurchaseOrderApi } from "../../../../api";
import { Row, Col, DropdownButton, Dropdown, Button, Modal, Table as BSTable } from "react-bootstrap";
import { ModalFilterPO } from "../Section"
import { Spinner } from "../Comps"
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import { saveAs } from 'file-saver'

const PurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false)
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState([]);
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [showModalExport, setShowModalExport] = useState({
    show: false,
    type: "",
    status: ""
  })
  const [modalFilter, setModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.po?.filter?.active,
      tgl_purchase_order_mulai: location?.state?.po?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai: location?.state?.po?.filter?.tgl_purchase_order_selesai,
      tgl_sv: location?.state?.po?.filter?.tgl_sv,
      // tgl_seleksi_vendor_selesai: location?.state?.po?.filter?.tgl_seleksi_vendor_selesai,
      id_vendor: location?.state?.po?.filter?.id_vendor,
      id_item_po: location?.state?.po?.filter?.id_item_po,
      status_approval: location?.state?.po?.filter?.status_approval,
    },
    pagination: {
      page: location?.state?.po?.filter?.page ?? "1",
      dataLength: location?.state?.po?.filter?.dataLength ?? "10",
      totalPage: location?.state?.po?.filter?.totalPage ?? "1",
      dataCount: location?.state?.po?.filter?.dataCount ?? "0",
    }
  })

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_purchase_order_mulai: dataFilter?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai: dataFilter?.filter?.tgl_purchase_order_selesai,
      tgl_sv: dataFilter?.filter?.tgl_sv,
      // tgl_seleksi_vendor_selesai: dataFilter?.filter?.tgl_seleksi_vendor_selesai,
      id_vendor: dataFilter?.filter?.id_vendor,
      id_item_po: dataFilter?.filter?.id_item_po,
      status_approval: dataFilter?.filter?.status_approval,
    })
      .then(data => {
        setDataPurchaseOrder(data.data.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle("Purchase Order");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle, 
    searchConfig.key, 
    dataFilter?.pagination?.page, 
    dataFilter?.pagination?.dataLength, 
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_purchase_order_mulai,
    dataFilter?.filter?.tgl_purchase_order_selesai,
    dataFilter?.filter?.tgl_sv,
    // dataFilter?.filter?.tgl_seleksi_vendor_selesai,
    dataFilter?.filter?.id_vendor,
    dataFilter?.filter?.id_item_po,
    dataFilter?.filter?.status_approval,
  ]);

  // EXPORT DATA
  const getExportData = (tipe) => {
    setLoadingExport(true)

    PurchaseOrderApi.export({
      tgl_purchase_order_mulai: dataFilter?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai: dataFilter?.filter?.tgl_purchase_order_selesai,
      tgl_sv: dataFilter?.filter?.tgl_sv,
      id_vendor: dataFilter?.filter?.id_vendor,
      id_item_po: dataFilter?.filter?.id_item_po,
      status_approval: dataFilter?.filter?.status_approval,
      tipe
    })
    .then(val => {
        // const urlFile = process.env.REACT_APP_FILE_BASE_URL + val.data.file;
        // FileSaver.saveAs(urlFile, val.data.file);
        const link = val?.data?.data
        const parse = 'https://' + link.split('/').filter((_val, idx) => idx !== 0).filter((_val, idx) => idx !== 0).join('/')
        saveAs(parse, "PurchaseRequest")
    }).catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        })
    }).finally(() => setLoadingExport(false))
  }

  // MODAL EXPORT DATA
  const ModalStatusExport = () => {
    return (
      <Modal
        show={showModalExport.show}
        onHide={() => setShowModalExport({ ...showModalExport, show: false })}
        centered
        backdrop={showModalExport.type === "loading" ? "static" : true}>
          
        {showModalExport.type === "loading" 
          ?  <Modal.Header><Modal.Title><span style={{fontSize:"15px"}}><b>Export Data Purchase Order</b></span></Modal.Title></Modal.Header>
          :  <Modal.Header closeButton><Modal.Title><span style={{fontSize:"15px"}}><b>Export Data Purchase Order</b></span></Modal.Title></Modal.Header>
        }
        {showModalExport.type === "loading" 
        ? (<Modal.Body>
            <div className="text-center">
              <Spinner />
              <p className='mt-4' style={{fontSize:'15px'}}><b>Proses Export Sedang Berjalan, Mohon Ditunggu</b></p>
            </div>
          </Modal.Body>)
        : showModalExport.type === "berhasil"  
        ? (<Modal.Body>
            <div className="text-center">
              <AiFillCheckCircle style={{fontSize:"80px", color:"#14C38E"}} />
              <p className='mt-4' style={{fontSize:'15px'}}><b>Export Berhasil</b></p>
              <Button 
                style={{marginTop:"-10px", paddingLeft:"20px", paddingRight:"20px"}} 
                onClick={() => setShowModalExport({ ...showModalExport, show: false })}>
                OK
              </Button>
            </div>
          </Modal.Body>)
        : showModalExport.type === "gagal" && (<Modal.Body>
            <div className="text-center">
              <AiFillCloseCircle style={{fontSize:"80px", color:"#EB1D36"}} />
              <p className='mt-4' style={{fontSize:'15px'}}><b>Export Gagal</b></p>
              <Button 
                style={{marginTop:"-10px", paddingLeft:"20px", paddingRight:"20px"}} 
                onClick={() => setShowModalExport({ ...showModalExport, show: false })}>
                OK
              </Button>
            </div>
          </Modal.Body>)
        }
      </Modal>
    )
  }

  const PageContent = () => {
    const generateStatusPR = (value) => {
      if (value.toUpperCase() === "OPEN") {
        return <b className="text-success">OPEN</b>;
      }

      return <b className="text-danger">CLOSE</b>;
    };
    const ItemBarangCollapse = ({ data }) => {
      return (
        data.map(brg => (
          <Tr>
            <Td width={400}>{`-${brg.nama_item} ${brg.qty ? brg.qty : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</Td>
          </Tr>
        ))
      )
    }
    const SeleksiVendorCollapse = ({ data }) => {
      return (
        data.map(sv => (
          <div>
            <TdFixed>
              <div className="text-left">{sv.tgl_seleksi_vendor ? DateConvert(new Date(sv.tgl_seleksi_vendor)).defaultDMY : "Belum"}</div>
              <div className="text-left">{sv.no_seleksi_vendor ?? "2022/09/PRO/PO/000005"}</div>
            </TdFixed>
          </div>
        ))
      )
    }
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : "";
      const convert = data.substring(0, 3);

      if (convert === "APP")
        return {
          variant: "outline-approved",
          label: "APPROVED",
        };
      if (convert === "VER")
        return {
          variant: "outline-green-light",
          label: "VERIFIED",
        };
      if (convert === "REV")
        return {
          variant: "outline-revised",
          label: "REVISION",
        };
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECTED",
        };
      return {
        variant: "outline-pending",
        label: "PENDING",
      };
    };

    const DataTable = () => {
      const TablePO = ({ children }) => {
        return (
          <BSTable bordered responsive className="bg-white overflow-hidden border-1 shadow-sm">
            {children}
          </BSTable>
        );
      };
      const TabelTd = ({children, colSpan, rowSpan, textRight, className, ...props}) => {
        return (
          <td 
            {...props}
            colSpan={colSpan}
            rowSpan={rowSpan}
            className={`p-1 px-2 ${textRight && 'text-right'} ${className}`}
            style={{
              ...props.style,
              fontSize: '14px'
            }}
          >
            {children}
          </td>
        )
      }
      const TabelTdFixed = ({children, colSpan, rowSpan, className, textRight, ...props}) => {
        return (
          <td 
            {...props}
            colSpan={colSpan}
            rowSpan={rowSpan}
            className={`p-1 px-2 text-center ${textRight && 'text-right'} ${className}`}
            style={{
              ...props.style,
              fontSize: '14px'
            }}
          >
            {children}
          </td>
        )
      }
      return (
        <>
          <span><b style={{fontSize:"14px"}}>List Data Purchase Order</b></span>
          <TablePO>
            <THead>
              <Tr>
                <ThFixed>No</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <ThFixed>Informasi Purchase Order</ThFixed>
                <ThFixed>Informasi Seleksi Vendor</ThFixed>
                <Th width={400}>Item Purchase Order</Th>
                <Th width={150}>Grand Total</Th>
                <Th width={200}>Vendor</Th>
                <ThFixed>Status Approval</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {
                dataPurchaseOrder.map((val, index) => {
                  const rowSpan = val.item_barang.length ?? 1

                  return val.item_barang.map((item, index_item) => {
                    return (
                      <Tr key={index}>
                        {
                          index_item === 0 &&
                          <>
                            <TabelTdFixed rowSpan={rowSpan}>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TabelTdFixed>
                            <TabelTdFixed rowSpan={rowSpan}>
                              <div className="d-flex justify-content-start">
                                <ReadButton
                                  onClick={() =>
                                    history.push("/transaksi/purchase-order/detail/" + val.id_purchase_order, {...location?.state, po: dataFilter})
                                  }
                                />
                                {(val.status_approval === "REV") && (
                                  <UpdateButton
                                    onClick={() =>
                                      history.push("/transaksi/purchase-order/ubah/" + val.id_purchase_order, {...location?.state, po: dataFilter})
                                    }
                                  />
                                )}
                              </div>
                            </TabelTdFixed>
                            <TabelTdFixed rowSpan={rowSpan}>
                              <div className="text-left">{val.tgl_purchase_order ? DateConvert(new Date(val.tgl_purchase_order)).defaultDMY : "-"}</div>
                              <div className="text-left">{val.no_purchase_order ?? "-"}</div>
                            </TabelTdFixed>
                          </>
                        }
                        <TdFixed>
                          <div className="text-left">{item.tgl_seleksi_vendor ? DateConvert(new Date(item.tgl_seleksi_vendor)).defaultDMY : "Belum"}</div>
                          <div className="text-left">{item.no_seleksi_vendor ?? "2022/09/PRO/PO/000005"}</div>
                        </TdFixed>
                        <TabelTd>{`-${item.nama_item} ${item.qty_order ? parseFloat(item.qty_order).toPrecision() : "0"} ${item.nama_satuan ? item.nama_satuan : ""}`?? "-"}</TabelTd>
                        {
                          index_item === 0 &&
                          <>
                            <TabelTd rowSpan={rowSpan} textRight><div className="pt-0">{val.grand_total ? RupiahConvert(val.grand_total).detail : "-"}</div></TabelTd>
                            <TabelTd rowSpan={rowSpan}>{val.nama_vendor ?? "-"}</TabelTd>
                            <TabelTdFixed rowSpan={rowSpan}>
                              <ApprovalStatusButton variant={checkStatus(val.status_approval).variant}>
                                {checkStatus(val.status_approval).label}
                              </ApprovalStatusButton>
                            </TabelTdFixed>
                          </>
                        }
                      </Tr>
                    )
                  })
                })
              }
            </TBody>
          </TablePO>
        </>
      )
    }

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
          }
          dataPage={
            dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              }
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              }
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex">
            <InputSearch
              onChange={(e) => {
                const key = e.target.value;
                setSearchConfig({
                  ...searchConfig,
                  key: e.target.value,
                });
                setAlertConfig({
                  show: key ? true : false,
                  variant: "primary",
                  text: "Hasil dari pencarian: " + key,
                });
              }}
            />
            <FilterButton 
              className="ml-4"
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
            <DropdownButton 
              className='ml-3'
              variant='warning text-white'
              title={<span className="text-white mr-3">EXPORT</span>}>

              <Dropdown.Item 
                onClick={() => getExportData('excel')}
              >Excel (.xlsx)</Dropdown.Item>
              <Dropdown.Item 
                onClick={() => getExportData('pdf')}
              >PDF (.pdf)</Dropdown.Item>
            </DropdownButton>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => history.push("/transaksi/purchase-order/vendor-terseleksi", {...location?.state, po: dataFilter})}
            // onClick={() => history.push("/transaksi/purchase-order/tambah")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <div className="mt-2">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
      </div>

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPurchaseOrder ? (
        dataPurchaseOrder.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}

      {/* Modal Filter  */}
      {modalFilter && (
        <ModalFilterPO
          modalFilter={modalFilter}
          setModalFilter={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
      <ModalStatusExport />
    </CRUDLayout>
  );
};

export default PurchaseOrder;
