import {
  useState,
  useEffect
} from 'react'
import {
  useHistory,
  useParams
} from 'react-router-dom'
import {
  Formik
} from 'formik'
import { 
  ActionButton, 
  BackButton, 
  DataStatus
} from 'components'
import {
  PrakualifikasiVendorApi,
  VendorApi,
} from 'api'
import {
  SectionPrakualifikasiVendor
} from './Comps'
import {
  dataKelengkapanDokumen as dataKelengkapan,
  formInitialValues,
  formValidationSchema
} from './Utils'

const PrakualifikasiVendorCreate = ({setNavbarTitle}) => {
  const history = useHistory()
  const { id } = useParams()
  const [dataDetail, setDataDetail] = useState({})
  const [dataItemBahan, setDataItemBahan] = useState([])
  const [dataItemSubcon, setDataItemSubcon] = useState([])
  const [dataItemUpah, setDataItemUpah] = useState([])
  const [dataKelengkapanDokumen, setDataKelengkapanDokumen] = useState([])
  const [dataDokumenLainnya, setDataDokumenLainnya] = useState([])
  const [fetching, setFetching] = useState({
    loading: true,
    error: true
  })

  const getInitialData = () => VendorApi.single(id)
    .then((res) => {
      const getDataDetail = res?.data?.data ?? {}

      setDataDetail(getDataDetail)
      setDataItemBahan(getDataDetail?.vendor_item_bahan ? getDataDetail.vendor_item_bahan.map(val => ({
        id_item_bahan: val.id_item_bahan,
        kode_item: val.kode_item,
        nama_item: val.nama_item,
        satuan_pakai: val.satuan_pakai,
        jumlah: 0
      })) : [])
      setDataItemSubcon(getDataDetail?.vendor_item_subcon)
      setDataItemUpah(getDataDetail?.vendor_item_upah)
      setFetching({
        loading: false,
        error: false
      })
    })
    .catch(() => {
      setFetching({
        loading: false,
        error: true
      })
    })

  const createPrakualifikasiVendor = async(values) => {
    const newDataDokumenUtama = async () => await Promise.all(dataKelengkapanDokumen.map(async val => {
      if (val?.dokumen?.file) {
        const fileData = new FormData()
        fileData.append("file", val.dokumen.file)

        return await PrakualifikasiVendorApi.upload(fileData)
          .then(res => ({
            id_vendor_prakualifikasi_dokumen: val?.id,
            ada_atau_tidak: !!(val?.dokumen?.have === 'ada'),
            file_path: res?.data?.data ?? ''
          }))
          .catch(() => ({
            id_vendor_prakualifikasi_dokumen: val?.id,
            ada_atau_tidak: !!(val?.dokumen?.have === 'ada'),
            file_path: ""
          }))
      }

      return {
        id_vendor_prakualifikasi_dokumen: val?.id,
        ada_atau_tidak: !!(val?.dokumen?.have === 'ada'),
        file_path: ""
      }
    }))

    const newDataDokumenLainnya = async () => await Promise.all(dataDokumenLainnya.map(async val => {
      if (val?.dokumen?.file) {
        const fileData = new FormData()
        fileData.append("file", val.dokumen.file)

        return await PrakualifikasiVendorApi.upload(fileData)
          .then(res => ({
            nama_dokumen: val?.uraian,
            file_path: res?.data?.data ?? ''
          }))
          .catch(() => ({
            nama_dokumen: val?.uraian,
            file_path: ''
          }))
      }

      return {
        nama_dokumen: val?.uraian,
        file_path: ''
      }
    })) 
    
    const finalValues = {
      ...values,
      jumlah_item: dataItemBahan.map(val => ({
        id_item_buaso: val.id_item_bahan,
        jumlah_item: val.jumlah
      })),
      dokumen_utama: await newDataDokumenUtama(),
      dokumen_lainnya: await newDataDokumenLainnya(),
    }

    return PrakualifikasiVendorApi.create(finalValues)
      .then(() => history.push('/master/prakualifikasi-vendor', {alert: {show: true, variant: 'primary', text: 'Tambah data berhasil!'}}))
      .catch(() => history.push('/master/prakualifikasi-vendor', {alert: {show: true, variant: 'danger', text: 'Tambah data gagal!'}}))
  }

  useEffect(() => {
    setNavbarTitle('Tambah Prakualifikasi Vendor')
    setDataKelengkapanDokumen(dataKelengkapan())
    getInitialData()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between align-items-end mb-2">
        <b className="px-2" style={{fontSize: 17}}>Data Prakualifikasi Vendor</b>
        <BackButton size="sm" onClick={history.goBack} />
      </div>
      <div className="bg-white border rounded p-3">
        {fetching.loading || fetching.error
          ? <DataStatus 
              loading={fetching.loading}
              text={fetching.loading ? 'Memuat data . . .' : 'Data gagal dimuat'}
            />
          : <Formik
              initialValues={formInitialValues({id_vendor: id})}
              validationSchema={formValidationSchema}
              onSubmit={createPrakualifikasiVendor}
            >
              {({isSubmitting, handleSubmit}) => (
                <>
                  <SectionPrakualifikasiVendor 
                    action="CREATE"
                    jenisVendor={dataDetail?.jenis_vendor ?? []}
                    dataDetail={dataDetail}
                    dataItemBahan={dataItemBahan}
                    dataItemUpah={dataItemUpah}
                    dataItemSubcon={dataItemSubcon}
                    dataDokumen={dataKelengkapanDokumen}
                    dataDokumenLainnya={dataDokumenLainnya}
                    setDataItemBahan={setDataItemBahan}
                    setDataDokumen={setDataKelengkapanDokumen}
                    setDataDokumenLainnya={setDataDokumenLainnya}
                  />
                  <div className="text-right">
                    <ActionButton 
                      text="Simpan"
                      className="px-4 my-3"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                    />
                  </div>
                </>
              )}
            </Formik>
        }
      </div>
    </>
  )
}

export default PrakualifikasiVendorCreate