import React, {
  useState,
  useEffect
} from 'react'
import {
  Tabs,
  Tab
} from 'react-bootstrap'
import { 
  useHistory,
  useParams
} from 'react-router-dom'
import {
  debounce
} from 'lodash'
import {
  CRUDLayout,
  InputSearch,
  Alert,
  Select
} from 'components'
import {
  HargaPerkiraanSendiriApi
} from 'api'
import {
  HPSList,
  ModalHPSPakai
} from './Comps'

const HargaPerkiraanSendiriList = ({setNavbarTitle}) => {
  const history = useHistory()
  const params = useParams()
  const [tab, setTab] = useState("beli")
  const [dataBeli, setDataBeli] = useState([])
  const [dataPakai, setDataPakai] = useState([])
  const [search, setSearch] = useState(undefined)
  const buaso = [
    {
      label: "Semua Data",
      value: "",
    },
    {
      label: "Bahan",
      value: "Bahan",
    },
    {
      label: "Upah",
      value: "Upah",
    },
    {
      label: "Alat dan mesin",
      value: "Alat dan mesin",
    },
    {
      label: "Subkon",
      value: "Subcon",
    },
    {
      label: "Overhead",
      value: "Overhead",
    },
  ]
  const [filter, setFilter] = useState({
    label: "Semua Data",
    value: "",
  })
  console.log(filter)
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true
  })
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })
  const [modal, setModal] = useState({
    show: false,
    data: {}
  })
  const [paginationBeli, setPaginationBeli] = useState({
    page: 1,
    dataLength: 10,
    totalPage: 1,
    dataCount: 0,
  })
  const [paginationPakai, setPaginationPakai] = useState({
    page: 1,
    dataLength: 10,
    totalPage: 1,
    dataCount: 0,
  })

  const getDataBeli = () => {
    setDataBeli([])
    setFetchingStatus({
      loading: true,
      error: true
    })
    const param = {
      page: paginationBeli.page,
      per_page: paginationBeli.dataLength,
      q: search
    };
    if (filter.value !== "") {
      param.nama_buaso = filter.value;
    }

    HargaPerkiraanSendiriApi.getPageBeli(param)
      .then((res) => {
        setDataBeli(res?.data?.data ?? [])
        setPaginationBeli({
          ...paginationBeli,
          dataCount: res?.data?.data_count,
          totalPage: res?.data?.total_page,
        })
        setFetchingStatus({
          loading: false,
          error: false
        })
      })
      .catch(() => {
        setDataBeli([])
        setFetchingStatus({
          loading: false,
          error: true
        })
      })
  }

  const getDataPakai = () => {
    setDataBeli([])
    setFetchingStatus({
      loading: true,
      error: true
    })
    const param = {
      page: paginationPakai.page,
      per_page: paginationPakai.dataLength,
      q: search
    };
    if (filter.value !== "") {
      param.nama_buaso = filter.value;
    }

    HargaPerkiraanSendiriApi.getPagePakai(param)
      .then((res) => {
        setDataPakai(res?.data?.data ?? [])
        setPaginationPakai({
          ...paginationPakai,
          dataCount: res?.data?.data_count,
          totalPage: res?.data?.total_page,
        })
        setFetchingStatus({
          loading: false,
          error: false
        })
      })
      .catch(() => {
        setDataPakai([])
        setFetchingStatus({
          loading: false,
          error: true
        })
      })
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)
  }

  const saveHPSPakai = (values) => HargaPerkiraanSendiriApi.savePakai(values)
    .then(() => {
      setAlert({
        show: true, 
        variant: 'primary', 
        text: 'Ubah HPS pakai berhasil!'
      })
      getDataPakai()
    })
    .catch(() => {
      setAlert({
        show: true, 
        variant: 'danger', 
        text: 'Ubah HPS pakai gagal!'
      })
    })
    .finally(() => {
      setModal({show: false, data: {}})
    })

  useEffect(() => {
    setNavbarTitle('Harga Perkiraan Sendiri')
    setAlert({
      show: !!history?.location?.state?.alert?.show,
      variant: history?.location?.state?.alert?.variant,
      text: history?.location?.state?.alert?.text,
    })
  }, [])
  
  useEffect(() => {
    if (tab === 'beli') {
      getDataBeli()
    }
  }, [tab, search, paginationBeli.page, paginationBeli.dataLength, filter])

  useEffect(() => {
    if (tab === 'pakai') {
      getDataPakai()
    }
  }, [tab, search,  paginationPakai.page, paginationPakai.dataLength, filter])
  

  return (
    <CRUDLayout>

      {/* Head */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch 
              onChange={debounce(searchHandler, 800)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <Select
            onChange={(e) =>
              setFilter({
                label: "",
                value: e.target.value,
              })
            }
          >
            <option value="" hidden>
              Pilih Buaso
            </option>
            {buaso.map((val) => (
              <option value={val.value}>{val.label}</option>
            ))}
          </Select>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert 
        show={alert.show}
        variant={alert.variant}
        text={alert.text}
      />

      {/* Content */}
      <Tabs 
        activeKey={tab}
        onSelect={e => setTab(e)}
      >
        <Tab 
          eventKey="beli" 
          title="HPS Beli"
        >
          <div className="p-3 bg-white border border-top-0 rounded">
            <HPSList 
              data={dataBeli}
              type="beli"
              onReadButtonClick={(val) => history.push('/master/harga-perkiraan-sendiri/beli/detail/' + val?.id_item_buaso)}
              onUpdateButtonClick={(val) => history.push('/master/harga-perkiraan-sendiri/beli/ubah/' + val?.id_item_buaso)}
              fetchingStatus={fetchingStatus}
              paginationConfig={paginationBeli}
              setPaginationConfig={setPaginationBeli}
            />
          </div>
        </Tab>
        <Tab 
          eventKey="pakai" 
          title="HPS Pakai"
        >
          <div className="p-3 bg-white border border-top-0 rounded">
            <HPSList 
              data={dataPakai}
              type="pakai"
              onUpdateButtonClick={(val) => setModal({show: true, data: val})}
              paginationConfig={paginationPakai}
              fetchingStatus={fetchingStatus}
              setPaginationConfig={setPaginationPakai}
            />
          </div>
        </Tab>
      </Tabs>
      <ModalHPSPakai 
        show={modal.show}
        data={modal.data}
        onHide={() => setModal({show: false, data: {}})}
        onSubmit={saveHPSPakai}
      />
    </CRUDLayout>
  )
}

export default HargaPerkiraanSendiriList