import {
  useState,
  useEffect
} from 'react'
import { 
  Modal,
  Form
} from 'react-bootstrap'
import { 
  ActionButton 
} from 'components'
import { IoEyeOutline, IoTrashOutline } from 'react-icons/io5'

export const ModalDokumen = ({action, show, onHide, index, value = {}, data = [], setData}) => {
  const [file, setFile] = useState({
    have: undefined,
    file: undefined,
    name: undefined,
    link: undefined
  })

  const onInputChange = e => {
    const file = e.target.files[0];

    // Check apakah ada file yang dipilih
    if (!file) return null;

    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const getFileType = file.name.split(".").pop();

    // Check jika type file sudah benar
    if (["pdf"].includes(getFileType)) {
      const generateName = file.name
      const generateLink = URL.createObjectURL(file)
      setFile({ have: 'ada', file: file, name: generateName, link: generateLink })
    } else {
      window.alert("File tidak valid! harap masukan .pdf");
    }    
  }

  const formSubmitHandler = () => {
    let newData = [...data]
    newData.splice(index, 1, {...data[index], dokumen: file})

    setData(newData)
    onHide()
  }
  useEffect(() => {
    setFile({
      have: value?.dokumen?.have ?? undefined,
      file: value?.dokumen?.file ?? undefined,
      name: value?.dokumen?.name ?? undefined,
      link: value?.dokumen?.link ?? undefined
    })
  }, [show])

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>  
        <b>
          {action === 'DETAIL' ? 'Dokumen' : 'Upload Dokumen'} Prakualifikasi Vendor
        </b>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {action === 'DETAIL'
            ? <>  
                {file?.name 
                    ? <>
                        <div className='text-truncate'>
                          <b>{file?.link}</b>
                        </div>
                        <ActionButton 
                          size="sm"
                          className="text-nowrap mt-2"
                          text={
                            <a href={file.link} target='_blank' className="d-flex align-items-center text-white">
                              <IoEyeOutline />
                              <div className="ml-1">Lihat Dokumen</div> 
                            </a>
                          }
                        />
                      </>
                    : <div className="text-center py-3">
                        <b>Tidak ada file</b>
                      </div>
                }
              </>
            : <>
                <Form.File 
                  custom
                  size="sm"
                  label={file?.name ? file.name : 'Pilih dokumen'}
                  accept="application/pdf"
                  onChange={onInputChange}
                />
                {file?.name 
                    ? <>
                        <ActionButton 
                          size="sm"
                          className="text-nowrap mt-2"
                          text={
                            <a href={file.link} target='_blank' className="d-flex align-items-center text-white">
                              <IoEyeOutline />
                              <div className="ml-1">Lihat Dokumen</div> 
                            </a>
                          }
                        />
                        <ActionButton 
                          size="sm"
                          variant="danger"
                          className="mt-2 ml-1"
                          text={<IoTrashOutline />}
                          onClick={() => setFile({
                            have: 'tidak',
                            file: undefined,
                            link: undefined,
                            name: undefined
                          })}
                        />
                      </>
                    : <small className="p-1 text-secondary">Tidak ada file</small>
                }
              </>
          }
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ActionButton 
          variant="outline-secondary"
          text="TUTUP"
          onClick={onHide}
        />
        {
          action !== 'DETAIL'
            ? <ActionButton 
                variant="primary"
                text="Simpan"
                onClick={formSubmitHandler}
              />
            : <></>
        }
      </Modal.Footer>
    </Modal>
  )
}
