import Services from "services";

class HargaPerkiraanSendiriApi {
  getPageBeli(params) {
    return Services.get('/harga_perkiraan_sendiri_beli/page', {params});
  }

  getPagePakai(params) {
    return Services.get('/harga_perkiraan_sendiri_pakai/page', {params});
  }

  getSingleBeli(params) {
    return Services.get('/harga_perkiraan_sendiri_beli/single', {params});
  }

  getDetailBeli(params) {
    return Services.get('/harga_perkiraan_sendiri_beli/detail', {params});
  }
  
  getVendor() {
    return Services.get('/vendor_master/dropdown');
  }

  saveBeli(data) {
    return Services.post('/harga_perkiraan_sendiri_beli', data);
  }

  savePakai(data) {
    return Services.post('/harga_perkiraan_sendiri_pakai', data);
  }
}

export default new HargaPerkiraanSendiriApi();
