import React, {
  useState,
  useEffect
} from 'react'
import {
  ButtonGroup
} from 'react-bootstrap'
import { 
  IoAddOutline, 
  IoCheckmarkOutline, 
  IoCloseOutline, 
  IoPencilOutline, 
  IoTrashBinOutline
} from 'react-icons/io5'
import {
  useFormik
} from 'formik'
import * as Yup from 'yup'
import {
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ActionButton,
  DataStatus,
  SelectSearch,
  Input,
  DeleteModal
} from 'components'
import { 
  DateConvert,
  RupiahConvert 
} from 'utilities'
import {
  HargaPerkiraanSendiriApi
} from 'api'

const TableRow = ({index, action, value, data, setData, dataVendor, loading, type, onDeleteButtonClick}) => {
  const [isEdited, setIsEdited] = useState(false)
 
  const { values, errors, touched, setFieldValue, setValues, handleSubmit } = useFormik({
    initialValues: {
      harga: value?.harga ?? 0, 
      id_vendor: value?.id_vendor ?? '', 
      nama_vendor: value?.nama_vendor ?? ''
    },
    validationSchema: Yup.object().shape({
      harga: Yup.string().required(), 
      id_vendor: Yup.string().required()
    }),
    onSubmit: (value) => {
      if (action === 'CREATE') {
        setData([...data, value])
      }

      if (action === 'UPDATE') {
        const newData = [...data]
        newData.splice(index,1, value)
        setData(newData)
        setIsEdited(false)
      }
    }
  })

  if (isEdited || action === 'CREATE') {
    return (
      <Tr>
        <TdFixed>{action !== 'CREATE' && index + 1}</TdFixed>
        <Td>
          <SelectSearch 
            noMargin
            placeholder="Pilih vendor"
            menuShouldBlockScroll={true}
            menuPosition="fixed"
            loading={loading.vendor}
            option={dataVendor}
            defaultValue={values.id_vendor ? dataVendor.find(val => val.value === values.id_vendor) : ''}
            onChange={val => {
              setValues({
                ...values,
                id_vendor: val.value,
                nama_vendor: val.label
              })
            }}
            error={errors.id_vendor && touched.id_vendor}
          />
        </Td>
        <Td textRight>
          <Input 
            noMargin
            value={RupiahConvert(String(parseInt(values.harga || 0))).detail}
            onChange={e => {
              const value = RupiahConvert(String(e?.target?.value)).default || 0
              setFieldValue('harga', value)
            }}
            error={errors.harga && touched.harga}
          />
        </Td>
        <Td style={{display: action === 'VIEW' ? 'none' : ''}} className="text-center">
          <ButtonGroup size="sm">
            {action === 'CREATE' &&
              <ActionButton 
                text={<IoAddOutline />}
                onClick={handleSubmit}
              />
            }
            {action !== 'CREATE' && isEdited &&
              <>
                <ActionButton
                  variant="outline-success"
                  text={<IoCheckmarkOutline />}
                  onClick={handleSubmit}
                />
                <ActionButton 
                  variant="outline-danger"
                  text={<IoCloseOutline />}
                  onClick={() => setIsEdited(false)}
                />
              </>
            }
          </ButtonGroup>
        </Td>
      </Tr>
    )
  }
  
  return (
    <Tr>
      <TdFixed>{index + 1}</TdFixed>
      <Td>{value?.nama_vendor ?? '-'}</Td>
      <Td textRight>
        {
          value?.harga
            ? RupiahConvert(String(value?.harga)).detail
            : type === 'beli'
              ? value?.hps_beli ? RupiahConvert(String(parseInt(value.hps_beli))).detail : '-'
              : value?.hps_pakai ? RupiahConvert(String(parseInt(value.hps_beli))).detail : '-'
        }
      </Td>
      <Td style={{display: action === 'VIEW' ? 'none' : ''}}>
        <ButtonGroup size="sm">
          <ActionButton
            variant="success"
            text={<IoPencilOutline />}
            onClick={() => setIsEdited(true)}
          />
          <ActionButton 
            variant="danger"
            text={<IoTrashBinOutline />}
            onClick={onDeleteButtonClick}
          />
        </ButtonGroup>
      </Td>
    </Tr>
  )
}

export const TableHargaBarang = ({action, data = [], setData, type = 'beli', perTanggal}) => {
  const [dataVendor, setDataVendor] = useState([])
  const [loading, setLoading] = useState({vendor: true})
  const [modal, setModal] = useState({
    show: false,
    index: '',
    data: {}
  })

  const getDataVendor = () => {
    setLoading({vendor: true})

    HargaPerkiraanSendiriApi.getVendor()
      .then((res) => {
        setDataVendor(res?.data?.data ? res.data.data.map(val => ({value: val.id_vendor, label: val.nama_vendor})) : [])
      })
      .catch(() => {
        setDataVendor([])
      })
      .finally(() => {
        setLoading({vendor: false})
      })
  }

  const getHargaRataRata = () => {
    const total = data.reduce((prev, curr) => {
      const harga = curr?.harga ? curr.harga
        : type === 'beli'
          ? curr?.hps_beli ?? 0
          : curr?.hps_pakai ?? 0
          
        return prev + parseFloat(harga)

    }, 0)
    const length = data?.length ? data.length : 1
    return parseInt(total / length)
  }

  useEffect(() => {
    getDataVendor()
  }, [])

  return (
    <div className="mb-3 px-3 pt-3 bg-white rounded border">
      {perTanggal
        ? <div className="px-2 pb-2">
            <small><b>Per Tanggal {DateConvert(new Date(perTanggal)).defaultDMY}</b></small>
          </div>
        : ''
      }
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th style={{minWidth: 300,}} className="p-0">Vendor</Th>
            <Th style={{width: 150,}} className="p-0">Harga Item</Th>
            <ThFixed style={{display: action === 'VIEW' ? 'none' : ''}}>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {action === 'UPDATE' &&
            <TableRow 
              action="CREATE" 
              dataVendor={dataVendor}
              loading={loading}
              data={data}
              setData={setData}
            />
          }
          {data && data?.length > 0
            ? data.map((val, index) => (
              <TableRow 
                key={index}
                index={index}
                type={type}
                action={action}
                value={val}
                data={data}
                setData={setData}
                dataVendor={dataVendor}
                loading={loading}
                onDeleteButtonClick={() => setModal({show: true, data: val, index})}
              />
            ))
            : <Tr>
                <Td colSpan={4}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
          }
          <Tr>
            <Td colSpan={2} textRight>
              <b>Harga Rata-rata</b>
            </Td>
            <Td textRight>
              <b>{RupiahConvert(String(getHargaRataRata())).detail}</b>
            </Td>
            {action !== 'VIEW' &&
              <Td></Td>
            }
          </Tr>
        </TBody>
      </Table>
      <DeleteModal 
        show={modal.show}
        onHide={() => setModal({show: false, data: {}})}
        onConfirm={() => {
          setData([...data].filter((_, i) => i !== modal.index))
          setModal({show: false, index: '', data: {}})
        }}
      >
        <span className="d-block">Hapus data dengan nama vendor: </span>
        <b>{modal?.data?.nama_vendor}</b>
      </DeleteModal>
    </div>
  )
}