import {
  useState
} from 'react'
import { 
  IoTrashBinOutline 
} from 'react-icons/io5'
import {
  useFormik
} from 'formik'
import * as Yup from 'yup'
import {
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  TdFixed,
  ThFixed,
  Td,
  Th,
  ActionButton,
  Input,
  DeleteModal,
} from 'components'
import {
  ModalDokumen
} from '../Comps'

export const TableDokumenLainnya = ({data = [], setData, action}) => {
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: { uraian: '' },
    validationSchema: Yup.object().shape({ uraian: Yup.string().required() }),
    onSubmit: (values, {resetForm}) => {
      const newData = [...data, values]
      const checkUraian = data.find(val => val?.uraian?.toLowerCase() === values?.uraian?.toLowerCase())

      if (checkUraian) {
        resetForm()
      } else { 
        setData(newData)
        resetForm()
      }
    }
  })
  const [modal, setModal] = useState({
    type: '',
    show: false,
    index: null,
    data: {}
  })

  const closeModalHandler = () => setModal({type: '', show: false,index: null, data: {}})
  
  return (
    <>
      {action !== 'DETAIL' &&
        <form onSubmit={handleSubmit} className="d-flex align-items-end mb-3">
          <div style={{width: '100%'}}>
            <Input 
              noMargin
              name="uraian"
              label="Dokumen Lainnya (sebutkan)"
              value={values.uraian}
              onChange={handleChange}
              error={errors.uraian}
            />
          </div>
          <ActionButton 
            type="submit"
            text="Tambah"
            className="px-4 ml-2 d-flex align-items-center"
            style={{height: 33}}
            onClick={handleSubmit}
          />
        </form>
      }
      {action === 'DETAIL' && <div className="p-2"><b>Dokumen Lainnya</b></div>}
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th style={{minWidth: 300}} className="p-2">Uraian</Th>
            <Th style={{width: 150}} className="p-2">{action === 'DETAIL' ? 'Dokumen' : 'Upload Dokumen'}</Th>
            {action !== 'DETAIL' &&
              <Th style={{width: 50}}>Aksi</Th>
            }
          </Tr>
        </THead>
        <TBody>
          {data && data?.length > 0
            ? data.map((e, i) => (
                <Tr key={i}>
                  <TdFixed>{i + 1}</TdFixed>
                  <Td>{e?.uraian ?? '-'}</Td>
                  <Td>
                    <ActionButton
                      size="sm"
                      text="Cek Dokumen"
                      className="col"
                      onClick={() => setModal({type: 'upload', show: true, index: i, data: e})}
                    />
                  </Td>
                  {action !== 'DETAIL' &&
                    <Td>
                      <ActionButton
                        size="sm" 
                        variant="danger"
                        text={<IoTrashBinOutline />}
                        onClick={() => setModal({type: 'delete', show: true, index: i, data: e})}
                      />
                    </Td>
                  }
                </Tr>
              ))
            : <Tr>
                <Td colSpan={11}>
                  <DataStatus 
                    text="Tidak ada data"
                  />
                </Td>
              </Tr>
          }
        </TBody>
      </Table>

      <ModalDokumen 
        show={modal.show && modal.type === 'upload'}
        onHide={closeModalHandler}
        value={modal.data}
        index={modal.index}
        data={data}
        setData={setData}
        action={action}
      />

      <DeleteModal
        show={modal.show && modal.type === 'delete'}
        onHide={closeModalHandler}
        onConfirm={() => {
          setData(data.filter((_, i) => modal.index !== i))
          closeModalHandler()
        }}
      >
        Data dengan uraian: <b>{modal?.data?.uraian ?? ''}</b>
      </DeleteModal>
    </>
  )
}