import React, {
  useState,
  useEffect
} from 'react'
import { 
  useHistory,
} from 'react-router-dom'
import {
  debounce
} from 'lodash'
import {
  CRUDLayout,
  InputSearch,
  Alert,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  DataStatus,
  Pagination,
  ActionButton,
  BackButton
} from 'components'
import {
  DateConvert,
  TableNumber
} from 'utilities'
import {
  PrakualifikasiVendorApi
} from 'api'
import { AiOutlineEye, AiOutlinePlus } from "react-icons/ai"

const PrakualifikasiVendorCalonVendorList = ({setNavbarTitle}) => {
  const history = useHistory()
  const [data, setData] = useState([])
  const [search, setSearch] = useState(undefined)
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true
  })
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })
  const [pagination, setPagination] = useState({
    page: 1,
    dataLength: 10,
    totalPage: 1,
    dataCount: 0,
  })

  const getData = () => {
    setData([])
    setFetchingStatus({
      loading: true,
      error: true
    })

    PrakualifikasiVendorApi.getPageCalonVendor({
      page: pagination.page,
      per_page: pagination.dataLength,
      q: search
    })
      .then((res) => {
        setData(res?.data?.data ?? [])
        setPagination({
          ...pagination,
          dataCount: res?.data?.data_count,
          totalPage: res?.data?.total_page,
        })
        setFetchingStatus({
          loading: false,
          error: false
        })
      })
      .catch(() => {
        setData([])
        setFetchingStatus({
          loading: false,
          error: true
        })
      })
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    setNavbarTitle('Calon Vendor')
    setAlert({
      show: !!history?.location?.state?.alert?.show,
      variant: history?.location?.state?.alert?.variant,
      text: history?.location?.state?.alert?.text,
    })
  }, [])

  useEffect(() => {
    getData()
  }, [search,  pagination.page, pagination.dataLength])
  

  return (
    <CRUDLayout>

      {/* Head */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch 
              onChange={debounce(searchHandler, 800)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push('/master/prakualifikasi-vendor')} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert 
        show={alert.show}
        variant={alert.variant}
        text={alert.text}
      />

      {/* Content */}
      {fetchingStatus.loading || fetchingStatus.error
        ? <DataStatus 
            loading={fetchingStatus.loading}
            text={fetchingStatus.loading ? 'Memuat . . .' : 'Data gagal dimuat'}
          />
        : <>
            <Table>
              <THead>
                <Tr>
                  <ThFixed>No.</ThFixed>
                  <ThFixed>Kode Vendor</ThFixed>
                  <Th style={{minWidth: 300,}} className="p-2">Nama Vendor</Th>
                  <Th style={{minWidth: 150,}} className="p-2">Jenis vendor</Th>
                  <Th style={{minWidth: 150,}} className="p-2">Contact Person</Th>
                  <Th style={{minWidth: 100,}} className="p-2">Telepon</Th>
                  <ThFixed>Aksi</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {data && data?.length > 1
                  ? data.map((e, i) => (
                      <Tr key={i}>
                        <TdFixed>{TableNumber(pagination.page, pagination.dataLength, i)}</TdFixed>
                        <TdFixed>{e?.kode_vendor ?? '-'}</TdFixed>
                        <Td>{e?.nama_vendor ?? '-'}</Td>
                        <Td>{e?.jenis_vendor ?? '-'}</Td>
                        <Td>{e?.contact_person ?? '-'}</Td>
                        <Td>{e?.telepon ?? '-'}</Td>
                        <TdFixed>
                          <div className="d-flex">
                            <ActionButton 
                              size="sm"
                              // variant="info"
                              // text="Profil Vendor"
                              text={<AiOutlineEye style={{fontSize:"15px"}} className="text-dark" />}
                              className="m-1"
                              onClick={() => history.push("/master/vendor/detail/"+ e.id_vendor)} 
                            />
                            <ActionButton 
                              // text="Prakualifikasi"
                              variant="success"
                              text={<AiOutlinePlus />}
                              size="sm"
                              className="m-1"
                              onClick={() => history.push("/master/prakualifikasi-vendor/tambah/"+ e.id_vendor)} 
                            />
                          </div>
                        </TdFixed>
                      </Tr>
                    ))
                  : <Tr>
                      <Td colSpan={11}>
                        <DataStatus 
                          text="Tidak ada data"
                        />
                      </Td>
                    </Tr>
                }
              </TBody>
            </Table>
            <Pagination
              dataLength={pagination?.dataLength}
              dataNumber={pagination?.page * pagination?.dataLength - pagination?.dataLength + 1}
              dataPage={
                pagination?.dataCount < pagination?.dataLength
                  ? pagination?.dataCount
                  : pagination?.page * pagination?.dataLength
              }
              dataCount={pagination?.dataCount}
              currentPage={pagination?.page}
              totalPage={pagination?.totalPage}
              onPaginationChange={({ selected }) =>
                setPagination({
                  ...pagination,
                  page: selected + 1,
                })
              }
              onDataLengthChange={(e) =>
                setPagination({
                  ...pagination,
                  page: 1,
                  dataLength: e.target.value,
                })
              }
            />
          </>
      }
      
    </CRUDLayout>
  )
}

export default PrakualifikasiVendorCalonVendorList