import React from 'react'
import {
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  TdFixed,
  ThFixed,
  Td,
  Th,
  Input
} from 'components'

export const TableItem = ({type, action, data = [], setData}) => {
  if (type === 'MANDOR') {
    return (
      <>
        <div className="p-2">
          <b>List Item Upah Yang Disediakan Vendor Ini Sebagai Mandor</b>
        </div>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <Th style={{minWidth: 100}} className="p-2">Kode Item Upah</Th>
              <Th style={{minWidth: 300,}} className="p-2">Nama Item Upah</Th>
              <Th style={{minWidth: 100,}} className="p-2">Satuan Pakai</Th>
            </Tr>
          </THead>
          <TBody>
            {data && data?.length > 0
              ? data.map((e, i) => (
                  <Tr key={i}>
                    <TdFixed>{i + 1}</TdFixed>
                    <Td>{e?.kode_item ?? '-'}</Td>
                    <Td>{e?.nama_item ?? '-'}</Td>
                    <Td>{e?.nama_satuan ?? '-'}</Td>
                  </Tr>
                ))
              : <Tr>
                  <Td colSpan={4}>
                    <DataStatus 
                      text="Tidak ada data"
                    />
                  </Td>
                </Tr>
            }
          </TBody>
        </Table>
      </>
    )
  }

  if (type === 'SUBCON') {
    return (
      <>
        <div className="p-2">
          <b>List Item Subcon Yang Akan Disediakan</b>
        </div>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <Th style={{minWidth: 100}} className="p-2">Kode Item Subcon</Th>
              <Th style={{minWidth: 300,}} className="p-2">Nama Item Subcon</Th>
              <Th style={{minWidth: 100,}} className="p-2">Kelompok Subcon</Th>
              <Th style={{minWidth: 100,}} className="p-2">Satuan Pakai</Th>
            </Tr>
          </THead>
          <TBody>
            {data && data?.length > 0
              ? data.map((e, i) => (
                  <Tr key={i}>
                    <TdFixed>{i + 1}</TdFixed>
                    <Td>{e?.kode_item ?? '-'}</Td>
                    <Td>{e?.nama_item ?? '-'}</Td>
                    <Td>{e?.nama_kelompok ?? '-'}</Td>
                    <Td>{e?.nama_satuan ?? '-'}</Td>
                  </Tr>
                ))
              : <Tr>
                  <Td colSpan={11}>
                    <DataStatus 
                      text="Tidak ada data"
                    />
                  </Td>
                </Tr>
            }
          </TBody>
        </Table>
      </>
    )
  }
  
  return (
    <>
      <div className="p-2">
        <b>List Item Bahan Yang Disediakan Vendor Ini Sebagai Supplier</b>
      </div>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th style={{minWidth: 100}} className="p-2">Kode Item Bahan</Th>
            <Th style={{minWidth: 300}} className="p-2">Nama Item Bahan</Th>
            <Th style={{minWidth: 100}} className="p-2">Satuan</Th>
            <Th style={{minWidth: 100}} className="p-2">Jumlah</Th>
          </Tr>
        </THead>
        <TBody>
          {data && data?.length > 0
            ? data.map((e, i) => (
                <Tr key={i}>
                  <TdFixed>{i + 1}</TdFixed>
                  <Td>{e?.kode_item ?? '-'}</Td>
                  <Td>{e?.nama_item ?? '-'}</Td>
                  <Td>{e?.satuan_pakai ?? '-'}</Td>
                  <Td textRight>
                    {action === 'DETAIL'
                      ? e?.jumlah ?? '-'
                      : <Input 
                          noMargin
                          type="number"
                          value={e.jumlah}
                          onChange={e => {
                            const newData = [...data]
                            newData.splice(i, 1, {...newData[i], jumlah: e?.target?.value || 0})
                            setData(newData)
                          }}
                        />
                    }
                  </Td>
                </Tr>
              ))
            : <Tr>
                <Td colSpan={11}>
                  <DataStatus 
                    text="Tidak ada data"
                  />
                </Td>
              </Tr>
          }
        </TBody>
      </Table>
    </>
  )
}
