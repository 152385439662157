import {
  useState,
  useEffect
} from 'react'
import {
  useHistory,
  useParams
} from 'react-router-dom'
import {
  ActionButton,
  DataStatus 
} from 'components'
import {
  HargaPerkiraanSendiriApi
} from 'api'
import {
  TableHargaBarang,
  SectionDetail
} from './Comps'

const HargaPerkiraanSendiriBeliUpdate = () => {
  const { id } = useParams()
  const history = useHistory()
  const [dataDetail, setDataDetail] = useState({})
  const [dataHPSTerbaru, setDataHPSTerbaru] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true
  })

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      error: true
    })

    HargaPerkiraanSendiriApi.getSingleBeli({id_item_buaso: id})
      .then((res) => {
        const data = res?.data?.data ?? {}
        setDataDetail(data)
        setDataHPSTerbaru(data?.detail_hps_terakhir 
            ? data.detail_hps_terakhir.map(val => ({
                harga: parseInt(val?.hps_beli ?? 0), 
                id_vendor: val?.id_vendor ?? '', 
                nama_vendor: val?.nama_vendor ?? ''
            }))
            : []
          )
        setFetchingStatus({
          loading: false,
          error: false
        })
      })
      .catch(() => {
        setFetchingStatus({
          loading: false,
          error: true
        })
      })
  }

  const submitDataHandler = () => {
    setIsSubmitting(true)

    const finalValue = {
      buaso: "B",
      id_item_hps: id,
      detail: dataHPSTerbaru.map(val => ({id_vendor: val.id_vendor, hps_beli: val.harga}))
    }

    return HargaPerkiraanSendiriApi.saveBeli(finalValue)
      .then(() => history.push('/master/harga-perkiraan-sendiri', {alert: {show: true, variant: 'primary', text: 'Ubah HPS beli berhasil!'}}))
      .catch(() => history.push('/master/harga-perkiraan-sendiri', {alert: {show: true, variant: 'danger', text: 'Ubah HPS beli gagal!'}}))
      .finally(() => setIsSubmitting(false))
  }

  useEffect(() => {
    getInitialData()
  }, [])
  

  if (fetchingStatus.loading || fetchingStatus.error) {
    return (
      <div className="py-2">
        <DataStatus 
          loading={fetchingStatus.loading}
          text={fetchingStatus.loading ? 'Memuat . . .' : 'Data gagal dimuat'}
        />
      </div>
    )
  }

  return (
    <div className="p-2">
      {/* Detail */}
      <SectionDetail 
        type="beli"
        action="UPDATE"
        data={dataDetail}
      />

      {/* Tabel Harga Sebelumnya */}
      <div>
        <h6 className="px-2">
          <b>Harga Barang Sebelumnya</b>
        </h6>
        <TableHargaBarang 
          action='VIEW'
          data={dataDetail?.detail_hps_terakhir ?? []}
          perTanggal={dataDetail?.tgl_hps_terakhir}
        />
      </div>

      {/* Table Harga Terbaru */}
      <h6 className="px-2">
        <b>Harga Barang Terbaru</b>
      </h6>
      <TableHargaBarang 
        action="UPDATE"
        data={dataHPSTerbaru}
        setData={setDataHPSTerbaru}
      />
      
      {/* Button Submit */}
      <div className="text-right">
        <ActionButton 
          className="text-white px-4 mt-2"
          variant="warning"
          text="UPDATE"
          disable={dataHPSTerbaru?.length < 1}
          loading={isSubmitting}
          onClick={submitDataHandler}
        />
      </div>
    </div>
  )
}

export default HargaPerkiraanSendiriBeliUpdate